@use "styles/constants";

app-tags-container {
  display: flex;
  width: 100%;

  .title {
    margin-right: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  @media (max-width: constants.$bp-phone) {
    flex-direction: column;
    align-items: flex-start;

    .title {
      margin-bottom: 30px;
      margin-left: 6px;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;

    > tag {
      position: relative;
      margin: 6px 5px;
      padding: 0px 14px;
      border-radius: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      line-height: 19px;
      letter-spacing: 0.04em;
      font-weight: 400;
      cursor: pointer;

      &.active {
        background-color: var(--color-red-dark);
        border: 1px solid transparent;
        color: white;
      }

      &.inactive {
        border: 1px solid var(--color-grey-medium);
        color: var(--color-grey-medium);
      }
    }

    @media (max-width: 1000px) {
      overflow-x: scroll;
      width: 100%;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
}
