@use "styles/constants";

app-tab {
  .tab {
    transition: all .3s linear;
    overflow: hidden;
    opacity: 0;
    height: 0;

    &.active {
      height: auto;
      opacity: 1;
    }

    @media (max-width: constants.$bp-tablet) {
      margin: auto;
    }
  }
}
