export type Coordinates<T = string | number> = {
  lat: T;
  lng: T;
};

export type BoundingBox = [number, number, number, number];

export type GeoLocation = {
  id: number;
  nom: string;
  slug: string;
  bbox?: BoundingBox;
};

export type GeoRegion = GeoLocation & {
  code: string;
  departements?: GeoDepartement[];
};

export type GeoDepartement = GeoLocation & {
  code: string;
  region?: GeoRegion;
  villes?: GeoVille[];
};

export type GeoVille = GeoLocation & {
  parent_id?: number;
  code_commune: string;
  code_insee: string;
  code_postal: string;
  type?: string;
  population: number;
  superficie: number;
  geoloc: Coordinates;
  region?: GeoRegion;
  departement?: GeoDepartement;
  arrondissements?: GeoVille[];
};

export type GeoAdresse = {
  id: string;
  banId: string;
  city: string;
  citycode: string;
  context: string;
  housenumber: string;
  importance: number;
  label: string;
  name: string;
  postcode: string;
  score: number;
  street: string;
  type: string;
  x: number;
  y: number;
};

export type GeolocMode = 'city' | 'department' | 'address' | 'where';

export type GeoRandom = { type: 'ville' | 'departement' | 'region' } & (GeoRegion | GeoDepartement | GeoVille);

export type GeoData = {
  id: number;
  geoloc: string;
  bbox?: any[];
  coordinates: Coordinates<number>[];
};

export type GeoAutocompletion = {
  departements: GeoDepartement[];
  villes: GeolocFeature[];
};

export type GeolocSearch = {
  attribution: string;
  features: GeolocFeature[];
  filters: {
    type: string;
  };
  licence: string;
  limit: number;
  query: string;
  type: string;
  version: string;
};

export type GeolocFeature = {
  properties: GeoAdresse | GeoDepartement | GeoVille;
  geometry: {
    coordinates: number[];
    type: string;
  };
  type?: string;
};

export type GeolocSavedAdresse = {
  nom?: string;
  code?: string;
  lat?: number;
  lng?: number;
  coord?: string;
};

export const isGeoVille = (item: any): item is GeoVille => {
  return !!item?.code_postal;
};

export const isGeoDepartement = (item: any): item is GeoDepartement => {
  return !!item?.code && !!item?.bbox;
};

export const isGeoRegion = (item: any): item is GeoRegion => {
  return !!item?.departements;
};

export const isGeoAdresse = (item: any): item is GeoAdresse => {
  return !!item?.citycode;
};

export const isGeolocFeature = (item: any): item is GeolocFeature => {
  return !!item?.properties && !!item.geometry;
};
