import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ConfigService } from './config.service';

import {
  ApiGeoDepartementResponse, ApiGeoDepartementsResponse, ApiGeoRandomResponse,
  ApiGeoRegionResponse, ApiGeoRegionsResponse, ApiGeoVilleResponse, ApiGeoVillesResponse, GeoAutocompletion,
  GeoAdresse, GeoDepartement, GeolocFeature, isGeoVille, GeolocMode, GeolocSearch, SearchGeoAutoCompletion, SearchLocation,
  isGeoDepartement, isGeolocFeature
} from '@/models';

const MAX_SUGGESTIONS = 5;

@Injectable({
  providedIn: 'root'
})
export class GeographyService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/geoloc`;
  }

  getRegions(): Observable<ApiGeoRegionsResponse> {
    return this.http.get<ApiGeoRegionsResponse>(`${this.baseUrl}/regions`);
  }

  getRegion(id: number): Observable<ApiGeoRegionResponse> {
    return this.http.get<ApiGeoRegionResponse>(`${this.baseUrl}/regions/${id}`);
  }

  getDepartements(): Observable<ApiGeoDepartementsResponse> {
    return this.http.get<ApiGeoDepartementsResponse>(`${this.baseUrl}/departements`);
  }

  getDepartement(id: number, withAnnonce = false): Observable<ApiGeoDepartementResponse> {
    const params: any = {};
    if (withAnnonce) {
      params.with_annonces = 1; // Add annonces into the departements and filter those without at least 1
    }
    return this.http.get<ApiGeoDepartementResponse>(`${this.baseUrl}/departements/${id}`, { params });
  }

  getVilles(): Observable<ApiGeoVillesResponse> {
    return this.http.get<ApiGeoVillesResponse>(`${this.baseUrl}/villes`);
  }

  getVille(id: number, withAnnonce = false): Observable<ApiGeoVilleResponse> {
    const params: any = {};
    if (withAnnonce) {
      params.with_annonces = 1; // Add annonces into the departements and filter those without at least 1
    }
    return this.http.get<ApiGeoVilleResponse>(`${this.baseUrl}/villes/${id}`, { params });
  }

  geoVilleSearch(lat: string | number, lng: string | number): Observable<ApiGeoVillesResponse> {
    return this.http.get<ApiGeoVillesResponse>(`${this.baseUrl}/villes/search?within=${lat},${lng}`);
  }

  getRandom(): Observable<ApiGeoRandomResponse> {
    return this.http.get<ApiGeoRandomResponse>(`${this.baseUrl}/random`);
  }

  private getAddressAutocomplete(query: string): Observable<GeolocSearch> {
    return this.http.get<GeolocSearch>(`${this.baseUrl}/adresse?query=${query}`);
  }

  private getAddressVilleAutocomplete(query: string): Observable<GeolocSearch> {
    return this.http.get<GeolocSearch>(`${this.baseUrl}/adresse-ville?query=${query}`);
  }

  getAutoCompleteList(query: string): Observable<SearchGeoAutoCompletion> {
    return this.http.get<GeoAutocompletion>(`${this.baseUrl}/ville-departement?query=${query}`).pipe(
      map((values: GeoAutocompletion) => this.toSearchLocation(values))
    );
  }

  getAutocompletion(query: string, mode: GeolocMode): Observable<SearchGeoAutoCompletion> {
    switch (mode) {
      case 'city':
        return this.getAddressVilleAutocomplete(query).pipe(
          map((response) => this.toSearchLocation({ villes: response.features }))
        );
      case 'department':
      case 'where':
        return this.getAutoCompleteList(query);
      default:
        return this.getAddressAutocomplete(query).pipe(
          map((response) => this.toSearchLocation({ adresses: response.features }))
        );
    }
  }

  toSearchLocation({ departements, villes, adresses }: {
    departements?: GeoDepartement[];
    villes?: GeolocFeature[];
    adresses?: GeolocFeature[];
  }): SearchGeoAutoCompletion {

    return {
      departements: departements && departements.slice(0, MAX_SUGGESTIONS)?.map((item) => (
        this.toLocation(item)
      )).filter((l) => !!l),

      villes: villes && villes.slice(0, MAX_SUGGESTIONS)?.map((item) => (
        this.toLocation(item)
      )).filter((l) => !!l),

      adresses: adresses && adresses.slice(0, MAX_SUGGESTIONS)?.map((item) => (
        this.toLocation(item)
      )).filter((l) => !!l)
    };
  }

  /**
   * Convert a GeoDepartement, GeoVille or GeoLocFeature into a SearchLocation object.
   * @param item The source object
   * @returns The derived SearchLocation object.
   */
  toLocation<T>(item: T): SearchLocation | undefined {

    if (isGeoVille(item)) {
      return {
        type: 'ville',
        id: item.id,
        nom: item.nom,
        code: item.code_postal.split(',')[0],
        slug: item.slug,
        geoloc: item.geoloc ? { lat: + item.geoloc?.lat, lng: + item.geoloc?.lng } : undefined,
        bbox: item.bbox
      };
    }

    if (isGeolocFeature(item)) {
      const adresse = item.properties as GeoAdresse;
      const [lat, lng] = item.geometry.coordinates;

      return {
        type: 'adresse',
        id: adresse.id,
        nom: adresse.label,
        code: adresse.postcode,
        adresse: adresse.name,
        ville: adresse.city,
        geoloc: { lat, lng }
      };
    }

    if (isGeoDepartement(item)) {
      return {
        type: 'departement',
        id: item.id,
        nom: item.nom,
        code: item.code,
        slug: item.slug,
        bbox: item.bbox
      };
    }

    return undefined;
  }
}
