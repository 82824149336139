app-carousel-slide {
  transition: all ease-in-out 200ms;
  visibility: hidden;
  overflow: hidden;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;

  > img {
    object-fit: contain;
    max-height: 80vh;
    margin: 0 auto;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
