import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, map, Subscription } from 'rxjs';

import { Location } from '@angular/common';

import { RouteObserverService } from '@/services/route-observer.service';
import { ToastService } from '@/modules/toast/services/toast.service';
import { OffreEmploiService } from '@/services/offre-emploi.service';
import { MetadataService } from '@/services/metadata.service';
import { AgencesService } from '@/services/agences.service';
import { LeadsService } from '@/services/leads.service';
import { TeamService } from '@/services/team.service';

import { MainRoutes, CONTACT_ORIGINS, TAGS, DEFAULT_AGENCE } from '@/constants';

import {
  Agence, AgenceGroupe, API_SOURCE, CONTACT_TYPE, ContactType, SearchLocation,
  GeolocMode, LeadContact, Negociateur, RouteData, SelectOption
} from '@/models';

type GeoLocInfos = {
  city?: string,
  department?: string;
  zip_code?: string
};

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ContactFormComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;
  public CONTACT_ORIGINS = CONTACT_ORIGINS;
  public DEFAULT_AGENCE = DEFAULT_AGENCE;
  public CONTACT_TYPE = CONTACT_TYPE;

  public agence?: Agence | AgenceGroupe;
  public isGroup = false;
  public negociator?: Negociateur;
  public tags: ContactType[] = [];
  public postes?: SelectOption[];
  public nearestAgencies?: Agence[];

  public geoLocInputMode: GeolocMode = 'address';
  public contact_type = 'CONTACT_TYPE.SELL_PROPERTY';
  public geoLocInfos: GeoLocInfos = {};
  public source?: string;
  public type?: string;
  public pathUrl = '';
  public submitted = false;
  public isLoading = false;

  public applicationForm = new FormGroup({
    contact_type: new FormControl('CONTACT_TYPE.SELL_PROPERTY', Validators.required),
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    job_type_id: new FormControl(0),
    message: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    call_back: new FormControl(true, Validators.required),
    origine_contact: new FormControl(''),
    contact_agreement: new FormControl(false, Validators.required),
    partner_agreement: new FormControl(false, Validators.required),
    agence_id: new FormControl(this.agence?.id ?? 0, [Validators.required, Validators.min(1)])
  });

  get firstname() {
    return this.applicationForm.get('firstname') as FormControl;
  }

  get lastname() {
    return this.applicationForm.get('lastname') as FormControl;
  }

  get email() {
    return this.applicationForm.get('email') as FormControl;
  }

  get phone() {
    return this.applicationForm.get('phone') as FormControl;
  }

  get agenceId() {
    return this.applicationForm.get('agence_id') as FormControl;
  }

  constructor(
    private gtmService: GoogleTagManagerService,
    private routeObserve: RouteObserverService,
    private offrePostes: OffreEmploiService,
    private agencesService: AgencesService,
    private leadsService: LeadsService,
    private toastService: ToastService,
    private teamService: TeamService,
    private cd: ChangeDetectorRef,
    private meta: MetadataService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.routeObserve.currentRoute$.subscribe(({ data, params: { id_or_slug }, url }) => {
        this.pathUrl = url;
        this.type = data.type;

        this.updateSeo(data);

        if (this.type === 'negociateur') {
          this.negociatorById(id_or_slug);
          this.source = API_SOURCE.SITE_NEGO;
        }

        if (this.type === 'era') {
          this.filterTags();
          this.source = API_SOURCE.SITE_NATIONAL;
        }

        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.agencesService.agence$.pipe(
        filter((value) => value !== undefined)
      ).subscribe(({ data, type }) => {
        this.agence = data;
        this.applicationForm.get('agence_id')?.setValue(data.era_id);
        this.isGroup = type === 'groupe';
        this.filterTags();

        if (this.isGroup) {
          this.source = API_SOURCE.SITE_GROUPE;
        } else {
          this.source = API_SOURCE.SITE_AGENCE;
          this.gtmService.pushTag({
            'event': 'Pageview',
            'id-agence': this.agence.id,
            'nom-agence': this.agence.enseigne,
            'pagetype': 'Contact'
          });
        }

        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.offrePostes.getPostes([1]).subscribe(({ data }) => {
        this.postes = data;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backButton(): void {
    this.location.back();
  }

  toggleTags(interest: string): void {
    this.contact_type = interest;
    this.nearestAgencies = undefined;
    this.applicationForm.get('address')!.reset();
    this.setGeoLocInputMode(this.contact_type);
    this.setjobTypeControlValidators(this.contact_type);
  }

  handleGeolocChanged(location: SearchLocation): void {
    this.updateGeoLocInfo(location);

    if (this.geoLocInputMode === 'address' || this.geoLocInputMode === 'city') {
      const { lng, lat } = location.geoloc!;

      this.subscriptions.add(
        this.agencesService.getNearestAgency(`${lng}%2C${lat}`, 1).subscribe(({ data }) => {
          this.nearestAgencies = data;
          this.cd.markForCheck();
        }));
    } else {
      const geoloc: any = { ...location, id_dep: location.id.toString() };

      this.subscriptions.add(
        this.agencesService.getAgences(12, { searchLocations: [geoloc] }).pipe(
          map(({ data }) => data.filter((agence) => agence.statut === 1))
        ).subscribe((data) => {
          this.nearestAgencies = data;
          this.cd.markForCheck();
        })
      );
    }
  }

  sendApplicationForm(): void {
    if (this.applicationForm.valid) {

      const allDetails = {
        ...this.applicationForm.value,
        ...this.geoLocInfos,
        phone: this.phone.value.e164Number,
        effectif_id: this.negociator ? this.negociator.era_id : undefined,
        contact_type: this.contact_type as CONTACT_TYPE,
        api_source: this.source as API_SOURCE
      } as LeadContact;

      this.isLoading = true;

      this.leadsService.contact(allDetails).subscribe({
        next: () => {
          this.isLoading = false;
          this.cd.markForCheck();

          this.toastService.show({
            title: '',
            body: 'form_sent_success',
            type: 'success'
          });

          setTimeout(() => {
            this.location.back();
          }, 2000);
        },
        error: (error) => {
          this.isLoading = false;
          this.cd.markForCheck();

          this.toastService.show({
            title: '',
            body: error.message,
            type: 'error'
          });
        }
      });
    }
  }

  private updateSeo({ title, description, ogTitle, ogDescription }: RouteData): void {
    this.meta.setMetadata({
      title,
      description,
      ogTitle,
      ogDescription
    });
  }

  private negociatorById(id_or_slug: string): void {
    this.subscriptions.add(
      this.teamService.getNegociateurById(id_or_slug).subscribe(({ data }) => {
        this.negociator = data;
        this.agence = data.agence;
        this.applicationForm.get('agence_id')?.setValue(data.agence?.era_id ?? 0);
        this.filterTags();

        if (this.pathUrl.startsWith(MainRoutes.Effectifs)) {
          this.gtmService.pushTag({
            'event': 'Pageview',
            'id-effectif': this.negociator.id,
            'nom-effectif': this.negociator.nom,
            'pagetype': 'Contact'
          });
        }

        if (this.pathUrl.startsWith(MainRoutes.AgenceImmobiliere)) {
          this.gtmService.pushTag({
            'event': 'Pageview',
            'id-agence': this.agence?.id,
            'nom-agence': this.agence?.enseigne,
            'pagetype': 'Contact'
          });
        }
        this.cd.markForCheck();
      })
    );
  }

  private updateGeoLocInfo({ nom, code }: SearchLocation): void {
    switch (this.geoLocInputMode) {
      case 'address':
      case 'city':
        this.geoLocInfos = {
          city: nom,
          zip_code: code
        };
        break;
      case 'department':
        this.geoLocInfos = {
          department: nom
        };
        break;
    }
  }

  private setGeoLocInputMode(contactType: string): void {
    if (contactType === CONTACT_TYPE.SELL_PROPERTY) {
      this.geoLocInputMode = 'address';
    } else if (contactType === CONTACT_TYPE.OPEN_AGENCY) {
      this.geoLocInputMode = 'department';
    } else {
      this.geoLocInputMode = 'city';
    }
  }

  private setjobTypeControlValidators(contactType: string): void {
    const jobTypeControl = this.applicationForm.get('job_type_id')!;

    if (contactType === CONTACT_TYPE.APPLICATION) {
      jobTypeControl.setValidators([Validators.required]);
    } else {
      jobTypeControl.clearValidators();
    }
    jobTypeControl.updateValueAndValidity();
  }

  private filterTags(): void {
    this.tags = TAGS.filter(({ value, source }) => {
      const isEntreprise = value === 'entreprise_commerce';
      const isSyndic = value === 'syndic_copropriete';
      const isGestion = value === 'gestion_locative';
      const isEraAgence = source === 'eraAndAgence';

      if (this.agence) {
        const {
          gestion_locative: isFeatGestion,
          syndic_copropriete: isFeatSyndic,
          entreprise_commerce: isFeatEntreprise
        } = this.agence.feature_display ?? {};

        return (
          (!isEntreprise && !isFeatEntreprise) || (!isEntreprise && isFeatEntreprise) || (isEntreprise && isFeatEntreprise)
        ) && (
          (!isGestion && !isFeatGestion) || (!isGestion && isFeatGestion) || (isGestion && isFeatGestion)
        ) && (
          (!isSyndic && !isFeatSyndic) || (!isSyndic && isFeatSyndic) || (isSyndic && isFeatSyndic)
        ) && (
          isEraAgence || (source === this.type)
        );
      }

      return isEraAgence || isGestion || isEntreprise || isSyndic;
    });
  }
}
