{
  "baseUrl": "https://www.erafrance.com",
  "production": true,
  "version": "2.0.0",
  "env": "default",
  "url": "https://api.erafrance.com/api/v2",
  "url_photos_from_BO": "https://api.erafrance.com",
  "url_front": "https://www.erafrance.com",
  "facebook_client_id": "555287399609884",
  "google_client_id": "620065002049-774mea0s2k63eu22i9tgevl73eik0mr3.apps.googleusercontent.com",
  "mapboxKey": "pk.eyJ1IjoiZXJhZnJhbmNlZGV2IiwiYSI6ImNrd3didHFnOTAyOWoyb2xhNnowZmJnbnIifQ.YX4hrL05xXjbcOkfpWJ7ig",
  "facebook_developper_app_id": "555287399609884",
  "googleTranslateKey": "AIzaSyDqNkhfkRtwRuc1dGAjc5tFgkL5EDbNODw",
  "googleTranslateUrl": "https://translation.googleapis.com/language/translate/v2",
  "gtm": "GTM-W2HT5G8",
  "ga": "GTM-W2HT5G8",
  "recaptcha": "6LdVHLofAAAAAH4P-1Fnp6N2dDYV7-V5p2ZYu5oW",
  "axeptioClientId": "62051a4a236e41f67a205868",
  "externalScripts": true
}
