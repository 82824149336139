{
  "ACHAT": {
    "COUNTRY": {
      "TITLE": "Achat immobilier",
      "SUBTITLE": "Achat immobilier en France",
      "EDITO": "Afin de réaliser votre achat immobilier en France vous devez respecter certaines étapes et, pour cela, il est souvent utile de se faire accompagner par une agence. Une des succursales, de l'important réseau ERA Immobilier, sera présente à chaque étape de votre achat.",
      "BOTTOM_CONTENT": [
        {
          "title": "Achat immobilier France",
          "text": "Vous venez de décider de devenir propriétaire de votre résidence, acheter son premier appartement ou sa première maison est une étape importante. Tout d'abord faites le point sur les différents prêts et dispositifs qui peuvent vous faire économiser, et la capacité de financement de votre projet (taux, durée du prêt et assurance)."
        },
        {
          "title": "Quelles sont les étapes pour acheter votre logement ?",
          "text": "En tant que jeune acquéreur, après avoir défini : votre budget ; le type d'achat : maison ou appartement, sa surface, le nombre de chambres et de pièces, avec ou sans terrain, garage, etc. ;  pensez à lister les services que vous souhaitez trouver proches de votre bien, écoles, commerces de proximité, ligne de bus, ...\nVous êtes prêt à parcourir les annonces immobilières parmi lesquels vous serez susceptible de trouver le bien de vos rêves. La recherche n'est pas toujours simple, c'est pourquoi le moteur ERA, référençant un grand nombre d'annonces de ventes de maisons et d'appartements, est là pour vous donner tous les outils et vous permettre une navigation simple.\nUne fois les annonces, ayant un prix de vente en corrélation avec votre budget, repérées sur le site de nos agences, vous pouvez prendre contact avec celle de votre département afin de convenir d'une visite.\nVous avez également la possibilité de créer une alerte sur le site ERA, pensez à bien renseigner le champ de votre adresse e-mail.\nLorsque votre recherche aura donné ses fruits, que parmi les annonces de notre site vous aurez enfin trouvé votre logement à l'achat, un agent immobilier ERA sera là pour votre accompagnement jusqu'à la signature de votre compromis, chez un des notaires de votre département."
        },
        {
          "title": "Est-il préférable d'investir dans du neuf ou de l'ancien ?",
          "text": "Un logement neuf est un bon investissement car il offre des avantages fiscaux, il peut être occupé dès la fin de sa construction, il est fonctionnel, généralement bénéficiant des services de proximité, les  frais de notaires sont réduits mais son coût d'acquisition peut-être plus élevé.\nDevenir acquéreur d'un bien immobilier ancien c'est faire le choix du charme, il sera disponible plus rapidement mais après quelques travaux à la clé, le prix de départ est moins élevé mais le coût de revient est parfois supérieur."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'une vente immobilière en France ?",
          "content": "Le prix de vente d'un bien immobilier est très variable, selon sa situation, son environnement, le type de résidence, sa superficie. Bien d'autres données peuvent influer sur ce prix de vente, comme un terrain, la vue, etc. Le prix moyen d'une transaction immobilière est de {{prix_moyen}}, le prix le plus bas se situant à {{prix_min}} et le prix le plus  élevé est de {{prix_max}}."
        },
        {
          "title": "Combien y a-t-il de biens immobiliers disponibles en France  ?",
          "content": " De l'appartement pour vivre en ville, studio ou 5 pièces, à la maison familiale, résidence principale ou secondaire, en passant par le terrain constructible, les biens immobiliers sont divers, ils sont aux environs de {{annonces}}."
        },
        {
          "title": "Comment trouver un bien à acheter dans la ville en France  ?",
          "content": "Pour trouver un bien à acheter en ville en France, ERA a mis à votre disposition des outils de sélection. Vous trouverez en un clic l’agence de notre réseau la plus proche de chez vous ou les annonces proposant des ventes en ville à partir de vos critères. Les 450 agences et ses 2100 conseillers ERA peuvent vous accompagner sur toute la France. Pour la recherche d'un appartement ou d'une maison en ville, nos services vous aiderons à faire une acquisition dans l'immobilier."
        },
        {
          "title": "Quel est le délai moyen pour acheter un bien en France ?",
          "content": "Le nombre de biens à acheter, que ERA propose sur son site, va vous permettre de trouver le vôtre en France. Les conseils que vous pourrez trouver en agence seront un plus à votre recherche. Le délai moyen d'une vente immobilière est de 3 mois.\nN'hésitez pas à créer une alerte afin de recevoir les nouvelles propositions de ventes en temps réel."
        }
      ]
    },
    "REGION": {
      "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
      "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
      "EDITO": "L'achat d'un immeuble en {{geo_object_name}} est votre choix pour un investissement. Trouver le programme conforme à ses critères peut être un parcours difficile sans l'aide d'un expert. ERA Immobilier référence des annonces d'immeubles à la vente dans toute la région {{geo_object_name}}.",
      "BOTTOM_CONTENT": [
        {
          "title": "Achat immeuble en {{geo_object_name}}",
          "text": "Vous cherchez un bâtiment à l’achat en {{geo_object_name}}, notre site internet met à votre disposition un moteur rendant possible une vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous épaulera pour trouver les habitations, appartements ou établissements ayant un fort potentiel."
        },
        {
          "title": "Vous recherchez un immeuble de rapport en {{geo_object_name}} ?",
          "text": "Vous souhaitez acheter un bâtiment de rapport, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA pourra vous aider dans cette étape, bâtiment avec appartement de 5 pièces, présentant 4 appartements ? Le bien de vos rêves est présent parmi ceux référencés sur notre site e-commerce. Notre menu pourra vous aider à affiner votre recherche. Et, si vous hésitez encore, pour le choix du type de bâtiment que vous allez acheter, le prix et l’état renseignés dans chaque fiche vous orienteront. Vous avez également la possibilité de réaliser une alerte afin de recevoir les nouvelles offres de vente d’immeuble de rapport ; mais également de prendre rendez-vous avec l’agence de votre région qui les gère afin de recevoir rapidement des conseils."
        },
        {
          "title": "Trouver votre programme immobilier en {{geo_object_name}}",
          "text": "Parmi les offres ERA vous pourrez trouver un hôtel restaurant, un local commercial, une résidence ou un bâtiment pour investir en {{geo_object_name}}. Votre projet, qu’il se situe dans une grande, moyenne ou petite ville, ou à la campagne en {{geo_object_name}}, se trouve avec certitude parmi les exclusivités d’une agence ERA. Les photos et les descriptions des habitations vous renseigneront de manière plus exacte en vous donnant des précisions. Tels que, pour chaque bâtisse, le nombre de pièces et chambres, de ses appartements, mais aussi s’ils sont pourvus d’une terrasse, d’un garage, d’un stationnement, d’un jardin."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'un immeuble en {{geo_object_name}} ?",
          "content": "Le prix d'un immeuble de rapport en {{geo_object_name}} est assez variable ; suivant sa localisation, en centre-ville ou en région. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
        },
        {
          "title": "Combien y a-t-il d'immeubles disponibles en {{geo_object_name}} ?",
          "content": "Les immeubles à la vente en {{geo_object_name}} sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Le nombre d'immeubles disponibles en {{geo_object_name}} est de {{annonces}}."
        },
        {
          "title": "Comment trouver un immeuble à acheter en {{geo_object_name}} ?",
          "content": "Pour trouver un immeuble à acheter en {{geo_object_name}}, en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
        },
        {
          "title": "Quel est le délai moyen pour acheter un immeuble en {{geo_object_name}} ?",
          "content": "Le délai pour l'achat de votre immeuble en {{geo_object_name}} est variable, il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
        }
      ]
    },
    "DEPARTEMENT": {
      "TITLE": "{{annonces}} {{typo}} en vente dans le département : {{geo_object_name}}",
      "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
      "EDITO": "Découvrez les annonces d'immeubles à l'achat {{geo_object_name}} dans votre agence ERA Immobilier ou sur notre site internet. Vous effectuez une recherche afin de trouver votre logement ou pour investir ? L'immeuble que vous souhaitez acquérir se trouve parmi nos exclusivités.",
      "BOTTOM_CONTENT": [
        {
          "title": "Achat immeuble {{geo_object_name}}",
          "text": "Vous recherchez un building à l’achat dans votre département ({{geo_object_name}}), notre site web met à votre disposition un moteur rendant possible la vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous épaulera pour trouver les immeubles de logements ou commerces ayant un fort potentiel."
        },
        {
          "title": "Comment choisir les catégories de votre immeuble dans votre département : {{geo_object_name}} ?",
          "text": "Vous souhaitez acquérir une bâtisse de rapport, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA vous aidera dans cette étape, bâtiment avec appartement de 5 pièces, offrant 4 logements ? Le bien de votre vie est présent parmi ceux référencés sur notre commerce en ligne. Notre menu vous aidera à perfectionner votre recherche. Et, si vous hésitez encore, pour le choix du genre d'édifice que vous allez acheter, le prix, la surface et l’état renseignés dans chaque fiche vous orienteront. Vous avez aussi la possibilité de réaliser une alerte afin de bénéficier des nouvelles offres de vente d’immeuble de rapport ; mais aussi de prendre rendez-vous avec l’agence de votre département ({{geo_object_name}}) qui les gère afin d’obtenir de véritables de conseils."
        },
        {
          "title": "Comment allez-vous trouver un immeuble dans votre département : {{geo_object_name}} ?",
          "text": "Parmi les offres ERA vous pourrez rechercher un hôtel restaurant, un local commercial, une résidence ou un ensemble à vendre dans votre département : {{geo_object_name}}. Votre projet, qu’il se situe en centre-ville de la ville principale de votre département, {{geo_object_name}} ({{code_departement}}), en région {{region}}, se trouve certainement parmi les exclusivités d’une agence ERA. Les photos et les descriptions des immeubles à vendre, vous renseigneront de manière plus explicite en vous donnant des détails. Tels que la surface de chaque bâtisse ainsi que son type, le nombre de pièces et chambres de ses appartements, mais aussi s’ils sont pourvus d’une grande terrasse, d’un garage, d’un stationnement, d’un jardin."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'un immeuble dans votre département : {{geo_object_name}} ?",
          "content": "Le prix d'un immeuble de rapport dans votre département ({{geo_object_name}}) est assez variable ; suivant sa localisation, en centre-ville ou en périphérie. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
        },
        {
          "title": "Combien y a-t-il d'immeubles disponibles dans votre département : {{geo_object_name}} ?",
          "content": "Les immeubles à la vente dans votre département ({{geo_object_name}}) sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Le nombre d'immeubles disponibles en {{geo_object_name}} est de {{annonces}}."
        },
        {
          "title": "Comment trouver un immeuble à acheter dans votre département : {{geo_object_name}} ?",
          "content": "Pour trouver un immeuble à acheter dans votre département : {{geo_object_name}}, en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
        },
        {
          "title": "Quel est le délai moyen pour acheter un immeuble dans votre département : {{geo_object_name}} ?",
          "content": "Le délai pour l'achat de votre immeuble dans votre département : {{geo_object_name}}, est variable. Il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
        }
      ]
    },
    "VILLE": {
      "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
      "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
      "EDITO": "ERA Immobilier propose des annonces d'immeubles à la vente dans votre ville, il permet également aux futurs acquéreurs d'établir une étude des programmes en cours à {{geo_object_name}} ; quelques secondes seulement sont nécessaires pour sélectionner celles qui vous paraitront pertinentes.",
      "BOTTOM_CONTENT": [
        {
          "title": "Achat immeuble à {{geo_object_name}}",
          "text": "Vous cherchez un immeuble à l’achat à {{geo_object_name}}, notre site internet met à votre disposition un moteur rendant possible la vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous permettra de trouver les édifices de logements ou commerces ayant un fort potentiel dans votre ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
        },
        {
          "title": "Comment choisir la catégorie de votre immeuble de rapport à {{geo_object_name}} ?",
          "text": "Vous souhaitez acheter un immeuble de rapport à {{geo_object_name}}, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA pourra vous aider dans cette étape, bâtiment avec appartement de 5 pièces, présentant 4 logements ? Le bien de votre vie est présent parmi ceux référencés sur notre site. Notre menu pourra vous aider à améliorer votre recherche. Et, si vous hésitez encore, pour le choix du type d’immeuble que vous allez acheter, le prix et l’état renseignés dans chaque fiche vous orienteront. Vous avez aussi la possibilité de concevoir une alerte afin de profiter des nouvelles offres de vente d’immeuble de rapport ; mais également de prendre contact avec l’agence de votre ville qui les gère afin d'avoir vraiment de bons conseils."
        },
        {
          "title": "Comment trouver un immeuble de rapport à {{geo_object_name}} ?",
          "text": "Parmi les offres ERA vous pourrez chercher un hôtel restaurant, un local commercial, une résidence ou un bâtiment à vendre à {{geo_object_name}}. Votre projet, qu’il se situe dans la banlieue, dans la couronne ou en centre-ville de {{geo_object_name}}, se trouve certainement parmi les exclusivités d’une agence ERA. Les photos et les descriptions des habitations à vendre, vous renseigneront de manière plus explicite en vous donnant des renseignements détaillés. Tels que la surface de chaque bâtiment, le nombre de pièces et chambres de ses appartements, mais aussi s’ils sont pourvus d’une grande terrasse, d’un garage, d’un stationnement, d’un jardin."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'un immeuble à {{geo_object_name}} ?",
          "content": "Le prix d'un immeuble de rapport à {{geo_object_name}} est assez variable ; suivant sa localisation, près de ses commerces ou dans la banlieue. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
        },
        {
          "title": "Combien y a-t-il d'immeubles disponibles à {{geo_object_name}} ?",
          "content": "Les immeubles à la vente à {{geo_object_name}} ({{code_postal}}), sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Vous avez la possibilité de prendre contact avec la mairie de {{geo_object_name}}. Le nombre d'immeubles disponibles à {{geo_object_name}} est de {{annonces}}."
        },
        {
          "title": "Comment trouver un immeuble à acheter à {{geo_object_name}} ?",
          "content": "Pour trouver un immeuble à acheter à {{geo_object_name}} en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
        },
        {
          "title": "Quel est le délai moyen pour acheter un immeuble à {{geo_object_name}} ?",
          "content": "Le délai pour l'achat de votre immeuble à {{geo_object_name}} est variable, il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
        }
      ]
    },
    "ARRONDISSEMENT": {
      "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
      "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
    },
    "APPARTEMENT": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "ERA Immobilier vous accompagne dans vos démarches pour l'achat d'un appartement en France. Son site web et ses agences immobilières présentes sur tout le territoire référencent des annonces qui vont vous permettre de trouver votre bonheur.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat appartement France",
            "text": "Acheter un appartement en France, c'est souvent faire son premier achat immobilier. Neuf ou ancien chacun présente ses avantages, l'un ou l'autre sera vous séduire que ce soit pour un investissement ou pour votre résidence principale."
          },
          {
            "title": "Comment faire le choix de votre appartement ?",
            "text": "Près de 4000 appartements sont en vente en France sur le site ERA Immobilier, aussi avant de débuter votre sélection nous vous conseillons de lister tous vos critères. Dans quel département ou à proximité de quel centre-ville souhaitez-vous trouver un logement ? Votre projet, pour devenir propriétaire, doit être assez précis. Réfléchissez au quartier que vous souhaitez habiter. Votre immeuble sera-t-il équipé d'un ascenseur, d'une terrasse, d'une cave ? Les commodités sont-elles accessibles à pied ?\nGrâce au moteur de recherche ERA vous pourrez ainsi filtrer les annonces d'après votre budget, la surface et le nombre de pièces. Ce sont les critères de base lors de la recherche d'un appartement, viennent s'ajouter les filtres de critères plus poussés, tels que la vue sur mer, terrasse ou balcon, etc."
          },
          {
            "title": "Voulez-vous acheter un appartement neuf ou ancien ?",
            "text": "Vous vous êtes longuement demandé s'il valait mieux que votre investissement se fasse dans du neuf ou de l'ancien. Chacun possède ses avantages et ses inconvénients.\nUn appartement neuf vous apportera peut-être plus de garantie, comme la garantie décennale qui est comprise dans le prix de vente de ce type d'appartement.\nUn logement ancien pourra vous séduire par son charme, mais faites chiffrer les travaux éventuels qui vont venir augmenter vos mensualités.\nNeuf ou ancien, votre appartement se trouve très certainement dans nos annonces. Studio ou 3 pièces, appartement avec jardin privatif ou en étage sans vis à vis, aux abords de Paris ou dans tout autre département de la France, votre recherche est possible avec vos conditions sur le site internet ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un appartement en France ?",
            "content": "Le prix d'un appartement en France est assez variable, il peut se situer dans les prix les plus bas s'il est, par exemple, en étage élevé sans ascenseur ; ou dans les prix les plus haut pour un appartement de quartier de haut standing. Le prix le plus bas pour l'achat d'un appartement en France est de {{prix_min}}, le prix le plus haut de {{prix_max}}, quant au prix moyen il s'élève à {{prix_moyen}}."
          },
          {
            "title": "Combien y a-t-il d'appartements disponibles en France ?",
            "content": "On compte environ 37 millions de logements de tous types en France. 82% de ces logements sont des résidences principales, 45 % sont des biens en immeuble collectif. Le nombre d'appartements disponibles en France est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à acheter en ville en France ?",
            "content": "Pour trouver un appartement à acheter en ville en France, il est conseillé de consulter régulièrement les petites annonces de journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, les annonces du site ERA Immobilier. Vous pouvez également créer une alerte sur notre site pour votre recherche, en n'oubliant pas de renseigner votre adresse e-mail."
          },
          {
            "title": "Quel est le délai moyen pour acheter un appartement en France ?",
            "content": "Si l'achat de votre appartement en France est votre premier achat, les délais d'attente pendant les recherches de votre résidence, avant l'accord de vos crédits et entre la signature du compromis de vente et celle de l'acte de vente, ce dernier étant d'environ 3 mois, peuvent vous paraître assez longs. Sachez que ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "ERA Immobilier propose un outil d'estimation, il permet également aux acheteurs d'établir une vérification du prix de leur futur appartement ; avant leur acquisition en {{geo_object_name}} 2 minutes suffisent pour connaitre la valeur de ce bien.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat appartement en {{geo_object_name}}",
            "text": "Faire l'acquisition d'un appartement en {{geo_object_name}}, c’est souvent faire son premier achat immobilier. Neuf ou ancien chacun présente ses avantages, l’un ou l’autre saura vous tenter que ce soit pour un investissement ou pour votre résidence principale."
          },
          {
            "title": "Vous allez acquérir un appartement en {{geo_object_name}}",
            "text": "Un grand nombre d'appartements sont en vente en {{geo_object_name}} sur le site ERA Immobilier, aussi avant de commencer votre sélection nous vous conseillons de lister tous vos critères. Dans quel département ou à proximité de quel centre-ville souhaitez-vous trouver un logement ? Votre projet, pour devenir propriétaire, doit être assez précis. Réfléchissez au quartier que vous souhaitez habiter. Votre immeuble aura-t-il un ascenseur, une grande terrasse, une cave ? Les commodités sont-elles accessibles à pied ? Grâce à la recherche ERA vous pouvez ainsi filtrer les annonces d’après votre budget, la surface et le nombre de pièces. Ce sont les critères de base pour un loft, viennent s’ajouter les filtres de critères plus poussés, tels que la vue sur mer, grande terrasse ou balcon, etc."
          },
          {
            "title": "Rêvez-vous d'un appartement neuf ou ancien en {{geo_object_name}} ?",
            "text": "Vous vous êtes longuement demandé s’il valait mieux que votre investissement se fasse dans du neuf ou de l’ancien. Chacun possède ses avantages et ses problèmes techniques. Un appartement neuf vous donnera sans doute plus de garantie, comme la garantie décennale qui est comprise dans le prix de vente de cette catégorie d’appartement. Un logement ancien pourra vous tenter par son look, mais faites chiffrer les travaux éventuels qui vont venir augmenter vos échéances. Neuf ou ancien, votre appartement se trouve sans aucun doute dans nos annonces. Studio ou 3 pièces, appartement avec jardin privé ou en étage sans vis à vis, aux abords de la capitale ou dans tout autre département, votre recherche est possible avec vos conditions sur le site web ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un appartement en {{geo_object_name}} ?",
            "content": "Le prix d'un appartement en {{geo_object_name}} est assez variable, il peut se situer dans les prix les plus bas s'il est, par exemple, en étage élevé sans ascenseur ; ou dans les prix les plus haut pour un appartement de quartier de haut standing. Le prix le plus bas pour l'achat d'un appartement en région {{prix_min}}, le prix le plus haut de {{prix_max}}, quant au prix moyen il s'élève à {{prix_moyen}}."
          },
          {
            "title": "Combien y a-t-il d'appartements disponibles en {{geo_object_name}} ?",
            "content": "Les logements sont en nombre en {{geo_object_name}}. Il y a {{annonces}} appartements disponibles à la vente dans la région {{geo_object_name}}. Les logements y sont principalement des résidences principales."
          },
          {
            "title": "Comment trouver un appartement à acheter en ville en {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à acheter en ville en {{geo_object_name}}, il est conseillé de consulter régulièrement les petites annonces de journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, les annonces du site ERA Immobilier. Vous pouvez également créer une alerte sur notre site pour votre recherche, en n'oubliant pas de renseigner votre adresse e-mail."
          },
          {
            "title": "Quel est le délai moyen pour acheter un appartement en {{geo_object_name}} ?",
            "content": "Si l'achat de votre appartement en {{geo_object_name}} est votre premier achat, les délais d'attente pendant les recherches de votre résidence, avant l'accord de vos crédits et entre la signature du compromis de vente et celle de l'acte de vente, ce dernier étant d'environ 3 mois, peuvent vous paraître assez longs. Sachez que ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "L'achat d'un logement dans votre département, {{geo_object_name}}, est votre choix pour un investissement ou pour vous habitation personnelle ? Trouver le programme conforme à vos critères peut être un parcours difficile sans l'aide d'un agent immobilier. ERA référence des annonces d'appartements à la vente dans tout votre département.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat appartement {{geo_object_name}}",
            "text": "Faire l'acquisition d'un appartement dans votre département ({{geo_object_name}}) c’est souvent faire son premier achat immobilier. Neuf ou ancien chacun présente ses avantages, l’un ou l’autre sera vous séduire que ce soit pour un investissement ou pour votre résidence première."
          },
          {
            "title": "Vous avez envie d'un appartement dans votre département : {{geo_object_name}} ?",
            "text": "Un large choix de locations d'appartements est proposé sur le site ERA Immobilier France, ce qui devrait vous permettre de trouver rapidement le logement de vos rêves, dans votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}. Tout d'abord listez tous vos critères. A proximité de quel centre-ville souhaitez-vous trouver un logement ? Votre projet, pour devenir propriétaire, doit être assez précis. Réfléchissez au quartier que vous souhaitez habiter. Votre bâtisse saura-t-elle intégrer un ascenseur, une terrasse, une cave ? Les commodités sont-elles accessibles à pied ? Grâce aux champs de recherche ERA vous pouvez ainsi filtrer les annonces d’après votre budget, le nombre de pièces, ... Ce sont les critères de base lors de la recherche d'un bien, auxquels viennent s’ajouter les filtres d'éléments plus poussés, tels que vue, terrasse ou balcon, etc."
          },
          {
            "title": "Neuf ou ancien, quel type d'achat d'un appartement dans votre département ({{geo_object_name}}) ?",
            "text": "Vous vous êtes amplement demandé s’il valait mieux que votre investissement se fasse dans du neuf ou de l’ancien. Chacun possède ses avantages et ses problèmes techniques. Un appartement neuf vous donnera peut-être plus de garanties, comme la garantie décennale qui est comprise dans le prix de vente de ce type d’appartement. Un logement ancien pourra vous séduire par son attrait, mais faites calculer les travaux éventuels qui viendront augmenter vos mensualités. Neuf ou ancien, votre appartement se trouve très certainement dans nos annonces. Studio ou 3 pièces, appartement avec jardin privé ou en étage sans vis à vis, aux abords de la capitale de votre département, votre recherche est possible avec vos conditions sur le site web ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un appartement dans votre département : {{geo_object_name}} ?",
            "content": "Le prix d'un appartement dans votre département ({{geo_object_name}}) est assez variable, il peut se situer dans les prix les plus bas s'il est, par exemple, en étage élevé sans ascenseur ; ou dans les prix les plus haut pour un appartement de quartier de haut standing. Le prix le plus bas pour l'achat d'un appartement dans votre département ({{geo_object_name}}) est de {{prix_min}}, le prix le plus haut de {{prix_max}}, quant au prix moyen il s'élève à {{prix_moyen}}."
          },
          {
            "title": "Combien y a-t-il d'appartements disponibles dans votre département : {{geo_object_name}} ?",
            "content": "Le nombre d'appartements disponibles dans votre département ({{geo_object_name}}) est de {{annonces}}. Ils sont de tous types, résidences principales ou de vacances, dans ce département : {{geo_object_name}}."
          },
          {
            "title": "Comment trouver un appartement à acheter en ville dans votre département : {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à acheter en ville dans votre département ({{geo_object_name}}), il est conseillé de consulter régulièrement les petites annonces de journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, les annonces du site ERA Immobilier. Vous pouvez également créer une alerte sur notre site pour votre recherche, en n'oubliant pas de renseigner votre adresse e-mail."
          },
          {
            "title": "Quel est le délai moyen pour acheter un appartement dans votre département : {{geo_object_name}} ?",
            "content": "Si l'achat de votre appartement dans votre département ({{geo_object_name}}) est votre premier achat, les délais d'attente pendant les recherches de votre résidence, avant l'accord de vos crédits et entre la signature du compromis de vente et celle de l'acte de vente, peuvent vous paraître assez longs. Sachez que ERA Immobilier est là pour vous épauler durant tout ce temps."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "L'achat d'un appartement dans votre ville est votre choix, que ce soit pour un investissement ou pour votre logement ? Trouver l'appartement correspondant à vos critères peut être un parcours difficile sans être épaulé par un agent immobilier. ERA référence des annonces d'appartements à la vente à {{geo_object_name}}.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat appartement à {{geo_object_name}}",
            "text": "Acheter un appartement à {{geo_object_name}}, c'est souvent faire son premier achat immobilier. Neuf ou ancien chacun présente ses avantages, l’un ou l’autre sera vous captiver que ce soit pour un investissement ou pour votre résidence principale dans votre ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
          },
          {
            "title": "Comment pouvez-vous faire le choix de votre appartement à {{geo_object_name}} ?",
            "text": "La commune de {{geo_object_name}} a une superficie de {{superficie}}. Un bon nombre d'appartements sont en vente à {{geo_object_name}} sur le site ERA Immobilier. Aussi avant d'entamer votre sélection nous vous demandons de lister tous vos critères. Dans quel quartier, ou à proximité de quel édifice, souhaitez-vous trouver un logement ? Votre projet, pour devenir propriétaire, se doit d'être assez précis. Réfléchissez au quartier que vous souhaitez habiter. Votre bâtiment sera-t-il équipé d’un ascenseur, d’une terrasse, d’une cave ? Les commerces sont-elles accessibles à pied ? Grâce à la barre de recherche ERA vous pouvez ainsi filtrer les annonces selon votre budget, la surface et le nombre de pièces. Ce sont les critères de base lors de l'achat d'un bien, viennent s’ajouter les filtres de caractères plus poussés, tels que la vue, terrasse ou balcon, etc."
          },
          {
            "title": "Est-il préférable d'acheter un appartement neuf ou ancien à {{geo_object_name}} ?",
            "text": "Vous vous êtes longuement demandé s’il valait mieux que votre investissement se fasse dans du neuf ou du vieux. Chacun possède ses avantages et ses inconvénients. Un appartement neuf vous offrira peut-être plus de garanties, comme la garantie décennale qui est comprise dans le prix de vente de ce type d'appartement. Un logement ancien pourra vous captiver par son aspect, mais faites calculer les travaux éventuels qui vont venir augmenter vos mensualités. Neuf ou ancien, votre appartement se trouve avec certitude dans nos annonces. Studio ou 3 pièces, appartement avec jardin privatif ou en étage sans vis à vis, aux abords des écoles ou des arrêts de bus de {{geo_object_name}}, votre recherche est possible avec vos conditions sur le site internet ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un appartement à {{geo_object_name}} ?",
            "content": "Le prix d'un appartement à {{geo_object_name}} est assez variable, il peut se situer dans les prix les plus bas s'il est, par exemple, en étage élevé sans ascenseur ; ou dans les prix les plus haut pour un appartement de quartier de haut standing. Le prix le plus bas pour l'achat d'un appartement à {{geo_object_name}} est de {{prix_min}}, le prix le plus haut de {{prix_max}}, quant au prix moyen il s'élève à {{prix_moyen}}."
          },
          {
            "title": "Combien y a-t-il d'appartements disponibles à {{geo_object_name}} ?",
            "content": "Dans la ville de {{geo_object_name}} ({{code_postal}}), au nombre d’habitants de {{nbr_habitant}}. On compte des appartements de tous types à {{geo_object_name}}. Ces logements sont souvent des résidences principales, certains étant des biens en immeuble collectif. Le nombre d'appartements disponibles à {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à acheter dans la ville de {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à acheter à {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}, il est conseillé de consulter régulièrement les petites annonces de journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, les annonces du site ERA Immobilier. Vous pouvez également créer une alerte sur notre site pour votre recherche, en n'oubliant pas de renseigner votre adresse e-mail."
          },
          {
            "title": "Quel est le délai moyen pour acheter un appartement à {{geo_object_name}} ?",
            "content": "Si l'achat de votre appartement à {{geo_object_name}} est votre premier achat, les délais d'attente pendant les recherches de votre résidence, avant l'accord de vos crédits et entre la signature du compromis de vente et celle de l'acte de vente, peuvent vous paraître assez longs. Sachez que ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    },
    "MAISON": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "L'outil d'estimation ERA Immobilier permet aux acheteurs de vérifier rapidement si le prix de leur future acquisition est en adéquation avec les tarifs du marché ; avant d'acheter une maison en France en moins de 2 minutes la valeur du bien peut être vérifiée.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat maison France",
            "text": "Acheter une maison est le rêve de la majorité des personnes en France. Maison neuve ou maison ancienne c'est souvent le premier choix qui se présente aux futurs acquéreurs. La recherche pourra s'orienter vers la tranquillité avec une maison en bord de mer, un bien à la campagne ou une maison de ville pour une question pratique."
          },
          {
            "title": "Le choix de votre maison",
            "text": "Le site ERA Immobilier propose près de 4000 maisons à la vente en France sur son site. Pour mieux cibler vos critères, il est conseillé d'établir une liste non exhaustive de toutes vos exigences. Quel est votre budget ? Dans quelle région doit se situer votre bien immobilier ?\nAfin de mieux sélectionner les annonces dans la catégorie maison à vendre réfléchissez à tous les détails importants pour votre famille, le nombre de chambres dont vous devez disposer, la surface qui vous est nécessaire, avec ou sans terrain, etc.\nNotre moteur ERA Immobilier vous aidera à sélectionner les annonces, à partir de votre budget, par région et suivant vos critères ; sans oublier de vous informer sur les diagnostics énergétiques et de vous donner un aperçu de la vie de votre futur quartier."
          },
          {
            "title": "Vous souhaiter acquérir une maison neuve ou ancienne ?",
            "text": "L'achat d'une maison neuve ou ancienne, cela peut influer sur le prix de vente et principalement sur les frais. Après avoir fait le point sur les avantages et les inconvénients de chaque type de maison à vendre, commencer votre recherche et renseignez vos informations sur le simulateur ERA.\nUne maison ancienne en pierres aura un certain charme, mais son diagnostic énergétique peut-être moins bon.\nUne maison neuve sera certainement plus fonctionnelle, vous l'aménagerez selon vos goûts, mais vous risquez de devoir attendre les finitions pour emménager.\nDe la maison neuve en centre-ville à la maison ancienne à La Rochelle, votre rêve se cache certainement dans les annonces ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une maison en France ?",
            "content": "Les prix des biens sont très variables, que l'on fasse l'acquisition d'un logement récent ou plus vieux, suivant sa localisation dans l'une des régions en France et enfin selon les prestations proposées. Le prix moyen d'une maison en France est de {{prix_moyen}}, le prix le plus bas étant de {{prix_min}} et le prix le plus haut de {{prix_max}}."
          },
          {
            "title": "Combien y a-t-il de maisons disponibles en France ?",
            "content": "Il existe tous types de logements en France, on en répertorie environ 37 millions. Les résidences principales représentent environ 30 millions, 55 % de ces résidences principales sont des maisons individuelles. On compte {{annonces}} maisons disponibles en France."
          },
          {
            "title": "Comment trouver une maison à acheter en France ?",
            "content": "Trouver votre maison à acheter en France vous semble un parcours difficile, mais des outils sont à votre disposition. Comme les petites annonces de journaux spécialisés, des réseaux sociaux ou des vitrines des agences immobilières. Mais vous gagnerez certainement beaucoup de temps en consultant les annonces du site ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter une maison en France ?",
            "content": "Les délais de recherche d'une maison en vente en France peuvent être largement réduit en vous faisant accompagner par notre réseau de franchises ERA. Le délai d'attente depuis la signature d'un compromis de vente jusqu'à la signature de l'acte définitif devant notaire est en moyenne de 3 mois."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Afin de réaliser l'achat de votre maison en {{geo_object_name}} il est conseillé de respecter certaines étapes, pour cela il sera utile de vous faire accompagner par une agence. Une des succursales du réseau ERA Immobilier peut être présente à chaque étape de votre achat.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat maison en {{geo_object_name}}",
            "text": "Acheter une maison est le rêve de la plupart des personnes habitant en {{geo_object_name}}. Maison nouvelle ou maison ancienne c’est souvent le premier choix qui se présente aux futurs acheteurs. La recherche pourra s’orienter vers la tranquillité avec une maison au bord de l'eau, un bien en banlieue ou une maison de ville pour une question pratique."
          },
          {
            "title": "Avez-vous fait le choix de votre maison en {{geo_object_name}} ?",
            "text": "Le site ERA Immobilier propose près de 4000 maisons à mise en vente en France et un grand nombre en {{geo_object_name}} sur son site. Pour mieux viser vos critères, il est conseillé de constituer une liste non exhaustive de toutes vos exigences. Quel sont vos moyens ? Dans quelle ville doit se situer votre bien immobilier ? Afin de mieux sélectionner les annonces dans la catégorie maison, réfléchissez à toutes les données importantes pour votre famille, le nombre de chambres dont vous devez disposer, qui vous est indispensable, avec ou sans terrain, etc. Notre moteur ERA Immobilier vous aidera à sélectionner les annonces, à partir de vos moyens, par ville et en fonction de vos critères ; sans oublier de vous informer sur les évaluations énergétiques et vous donner un aperçu de la vie de votre futur quartier."
          },
          {
            "title": "Vous êtes sur le point d'acheter une maison neuve ou ancienne en {{geo_object_name}} ?",
            "text": "L’achat d'un appartement neuf ou ancien, cela peut influer sur le prix de vente et principalement sur les frais. Après avoir fait le point sur les avantages et les contraintes de chaque type de maison à connaitre, commencer votre rechercher et renseigner vos données sur le simulateur ERA. Une maison ancienne en pierres aura un certain attrait, mais son diagnostic énergétique sera sans doute moins bon. Une maison récente sera sûrement plus fonctionnelle, vous l’aménagerez selon vos goûts, mais vous risquez de devoir attendre pour emménager. De la maison neuve en centre-ville à la maison ancienne à la campagne, votre rêve se cache sûrement dans les annonces ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une maison en {{geo_object_name}} ?",
            "content": "Les prix des biens sont très variables, que l'on fasse l'acquisition d'un logement récent ou plus vieux, suivant sa localisation dans l'un des départements en {{geo_object_name}} et enfin selon les prestations proposées."
          },
          {
            "title": "Combien y a-t-il de maisons disponibles en {{geo_object_name}} ?",
            "content": "Il existe tous types de logements en {{geo_object_name}}, on en répertorie un nombre assez important. Les résidences principales sont souvent des maisons individuelles. On compte {{annonces}} maisons disponibles en {{geo_object_name}}."
          },
          {
            "title": "Comment trouver une maison à acheter en {{geo_object_name}} ?",
            "content": "Trouver votre maison à acheter en {{geo_object_name}} vous semble un parcours difficile, mais des outils sont à votre disposition. Comme les petites annonces de journaux spécialisés, des réseaux sociaux ou des vitrines des agences immobilières. Mais vous gagnerez certainement beaucoup de temps en consultant les annonces du site ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter une maison en {{geo_object_name}} ?",
            "content": "Les délais de recherche d'une maison en vente en {{geo_object_name}} peuvent être largement réduits en vous faisant accompagner par notre réseau de franchises ERA. Le délai d'attente depuis la signature d'un compromis de vente jusqu'à la signature de l'acte définitif devant notaire est en moyenne de 3 mois."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Une maison à l'achat dans votre département ({{geo_object_name}}) n'est pas toujours simple à trouver, vous faire aider dans cette étape par une agence immobilière peut être une bonne solution. Le site web ERA Immobilier référence un nombre important d'annonces de maisons à vendre dans votre département.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat maison {{geo_object_name}}",
            "text": "Acheter une maison est le rêve de la plupart des personnes ; c'est certainement le vôtre dans votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}. Maison nouvelle ou maison ancienne c’est souvent le premier choix qui se présente aux futurs acquéreurs. La recherche pourra s’orienter vers la tranquillité avec un bien à la campagne, une maison en centre-ville pour une question pratique."
          },
          {
            "title": "A qui vous adresser pour trouver votre maison dans votre département : {{geo_object_name}} ?",
            "text": "Le site ERA Immobilier propose de nombreuses maisons en vente dans votre département ({{geo_object_name}}) sur son site. Pour mieux cibler vos critères, il est conseillé d’en constituer une liste non exhaustive, avec toutes vos exigences. Quel est votre budget ? Près de quelle commune doit se trouver votre bien immobilier ? Afin de mieux choisir les annonces dans la catégorie maison à vendre réfléchissez à tous les impératifs importants pour votre famille, le nombre de chambres dont vous devez disposer, la surface qui vous est nécessaire, avec ou sans terrain, etc. Notre moteur ERA Immobilier pourra vous aider à choisir les annonces, à partir de votre budget, par commune de votre département ({{geo_object_name}}) et en fonction de vos critères ; sans oublier de vous communiquer les diagnostics énergétiques et vous donner un aperçu de la vie de votre futur quartier."
          },
          {
            "title": "Faut-il acheter une maison neuve ou à rénover dans votre département : {{geo_object_name}} ?",
            "text": "L’achat d'un lieu de vie neuf ou ancienne, cela peut influer sur le prix de vente et principalement sur les frais. Après avoir fait le point sur les possibilités et les contraintes de chaque type de maison à vendre, commencer votre recherche et renseigner vos infos sur le simulateur ERA. Une maison ancienne en pierres aura un certain style, mais son diagnostic énergétique peut-être moins bon. Une maison nouvelle sera à coup sûr plus fonctionnelle, vous l’aménagerez selon vos goûts, mais vous risquez de devoir attendre pour emménager. De la maison ancienne en centre-ville à la maison neuve dans son agglomération, votre rêve se cache à coup sûr dans les annonces ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une maison dans votre département : {{geo_object_name}} ?",
            "content": "Les prix des biens sont très variables, que l'on fasse l'acquisition d'un logement récent ou plus vieux, suivant sa localisation dans l'une des régions de votre département ({{geo_object_name}}) et enfin selon les prestations proposées. Le prix moyen d'une maison dans votre département ({{geo_object_name}}) est de {{prix_moyen}}, le prix le plus bas étant de {{prix_min}} et le prix le plus haut de {{prix_max}}."
          },
          {
            "title": "Combien y a-t-il de maisons disponibles dans votre département : {{geo_object_name}} ?",
            "content": "Il existe tous types de logements dans votre département : {{geo_object_name}}. Les résidences principales représentent une grande partie, un pourcentage de ces résidences principales sont des maisons individuelles. On compte {{annonces}} maisons disponibles en {{geo_object_name}}."
          },
          {
            "title": "Comment trouver une maison à acheter dans votre département : {{geo_object_name}} ?",
            "content": "Trouver votre maison à acheter dans votre département ({{geo_object_name}}) vous semble un parcours difficile, mais des outils sont à votre disposition. Comme les petites annonces de journaux spécialisés, des réseaux sociaux ou des vitrines des agences immobilières. Mais vous gagnerez certainement beaucoup de temps en consultant les annonces du site ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter une maison dans votre département : {{geo_object_name}} ?",
            "content": "Les délais de recherche d'une maison en vente dans votre département ({{geo_object_name}}) peuvent être largement réduit en vous faisant accompagner par notre réseau de franchises ERA. Le délai d'attente depuis la signature d'un compromis de vente jusqu'à la signature de l'acte définitif devant notaire est en moyenne de 3 mois."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "Afin de réaliser l'achat de votre maison à {{geo_object_name}}, il est conseillé de respecter certaines étapes, pour cela il est préférable de vous faire accompagner par un agent immobilier. Un de nos collaborateurs ERA Immobilier peut être présent à chaque étape de votre achat.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat maison à {{geo_object_name}}",
            "text": "Acheter une maison à {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}, est le rêve de la plupart des personnes à {{geo_object_name}}. Maison nouvelle ou maison ancienne c’est souvent le premier choix qui se présente aux acquéreurs. La recherche pourra s’orienter vers la sérénité avec une maison dans un des quartiers d'habitation, ou proche des commerciaux pour une question pratique."
          },
          {
            "title": "Quels sont vos critères pour l'achat de votre maison à {{geo_object_name}} ?",
            "text": "La commune de {{geo_object_name}}, a une superficie de {{superficie}}. Elle a certainement des maisons à l'achat sur le site ERA Immobilier. Pour mieux viser vos critères, il est conseillé de constituer une liste non exhaustive de toutes vos attentes. Quel est votre budget ? Dans quel quartier doit se trouver votre bien immobilier ? Afin de mieux faire le choix des annonces dans la catégorie maison, réfléchissez à toutes les données importantes pour votre famille, le nombre de chambres qui vous est nécessaire, avec ou sans terrain, etc. Notre moteur ERA Immobilier pourra vous aider à faire le choix des annonces, à partir de votre budget, par quartier et en fonction de vos critères ; sans oublier de vous renseigner les évaluations énergétiques et vous donner un aperçu de la vie de votre futur quartier."
          },
          {
            "title": "Faut-il acheter une maison neuve ou ancienne à {{geo_object_name}} ?",
            "text": "L’achat d'un logement neuf ou ancienne, cela peut impacter sur le prix de vente et principalement sur les frais. Après avoir fait le point sur les avantages et les inconvénients de chaque type de maison, commencer votre recherche et renseigner vos infos sur le simulateur ERA. Une maison ancienne en pierres aura beaucoup d'élégance, mais son diagnostic énergétique peut-être moins bon. Une maison nouvelle sera certainement plus fonctionnelle, vous l’aménagerez selon vos goûts, mais vous risquez de devoir attendre la fin des travaux pour emménager. De la maison neuve en quartier résidentiel à {{geo_object_name}}, à la maison ancienne dans sa périphérie, votre rêve se cache certainement dans les annonces ERA."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une maison à {{geo_object_name}} ?",
            "content": "Les prix des biens sont très variables, que l'on fasse l'acquisition d'un logement récent ou plus vieux, suivant sa localisation dans l'un des quartiers à {{geo_object_name}} et enfin selon les prestations proposées. Le prix moyen d'une maison à {{geo_object_name}} est de {{prix_moyen}}, le prix le plus bas étant de {{prix_min}} et le prix le plus haut de {{prix_max}}."
          },
          {
            "title": "Combien y a-t-il de maisons disponibles à {{geo_object_name}} ?",
            "content": "Il existe tous types de logements à {{geo_object_name}}, on répertorie environ des résidences principales et des maisons de vacances. On compte {{annonces}} maisons disponibles à {{geo_object_name}} ({{code_postal}}), qui compte {{nbr_habitant}} habitants."
          },
          {
            "title": "Comment trouver une maison à acheter à {{geo_object_name}} ?",
            "content": "Trouver votre maison à acheter à {{geo_object_name}} vous semble un parcours difficile, mais des outils sont à votre disposition. Comme les petites annonces de journaux spécialisés, des réseaux sociaux ou des vitrines des agences immobilières. Mais vous gagnerez certainement beaucoup de temps en consultant les annonces du site ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter une maison à {{geo_object_name}} ?",
            "content": "Les délais de recherche d'une maison en vente à {{geo_object_name}} peuvent être largement réduit en vous faisant accompagner par notre réseau de franchises ERA. Le délai d'attente depuis la signature d'un compromis de vente jusqu'à la signature de l'acte définitif devant notaire est en moyenne de 3 mois."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    },
    "TERRAIN": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "Toutes les annonces et les offres de terrains à l'achat en France, sur le site ERA Immobilier, sont consultables rapidement ; l'ensemble des informations nécessaires pour vous orienter dans votre choix sont en permanence à votre disposition.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat terrain France",
            "text": "Acheter son terrain en France, c'est le plus souvent faire un achat dans le but de bâtir sa maison individuelle. Isolé ou en lotissement, c'est une réflexion que vous devez mener avant de commencer vos recherches pour que votre projet convienne à votre vie de famille."
          },
          {
            "title": "Besoin d'aide pour faire le choix de votre terrain à bâtir ?",
            "text": "Choisir son terrain constructible est la première étape lorsque l'on a décidé de faire bâtir son logement.\nVous trouverez de nombreuses annonces de terrains à bâtir sur les sites d'annonces immobilières, comme ERA Immobilier. Vous recherchez un terrain à la campagne ou dans une ville précise de votre département, vous trouverez à coup sûr la parcelle correspondant à vos critères. Vous avez également la possibilité de faire la recherche de votre terrain en faisant appel à un constructeur ou à une agence ERA de votre ville.\nQuelle que soit votre méthode de recherche, votre projet immobilier devra être établi en fonction de la nature du sol de votre terrain. C'est pourquoi il est préférable de vous faire accompagner lors de son choix afin qu'il puisse accueillir votre future maison. Un professionnel pensera à observer les murs des maisons sur les terrains attenants, l'orientation de la propriété voisine afin de pouvoir déterminer la meilleure disposition des pièces de votre future maison."
          },
          {
            "title": "Venez trouver votre terrain à bâtir",
            "text": "Le budget global de votre projet, le type de maison qui sera bâti sur votre propriété, ainsi que la nature, la localisation et la surface souhaitées pour votre terrain à bâtir, vont vous guider dans votre recherche. Sur notre site les fiches de plusieurs produits vous seront proposées, vous informant de manière assez détaillée sur le quartier du bien, les services de proximité. Vous aurez également accès à un simulateur financier. Les coordonnées de l'agence et de notre conseiller, en charge de cette annonce, seront visibles, avec la possibilité de le contacter par mail.\nUne alerte peut également être créée, afin d'être informé des nouveaux terrains disponibles pour un achat."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un terrain en France ?",
            "content": "Le prix d'un terrain en France est très variable, le plus bas pour l'achat d'un terrain est de {{prix_min}}, le plus haut de {{prix_max}}, le prix moyen s'élevant à {{prix_moyen}}. On peut acquérir un terrain à un prix très abordable s'il est situé, par exemple, dans une petite commune peu desservie ; mais le tarif sera beaucoup plus élevé s'il fait partie d'un projet immobilier en centre-ville."
          },
          {
            "title": "Combien y a-t-il de terrains disponibles en France ?",
            "content": "Le nombre de terrains disponibles à la vente est assez important, le nombre de terrains disponibles en France est de {{annonces}}. Vous pourrez en trouver sur le site ERA en renseignant vos critères tels que votre secteur, la surface souhaitée."
          },
          {
            "title": "Comment trouver un terrain à acheter en France ?",
            "content": "Trouver un terrain à acheter en France, correspondant à son projet immobilier, n'est pas toujours facile. Il est préférable de se faire accompagner par son agent immobilier ou son constructeur. Epluchez régulièrement les petites annonces de journaux spécialisés, des réseaux sociaux, des vitrines des agences immobilières et, plus particulièrement, d'ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter un terrain en France ?",
            "content": "Le délai moyen qui s'écoule entre la signature du compromis de vente d'un terrain et la signature du contrat définitif devant notaire est de trois mois. A ce délai il faudra ajouter toutes les étapes de la construction dans le cas d'un achat pour une construction de maison individuelle."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Venez faire votre sélection parmi nos nombreuses exclusivités pour l'achat d'un terrain en {{geo_object_name}}. Que vous vouliez acquérir un terrain proche d'une ville ou à la campagne, créez une alerte pour vous permettre d'être prévenu dès la parution des nouvelles annonces.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat terrain {{geo_object_name}}",
            "text": "Acheter son terrain à bâtir en {{geo_object_name}}, c’est le plus souvent faire un achat dans le but de bâtir une maison individuelle. Isolé ou en lotissement, c’est une réflexion que vous devez mener avant de commencer vos recherches pour que votre projet convienne à votre vie de famille."
          },
          {
            "title": "Faire le choix de votre terrain à bâtir en {{geo_object_name}}",
            "text": "Choisir son terrain constructible est la première étape quand l’on a décidé avant de faire bâtir son habitation. Vous obtiendrez de multiples annonces de lot à bâtir sur les sites d’annonces immobilières, comme ERA Immobilier. Vous désirez trouver un terrain en province ou dans une ville de votre région, vous obtiendrez automatiquement la parcelle correspondant à vos critères. Vous avez également la possibilité de faire la recherche de votre terrain en faisant appel à un constructeur ou à une agence ERA de votre agglomération. Quelle que soit votre méthode de recherche, votre projet immobilier devra être tenu en fonction de la nature du sol de votre terrain. C’est pourquoi il est nécessaire de vous faire aider lors du choix de la parcelle qui devra accueillir votre future maison. Un professionnel pensera à regarder les murs des maisons sur les terrains attenants, l’orientation de la propriété voisine afin de pouvoir déterminer la meilleure disposition des pièces de votre future maison."
          },
          {
            "title": "Trouver son terrain à bâtir en {{geo_object_name}}",
            "text": "Le budget global de votre projet, le type de maison qui sera bâti sur votre propriété, ainsi que la nature, la localisation et la surface voulues pour votre terrain à bâtir, vont vous guider dans votre recherche. Sur notre site les notices de plusieurs produits vous seront proposées, vous informant de manière assez détaillée sur le quartier du bien, les services de proximité. Vous aurez également accès à un simulateur financier. Les informations de contacts de l’agence, et de notre agent en charge de cette annonce, seront renseignés, avec la possibilité de le contacter par mail. Une alerte peut également créée afin d’être informé des nouveaux terrains disponibles pour un achat."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un terrain en {{geo_object_name}} ?",
            "content": "Le prix d'un terrain en {{geo_object_name}} est très variable. On peut acquérir un terrain à un prix très abordable s'il est situé, par exemple, dans une petite commune peu desservie ; mais le tarif sera beaucoup plus élevé s'il fait partie d'un projet immobilier en centre-ville."
          },
          {
            "title": "Combien y a-t-il de terrains disponibles en {{geo_object_name}} ?",
            "content": "Le nombre de terrains disponibles à la vente est assez important, le nombre de terrains disponibles en {{geo_object_name}} est de {{annonces}}. Vous pourrez en trouver sur le site ERA en renseignant vos critères tels que votre secteur, la surface souhaitée."
          },
          {
            "title": "Comment trouver un terrain à acheter en {{geo_object_name}} ?",
            "content": "Trouver un terrain à acheter en {{geo_object_name}}, correspondant à son projet immobilier, n'est pas toujours facile. Il est préférable de se faire accompagner par son agent immobilier ou son constructeur. Epluchez régulièrement les petites annonces de journaux spécialisés, des réseaux sociaux, des vitrines des agences immobilières et, plus particulièrement, d'ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter un terrain en {{geo_object_name}} ?",
            "content": "Le délai moyen qui s'écoule entre la signature du compromis de vente d'un terrain et la signature du contrat définitif devant notaire est de trois mois. A ce délai il faudra ajouter toutes les étapes de la construction dans le cas d'un achat pour une construction de maison individuelle."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Trouvez un terrain à l'achat dans votre département, {{geo_object_name}}, grâce aux annonces du site internet des franchises ERA Immobilier. Domaines forestiers, agricoles ou terrains à bâtir, les offres exclusives sont nombreuses et variées.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat terrain {{geo_object_name}}",
            "text": "Acheter son terrain à bâtir dans votre département : {{geo_object_name}}, c’est le plus souvent faire un achat dans l'idée de bâtir sa maison. Isolé ou en lotissement, c’est une question que vous devez vous poser avant de commencer vos recherches pour que votre projet convienne à votre vie de famille."
          },
          {
            "title": "Pour bien choisir votre terrain à bâtir dans votre département : {{geo_object_name}}",
            "text": "Choisir son terrain constructible est la première phase dès que l’on a décidé de faire bâtir son habitation. Vous retrouverez de nombreuses annonces de lot à bâtir sur les sites d’annonces immobilières, comme ERA Immobilier. Vous désirez faire l'achat d'un terrain à la campagne ou dans une ville spécifique de votre département, vous obtiendrez à coup sûr la parcelle correspondant à vos critères. Vous avez également la possibilité de trouver votre terrain en faisant appel à un constructeur ou à une agence ERA de votre région. Quelle que soit votre méthode de recherche, votre projet de construction devra être tenu en fonction de la nature du sol de votre terrain. C’est pourquoi il convient de vous faire aider lors de son choix afin qu’il puisse accueillir votre future maison. Un professionnel pensera à regarder les murs des maisons sur les terrains attenants, l’orientation de la propriété voisine afin de pouvoir déterminer la meilleure disposition des pièces de votre future logis."
          },
          {
            "title": "Trouver votre terrain à bâtir dans votre département : {{geo_object_name}}",
            "text": "Le budget global de votre projet, la catégorie de maison qui devra être bâti sur votre propriété, ainsi que la nature, la localisation et la surface voulues pour votre terrain à bâtir, vont vous guider dans votre recherche. Sur le moteur ERA Immobilier France, un large choix de locations de maisons, ce qui devrait vous permettre de trouver rapidement un terrain à bâtir, dans votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}. Les fiches détaillées de plusieurs produits vous seront proposées, vous informant de manière assez complète sur le quartier du bien, les services de proximité. Vous aurez également accès à un simulateur financier. Les coordonnées de l’agence et de notre coach, en charge de cette annonce, seront renseignées, avec la possibilité de le contacter par messagerie. Une alerte peut également être crée afin d’être prévenu des nouveaux terrains disponibles pour un achat."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un terrain dans ce département : {{geo_object_name}} ?",
            "content": "Le prix d'un terrain dans ce département : {{geo_object_name}}, est très variable. Le plus bas pour l'achat d'un terrain est de {{prix_min}}, le plus haut de {{prix_max}}, le prix moyen s'élevant à {{prix_moyen}}. On peut acquérir un terrain à un prix très abordable s'il est situé, par exemple, dans une petite commune peu desservie ; mais le tarif sera beaucoup plus élevé s'il fait partie d'un projet immobilier en centre-ville."
          },
          {
            "title": "Combien y a-t-il de terrains disponibles dans ce département : {{geo_object_name}} ?",
            "content": "Le nombre de terrains disponibles à la vente est assez important, le nombre de terrains disponibles dans ce département : {{geo_object_name}}, est de {{annonces}}. Vous pourrez en trouver sur le site ERA en renseignant vos critères tels que votre secteur, la surface souhaitée."
          },
          {
            "title": "Comment trouver un terrain à acheter dans ce département : {{geo_object_name}} ?",
            "content": "Trouver un terrain à acheter dans ce département : {{geo_object_name}}, correspondant à son projet immobilier, n'est pas toujours aisé. Il est préférable de se faire accompagner par son agent immobilier ou son constructeur. Epluchez régulièrement les petites annonces de journaux spécialisés, des réseaux sociaux, des vitrines des agences immobilières et, plus particulièrement, d'ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter un terrain dans ce département : {{geo_object_name}} ?",
            "content": "Le délai moyen qui s'écoule entre la signature du compromis de vente d'un terrain et la signature du contrat définitif devant notaire est de trois mois. A ce délai il faudra ajouter toutes les étapes de la construction, dans le cas d'un achat pour une construction de maison individuelle."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "ERA Immobilier vous accompagne dans votre recherche de terrain à l'achat à {{geo_object_name}}. Grâce à son site web, ou dans ses agences immobilières vous pouvez, à tout moment, consulter les annonces de vente de terrains référencées pour votre ville.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat terrain à {{geo_object_name}}",
            "text": "Faire l'acquisition son terrain à bâtir à {{geo_object_name}}, c’est le plus souvent faire un achat dans l'optique de bâtir sa maison. Isolé ou en lotissement, c’est une que vous devez mener avant de commencer vos recherches pour que votre projet convienne à votre vie de famille. Vous souhaitez acheter à {{geo_object_name}} ({{code_postal}}) ville qui compte {{nbr_habitant}} habitants."
          },
          {
            "title": "Vous débutez vos recherches de terrain à bâtir à {{geo_object_name}}",
            "text": "Choisir son terrain constructible est la première étape dès que l’on a décidé de faire bâtir son habitation. Vous aurez de nombreuses annonces de terrain à bâtir sur les sites d’annonces immobilières, comme ERA Immobilier. Vous êtes à la recherche d'un terrain à la périphérie ou en centre-ville de {{geo_object_name}}, vous aurez à coup sûr la zone correspondant à vos critères. Vous avez également la possibilité de faire le choix de votre terrain en faisant appel à un constructeur ou à une agence ERA de votre commune. Quelle que soit votre méthode de recherche, votre projet de construction devra être établi en fonction de la nature du sol de votre terrain. C’est pourquoi il convient de vous faire aider lors de sa recherche, il devra pouvoir accueillir votre habitation. Un professionnel pensera à regarder les murs des maisons sur les terrains attenants, l’orientation de la propriété voisine afin de pouvoir déterminer la meilleure disposition des pièces de votre future maison."
          },
          {
            "title": "Une aide vous serait utile pour cette recherche de terrain à bâtir à {{geo_object_name}}",
            "text": "Le budget global de votre projet, la catégorie de maison qui sera bâti sur votre propriété, ainsi que la nature, la localisation souhaitées pour votre terrain à bâtir, vont vous guider dans votre recherche. Sur notre magasin en ligne les notices de plusieurs produits vous seront proposées, vous informant de façon assez précise, avec un support entièrement dédié à votre satisfaction sur le quartier du bien, les services de proximité. Vous aurez également accès à un simulateur financier. Les coordonnées de l’agence et de notre coach, en charge de cette annonce, seront précisés, avec la possibilité de le joindre par mail. Une alerte peut également être créée afin d’être informé des nouveaux terrains disponibles."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un terrain à {{geo_object_name}} ?",
            "content": "On peut acquérir un terrain à un prix très abordable s'il est situé, par exemple, dans en banlieue peu desservie {{geo_object_name}} ; mais le tarif sera beaucoup plus élevé s'il fait partie d'un projet immobilier près de son centre-ville."
          },
          {
            "title": "Combien y a-t-il de terrains disponibles à {{geo_object_name}} ?",
            "content": "Le nombre de terrains disponibles à la vente est assez important. Vous pourrez en trouver sur le site ERA en renseignant vos critères tels que votre secteur, la surface souhaitée."
          },
          {
            "title": "Comment trouver un terrain à acheter à {{geo_object_name}} ?",
            "content": "Trouver un terrain à acheter à {{geo_object_name}}, correspondant à son projet immobilier, n'est pas toujours facile. Il est préférable de se faire accompagner par son agent immobilier ou son constructeur. Epluchez régulièrement les petites annonces de journaux spécialisés, des réseaux sociaux, des vitrines des agences immobilières et, plus particulièrement, d'ERA Immobilier."
          },
          {
            "title": "Quel est le délai moyen pour acheter un terrain à {{geo_object_name}} ?",
            "content": "Le délai moyen qui s'écoule entre la signature du compromis de vente d'un terrain et la signature du contrat définitif devant notaire est de trois mois. A ce délai il faudra ajouter toutes les étapes de la construction dans le cas d'un achat pour une construction de maison individuelle à {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
          }
        ]
      }
    },
    "PROGRAMMES-NEUFS": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "Découvrez les programmes immobiliers neufs en France dans les agences ERA Immobilier de votre région ou sur notre site internet, que vous effectuiez votre recherche pour vous loger ou pour un investissement.",
        "BOTTOM_CONTENT": [
          {
            "title": "Programme immobilier neuf France",
            "text": "Des programmes immobiliers neufs très divers sont proposés à la vente, souvent sur plan. Que ce soit l'appartement en ville, la maison familiale, l'immeuble de rapport, ou encore la résidence de service pour investissement."
          },
          {
            "title": "Vous cherchez un programme immobilier neuf ?",
            "text": "Grâce au moteur de recherche ERA vous aurez la possibilité de filtrer un programme immobilier neuf selon votre choix, dans l'objectif d'un investissement dans des appartements ou pour la recherche de votre logement.\nNotre moteur de recherche permet de filtrer différents critères pour trouver le programme qui vous convient, que ce soit à proximité de Paris, Marseille, Lyon, Montpellier ou d'autres villes en France.\nSuivant les plans, photos et programmes des promoteurs, les fiches des dispositifs sont renseignées de la manière la plus détaillée possible. La superficie des maisons ou appartements, leur localité, leur prix de vente, également le nombre de pièces, ainsi que de nombreux autres critères qui pourront orienter votre choix."
          },
          {
            "title": "L'immobilier neuf est votre choix de vie ?",
            "text": "Vous épluchez actuellement les annonces des programmes immobiliers neufs en vente, vous avez donc certainement fait ce choix d'achat pour votre futur logement. Sur notre site de nombreux filtres sont à votre disposition pour vous orienter vers le bon projet, comme des informations sur les appartements, prix, confort, quartier. Forcément, parmi ces programmes immobiliers neufs vous trouverez celui qui correspond à votre choix de vie.\nLa vente d'immobilier neuf rencontre un grand succès sur le marché actuel. Les dispositifs, tels que la loi Pinel et le prêt à taux zéro, vous feront bénéficier d'une fiscalité intéressante si votre achat est destiné à être louer par la suite.\nL'un des programmes immobiliers neufs proposé par ERA Immobilier vous intéresse ? Laissez-vous guider pour trouver le projet conforme à votre recherche."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un programme immobilier en France ?",
            "content": "Le prix d'un programme immobilier en France peut être très variable selon qu'il se situe en île de France, proche d'un centre-ville, etc. Il dépend du type de logements qui y seront construits selon les plans, de leur nombre de pièces, etc. ; mais aussi du marché immobilier actuel."
          },
          {
            "title": "Combien y a-t-il de programmes immobiliers disponibles en France ?",
            "content": "Les programmes immobiliers sont très divers, maisons individuelles, lotissements, appartements dans des immeubles neufs, ... pour habiter ou investir. Le nombre de programmes immobiliers neufs en France est de {{annonces}}."
          },
          {
            "title": "Comment trouver un programme immobilier en France ?",
            "content": "Pour trouver un programme immobilier en France, que ce soit pour investir ou trouver un logement, vous avez la possibilité de consulter les petites annonces. Les annonces de notre site internet vous présentent les programmes en cours de réalisation."
          },
          {
            "title": "Quel est le délai moyen pour la livraison d'un programme immobilier en France ?",
            "content": "Un promoteur immobilier ne peut faire démarrer son programme de construction que lorsqu'il a obtenu le permis de construire de son projet. Le délai entre le début du chantier et la livraison des logements peut être de 12 à 24 mois."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Trouvez le programme immobilier neuf idéal en {{geo_object_name}}, grâce aux annonces d'achats des agences immobilières ERA Immobilier. Pour investisseurs ou pour un futur logement, les offres exclusives de programmes immobiliers neufs sont variées.",
        "BOTTOM_CONTENT": [
          {
            "title": "Programme immobilier neuf en {{geo_object_name}}",
            "text": "Des programmes immobiliers neufs très différents sont proposés à la vente, souvent sur plan. Que ce soit l’appartement en ville, la maison familiale, l’immeuble de rapport, ou la résidence de service pour investissement."
          },
          {
            "title": "Vous êtes à la recherche d'un programme immobilier neuf en {{geo_object_name}} ?",
            "text": "Grâce au champ de rechercher ERA vous aurez la possibilité de filtrer un programme immobilier neuf selon votre sélection, dans le cas d’un investissement dans des appartements ou un immeuble. Notre moteur de recherche permet de filtrer différents critères pour trouver le programme qui vous convient, que ce soit à proximité d'une grande, moyenne ou petite ville en {{geo_object_name}}. Suivant les plans, photos et programmes des promoteurs, le descriptif des ouvrages sont renseignées de la manière la plus détaillée possible. La superficie des maisons ou appartements, leur localité, leur prix de vente, également le nombre de pièces, ainsi que de nombreux autres critères qui pourront guider votre sélection."
          },
          {
            "title": "L’immobilier neuf est votre option de vie en {{geo_object_name}} ?",
            "text": "Vous épluchez actuellement les annonces des programmes immobiliers neufs en vente, vous avez donc sûrement fait ce choix d’achat pour votre futur logement. Les outils de recherche, constitués de nombreux filtres, sont à votre disposition pour vous guider vers le bon projet, comme des informations sur les appartements, prix, confort, quartier. Forcément, parmi ces programmes immobiliers neufs, vous obtiendrez celui qui se rapporte à votre choix de vie. La vente des constructions neuves rencontre un grand succès sur le marché actuel. Les dispositifs, tels que la loi Pinel et le prêt à taux zéro, vous offriront une fiscalité avantageuse si votre achat est destiné à être louer par la suite. L’un des programmes immobiliers neufs proposé par ERA Immobilier vous intéresse ? Laissez-vous guider pour trouver le projet conforme à votre recherche."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un programme immobilier en {{geo_object_name}} ?",
            "content": "Le prix d'un programme immobilier en {{geo_object_name}} peut être très variable selon qu'il se situe dans la ville préfecture, proche d'un petit bourg, etc. Il dépend du type de logements qui y seront construits selon les plans, de leur nombre de pièces, etc. ; mais aussi du marché immobilier actuel."
          },
          {
            "title": "Combien y a-t-il de programmes immobiliers disponibles en {{geo_object_name}} ?",
            "content": "Les programmes immobiliers sont très divers, maisons individuelles, lotissements, appartements dans des immeubles neufs, ... pour habiter ou investir. Le nombre de programmes immobiliers neufs en {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un programme immobilier en {{geo_object_name}} ?",
            "content": "Pour trouver un programme immobilier en {{geo_object_name}}, que ce soit pour investir ou trouver un logement, vous avez la possibilité de consulter les petites annonces. Les annonces de notre site internet vous présentent les programmes en cours de réalisation."
          },
          {
            "title": "Quel est le délai moyen pour la livraison d'un programme immobilier en {{geo_object_name}} ?",
            "content": "Un promoteur immobilier ne peut faire démarrer son programme de construction que lorsqu'il a obtenu le permis de construire de son projet. Le délai entre le début du chantier et la livraison des logements peut être de 12 à 24 mois."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Venez faire votre sélection parmi nos nombreuses exclusivités pour l'achat d'un programme immobilier neuf dans votre département : {{geo_object_name}}. Que vous souhaitiez acheter dans un programme proche d'une ville ou à la campagne, créez une alerte pour être prévenu dès la publication des nouvelles annonces.",
        "BOTTOM_CONTENT": [
          {
            "title": "Programme immobilier neuf {{geo_object_name}}",
            "text": "Des programmes immobiliers neufs très divers sont proposés à la vente, souvent sur plan. Que ce soit l’appartement en ville, la maison de famille, l’immeuble de rapport, ou aussi la résidence de service pour investissement. Un large choix de programmes immobiliers sont présents sur le site ERA Immobilier France, ce qui devrait vous permettre de trouver rapidement le vôtre, dans votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}."
          },
          {
            "title": "Vous avez opté pour un achat immobilier neuf dans votre département : {{geo_object_name}} ?",
            "text": "Grâce à la barre de recherche ERA vous aurez la possibilité de filtrer un programme immobilier neuf selon votre sélection, dans l’objectif d’un investissement dans des appartements ou pour une résidence. Notre moteur de recherche permet de filtrer différents critères pour trouver le programme qui vous convient, que ce soit à proximité de la capitale, ou d’autres villes de votre département : {{geo_object_name}}. Suivant les plans, photos et programmes des promoteurs, les notices des dispositifs sont renseignées de la manière la plus détaillée possible. La superficie des maisons ou appartements, leur localité, leur prix de vente, également le nombre de pièces, ainsi que de nombreux autres critères qui pourront orienter votre sélection."
          },
          {
            "title": "L’immobilier neuf, l'avez votre sélectionné pour votre vie dans ce département : {{geo_object_name}} ?",
            "text": "Vous épluchez maintenant les annonces des programmes immobiliers neufs en vente, vous avez donc certainement fait ce choix d’achat pour votre futur logement. Sur notre agence en ligne de nombreux filtres sont à votre disposition pour vous orienter vers le bon projet, comme des informations sur les appartements, prix, confort, quartier. Forcément, parmi ces programmes immobiliers neufs vous obtiendrez celui se rapportant à votre choix. La vente d'actions immobilières neuves rencontre un large succès sur le marché actuel. Les processus, comme la loi Pinel et le disponible à taux zéro, vous feront disposer d’une fiscalité avantageuse si votre achat est destiné à être louer par la suite. L’un des programmes immobiliers neufs proposé par ERA Immobilier vous intéresse ? Laissez-vous guider pour trouver le projet identique à votre recherche."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un programme immobilier dans votre département : {{geo_object_name}} ?",
            "content": "Le prix d'un programme immobilier dans votre département ({{geo_object_name}}) peut être très variable selon qu'il se situe dans sa ville principale, proche d'un centre-ville, etc. Il dépend du type de logements qui y seront construits selon les plans, de leur nombre de pièces, etc. ; mais aussi du marché immobilier actuel. "
          },
          {
            "title": "Combien y a-t-il de programmes immobiliers disponibles dans votre département : {{geo_object_name}} ?",
            "content": "Les programmes immobiliers sont très divers, maisons individuelles, lotissements, appartements dans des immeubles neufs, ... pour habiter ou investir. Le nombre de programmes immobiliers neufs dans votre département ({{geo_object_name}}) est de {{annonces}}."
          },
          {
            "title": "Comment trouver un programme immobilier dans votre département : {{geo_object_name}} ?",
            "content": "Pour trouver un programme immobilier dans votre département : {{geo_object_name}}, que ce soit pour investir ou trouver un logement, vous avez la possibilité de consulter les petites annonces. Les annonces de notre site internet vous présentent les programmes en cours de réalisation."
          },
          {
            "title": "Quel est le délai moyen pour la livraison d'un programme immobilier dans votre département : {{geo_object_name}} ?",
            "content": "Un promoteur immobilier ne peut faire démarrer son programme de construction que lorsqu'il a obtenu le permis de construire de son projet. Le délai entre le début du chantier et la livraison des logements peut être de 12 à 24 mois."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "Toutes les annonces et les offres de programmes immobiliers neufs dans votre ville, sont sur le site ERA Immobilier ; elles sont consultables en quelques clics. Les informations nécessaires pour vous épauler dans votre recherche sont également disponibles dans notre agence de {{geo_object_name}}.",
        "BOTTOM_CONTENT": [
          {
            "title": "Programme immobilier neuf à {{geo_object_name}}",
            "text": "Des programmes immobiliers neufs très divers sont proposés à la vente, souvent sur plan, à {{geo_object_name}} ({{code_postal}}) dont le nombre d’habitants, les {{gentilet}}, est de {{nbr_habitant}}. Que ce soit l’appartement à la capitale, la maison de famille, l’immeuble de rapport, ou encore la résidence de service pour investissement."
          },
          {
            "title": "Comment pouvez-vous chercher un programme immobilier neuf à {{geo_object_name}} ?",
            "text": "Grâce au moteur de recherche ERA vous aurez la possibilité de filtrer un programme immobilier neuf à {{geo_object_name}} selon votre choix, dans le contexte d’un investissement dans des appartements ou pour de résidences. Notre outil de recherche permet de filtrer différents critères pour trouver le programme qui vous convient, que ce soit à proximité de la boulangerie ou près de la mairie de {{geo_object_name}}. Suivant les plans, photos et programmes des promoteurs, les notices explicatives des dispositifs sont renseignées de la manière la plus détaillée possible. La superficie des maisons ou appartements, leur localisation, leur prix de vente, aussi le nombre de pièces, ainsi que de nombreux autres critères qui pourront orienter votre choix."
          },
          {
            "title": "L’immobilier neuf est votre choix de vie à {{geo_object_name}} ?",
            "text": "Vous épluchez actuellement les annonces des programmes immobiliers neufs en vente à {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}, vous avez donc sans aucun doute fait ce choix d’achat pour votre futur logement. Sur le site ERA de nombreux filtres sont à votre disposition pour vous orienter vers le bon projet, comme des infos sur les appartements, prix, confort, quartier. Forcément, parmi ces programmes immobiliers neufs vous trouverez celui qui correspond à votre choix de vie. La vente d’immobilier neuf rencontre un large succès sur le marché moderne. Les dispositifs, comme par exemple la mesure fiscale Pinel et le taux zéro, vous feront bénéficier d’aides intéressantes si votre achat est destiné à être mettre en location par la suite. L’un des programmes immobiliers neufs proposé par ERA Immobilier vous intéresse ? Laissez-vous guider pour trouver le projet conforme à votre recherche."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un programme immobilier à {{geo_object_name}} ?",
            "content": "Le prix d'un programme immobilier à {{geo_object_name}} peut être très variable selon qu'il se situe dans un quartier résidentiel de {{geo_object_name}} ou dans sa banlieue. Il dépend du type de logements qui y seront construits selon les plans, de leur nombre de pièces, etc. ; mais aussi du marché immobilier actuel."
          },
          {
            "title": "Combien y a-t-il de programmes immobiliers disponibles à {{geo_object_name}} ?",
            "content": "Les programmes immobiliers sont très divers, maisons individuelles, lotissements, appartements dans des immeubles neufs, ... pour habiter ou investir. Le nombre de programmes immobiliers neufs à {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un programme immobilier à {{geo_object_name}} ?",
            "content": "Pour trouver un programme immobilier à {{geo_object_name}}, dont la superficie est de {{superficie}}, que ce soit pour investir ou trouver un logement, vous avez la possibilité de consulter les petites annonces ou prendre contact avec la mairie, dont le maire est {{maire}}. Les annonces de notre site internet vous présentent les programmes en cours de réalisation."
          },
          {
            "title": "Quel est le délai moyen pour la livraison d'un programme immobilier à {{geo_object_name}} ?",
            "content": "Un promoteur immobilier ne peut faire démarrer son programme de construction que lorsqu'il a obtenu le permis de construire de son projet. Le délai entre le début du chantier et la livraison des logements peut être de 12 à 24 mois."
          }
        ],
        "ARRONDISSEMENT": {
          "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
          "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
        }
      }
    },
    "IMMEUBLE": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "Découvrez et faites votre sélection parmi nos nombreuses exclusivités pour l'achat d'un immeuble en France. Que vous vouliez acquérir du neuf ou de l'ancien, créez une alerte pour vous permettre d'être prévenu dès la parution des nouvelles annonces.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat immeuble France",
            "text": "Vous êtes à la recherche d'un immeuble à l'achat en France, notre site internet met à votre disposition un moteur vous permettant une vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d'appartements référencés, grâce à notre réseau de franchises, vous permettra de trouver les immeubles de logements ou commerces ayant un fort potentiel."
          },
          {
            "title": "Choisir le type de votre immeuble de rapport",
            "text": "Vous souhaitez acquérir un immeuble de rapport, vous allez devoir faire votre choix parmi les annonces selon vos critères. ERA vous aidera dans cette étape, immeuble avec appartement de 5 pièces, proposant 4 logements ? Le bien de vos rêves est présent parmi ceux référencés sur notre site.\nNotre menu vous aidera à affiner votre recherche. Et, si vous hésitez encore, pour le choix du genre d'immeuble que vous allez acheter, le prix, la surface et l'état renseignés dans chaque fiche vous orienteront.\nVous avez également la possibilité de créer une alerte afin de recevoir les nouvelles offres de vente d'immeuble de rapport ; mais également de prendre contact avec l'agence de votre département qui les gère afin d'obtenir encore plus de conseils."
          },
          {
            "title": "Trouver un programme immobilier",
            "text": "Parmi les offres ERA vous pourrez rechercher un hôtel restaurant, un local commercial, une résidence ou un immeuble à vendre en France.\nVotre projet, qu'il se situe dans votre région, à Saint-Flour ou en centre-ville d'une autre commune de France, se trouve certainement parmi les exclusivités d'une agence ERA.\nLes photos et les descriptions des immeubles à vendre, vous renseigneront plus précisément en vous donnant des détails. Tels que la surface de chaque immeuble, le nombre de niveaux, de pièces et chambres de ses appartements, mais aussi s'ils sont pourvus d'une terrasse, d'un garage, d'un parking, d'un jardin, ..."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un immeuble en France ?",
            "content": "Le prix d'un immeuble de rapport en France est assez variable ; suivant sa localisation, en centre-ville ou en région. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements. Le prix le plus bas est de {{prix_min}}, le plus haut de {{prix_max}}, le prix moyen s'élevant à {{prix_moyen}}."
          },
          {
            "title": "Combien y a-t-il d'immeubles disponibles en France ?",
            "content": "Les immeubles à la vente en France sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Le nombre d'immeubles disponibles en France est de {{annonces}}."
          },
          {
            "title": "Comment trouver un immeuble à acheter en France ?",
            "content": "Pour trouver un immeuble à acheter en France, en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
          },
          {
            "title": "Quel est le délai moyen pour acheter un immeuble en France ?",
            "content": "Le délai pour l'achat de votre immeuble en France est variable, il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "L'achat d'un immeuble en {{geo_object_name}} est votre choix pour un investissement. Trouver le programme conforme à ses critères peut être un parcours difficile sans l'aide d'un expert. ERA Immobilier référence des annonces d'immeubles à la vente dans toute la région {{geo_object_name}}.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat immeuble en {{geo_object_name}}",
            "text": "Vous cherchez un bâtiment à l’achat en {{geo_object_name}}, notre site internet met à votre disposition un moteur rendant possible une vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous épaulera pour trouver les habitations, appartements ou établissements ayant un fort potentiel."
          },
          {
            "title": "Vous recherchez un immeuble de rapport en {{geo_object_name}} ?",
            "text": "Vous souhaitez acheter un bâtiment de rapport, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA pourra vous aider dans cette étape, bâtiment avec appartement de 5 pièces, présentant 4 appartements ? Le bien de vos rêves est présent parmi ceux référencés sur notre site e-commerce. Notre menu pourra vous aider à affiner votre recherche. Et, si vous hésitez encore, pour le choix du type de bâtiment que vous allez acheter, le prix et l’état renseignés dans chaque fiche vous orienteront. Vous avez également la possibilité de réaliser une alerte afin de recevoir les nouvelles offres de vente d’immeuble de rapport ; mais également de prendre rendez-vous avec l’agence de votre région qui les gère afin de recevoir rapidement des conseils."
          },
          {
            "title": "Trouver votre programme immobilier en {{geo_object_name}}",
            "text": "Parmi les offres ERA vous pourrez trouver un hôtel restaurant, un local commercial, une résidence ou un bâtiment pour investir en {{geo_object_name}}. Votre projet, qu’il se situe dans une grande, moyenne ou petite ville, ou à la campagne en {{geo_object_name}}, se trouve avec certitude parmi les exclusivités d’une agence ERA. Les photos et les descriptions des habitations vous renseigneront de manière plus exacte en vous donnant des précisions. Tels que, pour chaque bâtisse, le nombre de pièces et chambres, de ses appartements, mais aussi s’ils sont pourvus d’une terrasse, d’un garage, d’un stationnement, d’un jardin."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un immeuble en {{geo_object_name}} ?",
            "content": "Le prix d'un immeuble de rapport en {{geo_object_name}} est assez variable ; suivant sa localisation, en centre-ville ou en région. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
          },
          {
            "title": "Combien y a-t-il d'immeubles disponibles en {{geo_object_name}} ?",
            "content": "Les immeubles à la vente en {{geo_object_name}} sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Le nombre d'immeubles disponibles en {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un immeuble à acheter en {{geo_object_name}} ?",
            "content": "Pour trouver un immeuble à acheter en {{geo_object_name}}, en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
          },
          {
            "title": "Quel est le délai moyen pour acheter un immeuble en {{geo_object_name}} ?",
            "content": "Le délai pour l'achat de votre immeuble en {{geo_object_name}} est variable, il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le département : {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Découvrez les annonces d'immeubles à l'achat {{geo_object_name}} dans votre agence ERA Immobilier ou sur notre site internet. Vous effectuez une recherche afin de trouver votre logement ou pour investir ? L'immeuble que vous souhaitez acquérir se trouve parmi nos exclusivités.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat immeuble {{geo_object_name}}",
            "text": "Vous recherchez un building à l’achat dans votre département ({{geo_object_name}}), notre site web met à votre disposition un moteur rendant possible la vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous épaulera pour trouver les immeubles de logements ou commerces ayant un fort potentiel."
          },
          {
            "title": "Comment choisir les catégories de votre immeuble dans votre département : {{geo_object_name}} ?",
            "text": "Vous souhaitez acquérir une bâtisse de rapport, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA vous aidera dans cette étape, bâtiment avec appartement de 5 pièces, offrant 4 logements ? Le bien de votre vie est présent parmi ceux référencés sur notre commerce en ligne. Notre menu vous aidera à perfectionner votre recherche. Et, si vous hésitez encore, pour le choix du genre d'édifice que vous allez acheter, le prix, la surface et l’état renseignés dans chaque fiche vous orienteront. Vous avez aussi la possibilité de réaliser une alerte afin de bénéficier des nouvelles offres de vente d’immeuble de rapport ; mais aussi de prendre rendez-vous avec l’agence de votre département ({{geo_object_name}}) qui les gère afin d’obtenir de véritables de conseils."
          },
          {
            "title": "Comment allez-vous trouver un immeuble dans votre département : {{geo_object_name}} ?",
            "text": "Parmi les offres ERA vous pourrez rechercher un hôtel restaurant, un local commercial, une résidence ou un ensemble à vendre dans votre département : {{geo_object_name}}. Votre projet, qu’il se situe en centre-ville de la ville principale de votre département, {{geo_object_name}} ({{code_departement}}), en région {{region}}, se trouve certainement parmi les exclusivités d’une agence ERA. Les photos et les descriptions des immeubles à vendre, vous renseigneront de manière plus explicite en vous donnant des détails. Tels que la surface de chaque bâtisse ainsi que son type, le nombre de pièces et chambres de ses appartements, mais aussi s’ils sont pourvus d’une grande terrasse, d’un garage, d’un stationnement, d’un jardin."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un immeuble dans votre département : {{geo_object_name}} ?",
            "content": "Le prix d'un immeuble de rapport dans votre département ({{geo_object_name}}) est assez variable ; suivant sa localisation, en centre-ville ou en périphérie. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
          },
          {
            "title": "Combien y a-t-il d'immeubles disponibles dans votre département : {{geo_object_name}} ?",
            "content": "Les immeubles à la vente dans votre département ({{geo_object_name}}) sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Le nombre d'immeubles disponibles en {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un immeuble à acheter dans votre département : {{geo_object_name}} ?",
            "content": "Pour trouver un immeuble à acheter dans votre département : {{geo_object_name}}, en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
          },
          {
            "title": "Quel est le délai moyen pour acheter un immeuble dans votre département : {{geo_object_name}} ?",
            "content": "Le délai pour l'achat de votre immeuble dans votre département : {{geo_object_name}}, est variable. Il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "ERA Immobilier propose des annonces d'immeubles à la vente dans votre ville, il permet également aux futurs acquéreurs d'établir une étude des programmes en cours à {{geo_object_name}} ; quelques secondes seulement sont nécessaires pour sélectionner celles qui vous paraitront pertinentes.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat immeuble à {{geo_object_name}}",
            "text": "Vous cherchez un immeuble à l’achat à {{geo_object_name}}, notre site internet met à votre disposition un moteur rendant possible la vision rapide de la campagne susceptible de vous intéresser. Le grand nombre d’appartements référencés, grâce à notre réseau de franchises, vous permettra de trouver les édifices de logements ou commerces ayant un fort potentiel dans votre ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
          },
          {
            "title": "Comment choisir la catégorie de votre immeuble de rapport à {{geo_object_name}} ?",
            "text": "Vous souhaitez acheter un immeuble de rapport à {{geo_object_name}}, vous allez devoir finaliser votre choix parmi les annonces selon vos critères. ERA pourra vous aider dans cette étape, bâtiment avec appartement de 5 pièces, présentant 4 logements ? Le bien de votre vie est présent parmi ceux référencés sur notre site. Notre menu pourra vous aider à améliorer votre recherche. Et, si vous hésitez encore, pour le choix du type d’immeuble que vous allez acheter, le prix et l’état renseignés dans chaque fiche vous orienteront. Vous avez aussi la possibilité de concevoir une alerte afin de profiter des nouvelles offres de vente d’immeuble de rapport ; mais également de prendre contact avec l’agence de votre ville qui les gère afin d'avoir vraiment de bons conseils."
          },
          {
            "title": "Comment trouver un immeuble de rapport à {{geo_object_name}} ?",
            "text": "Parmi les offres ERA vous pourrez chercher un hôtel restaurant, un local commercial, une résidence ou un bâtiment à vendre à {{geo_object_name}}. Votre projet, qu’il se situe dans la banlieue, dans la couronne ou en centre-ville de {{geo_object_name}}, se trouve certainement parmi les exclusivités d’une agence ERA. Les photos et les descriptions des habitations à vendre, vous renseigneront de manière plus explicite en vous donnant des renseignements détaillés. Tels que la surface de chaque bâtiment, le nombre de pièces et chambres de ses appartements, mais aussi s’ils sont pourvus d’une grande terrasse, d’un garage, d’un stationnement, d’un jardin."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un immeuble à {{geo_object_name}} ?",
            "content": "Le prix d'un immeuble de rapport à {{geo_object_name}} est assez variable ; suivant sa localisation, près de ses commerces ou dans la banlieue. Son prix est également fixé en fonction de sa surface, le nombre de niveaux et d'appartements."
          },
          {
            "title": "Combien y a-t-il d'immeubles disponibles à {{geo_object_name}} ?",
            "content": "Les immeubles à la vente à {{geo_object_name}} ({{code_postal}}), sont assez divers, immeubles neufs en construction, immeubles rénovés ou à rénover avec travaux. Vous avez la possibilité de prendre contact avec la mairie de {{geo_object_name}}. Le nombre d'immeubles disponibles à {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un immeuble à acheter à {{geo_object_name}} ?",
            "content": "Pour trouver un immeuble à acheter à {{geo_object_name}} en tant qu'investisseur vous pouvez consulter les petites annonces. Celles de notre site internet vous présentent des programmes en cours de réalisation, des immeubles en état à réhabiliter."
          },
          {
            "title": "Quel est le délai moyen pour acheter un immeuble à {{geo_object_name}} ?",
            "content": "Le délai pour l'achat de votre immeuble à {{geo_object_name}} est variable, il dépend de l'accord de votre financement et de celui entre la signature du compromis de vente et de l'acte de vente. ERA Immobilier est là pour vous épauler durant toutes ces étapes."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    },
    "PARKING-GARAGE": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en France",
        "EDITO": "Trouvez le parking ou le garage idéal en France grâce aux annonces d'achat des franchises d'agences immobilières ERA Immobilier. Pour investisseurs ou pour un usage personnel, les offres de parkings, garages et box sont variées.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat parking et garage France",
            "text": "Vous avez décidé d'acheter un parking ou un garage afin de résoudre vos problèmes de stationnement en centre-ville, ou pour un investissement qui vous assurera un rendement régulier ? Le moteur de recherche du site ERA va vous permettre de finaliser votre projet de parking, en vous orientant dans la catégorie : vente parking."
          },
          {
            "title": "Vous cherchez un programme immobilier neuf ?",
            "text": "Vous souhaitez acheter un box ou un garage particulier proche de votre résidence ou une place de parking pour réduire vos frais de parking en ville. Vous trouverez certainement votre place dans un garage, que ce soit en plein centre de Paris, au deuxième sous-sol d'un parking sécurisé, parmi les emplacements de stationnement mis en vente sur notre site.\nVotre recherche a pour nature d'investir dans une place de parking, un garage ou un box, pour le stationnement d'un ou plusieurs véhicules, afin de constituer un patrimoine avec un petit rendement régulier ? Les annonces ne manquent pas non plus, selon votre budget et vos exigences sur la nature du bien, place de parking, box, garage, en extérieur ou en sous-sol, ... L'une de nos agences a certainement référencé la bonne affaire qui vous assurera un revenu.\nN'hésitez pas à créer une alerte, les nouvelles annonces, en exclusivité, de parking, box ou garage, vous parviendront en temps réel. Pour cela créez votre compte sur notre site ERA France. Vous pouvez également faire appel aux services de nos agences qui vous accompagneront dans cette nouvelle expérience."
          },
          {
            "title": "Trouver un parking ou garage",
            "text": "Parmi les offres ERA vous pourrez rechercher une place de parking, un emplacement de stationnement, un box à vendre en France, pour réduire votre budget stationnement ou investir dans un bien.\nSi votre projet se situe dans votre région, à Saint-Etienne dans la Loire, ou en centre-ville de Paris, il se trouve certainement parmi les exclusivités de notre site immobilier.\nLes photos et les descriptions de chaque emplacement de voiture, vous renseigneront plus précisément en vous donnant le lieu du stationnement, le type de parking, le nombre de niveaux, la quantité de véhicules qu'il peut recevoir. Les annonces informent également sur les surfaces de chaque place de parking ou garage à vendre, s'il se situe en sous-sol, en surface, en extérieur, mais également sur le quartier où il se trouve. A l'aide de notre simulateur financier vous pourrez rapidement connaitre le rendement de chaque emplacement."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou garage en France ?",
            "content": "Les prix des parkings et garages en France varient selon leur emplacement. Ils sont d'autant plus chers s'ils se situent proches d'un centre-ville."
          },
          {
            "title": "Combien y a-t-il de parkings et garages disponibles en France ?",
            "content": "En budget, emplacement et surface très divers, nombre de parkings et garages sont proposés sur toute la France, on peut recenser {{annonces}} place de parking et garages."
          },
          {
            "title": "Comment trouver un parking ou un garage à acheter en France ?",
            "content": "Consulter les annonces de vente de parking, box ou garage des vitrines des agences immobilières, des réseaux sociaux ou des journaux, vous offre la possibilité de repérer de nombreux résultats pour votre recherche de parking garage à acheter en France. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier, plus particulièrement celles dont la référence est notée en exclusivité."
          },
          {
            "title": "Quel est le délai moyen pour acheter un parking ou un garage en France ?",
            "content": "Le délai entre l'accord verbal ou écrit de l'achat de votre parking ou garage, signature du compromis de vente, et la signature du contrat définitif de vente de votre emplacement de stationnement devant notaire est de deux à cinq mois."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en vente en {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Un parking ou garage à l'achat en {{geo_object_name}} n'est pas toujours simple à trouver, se faire aider dans sa recherche par une agence immobilière peut être une solution efficace. Le site internet ERA Immobilier référence un nombre important d'annonces de places de parking en {{geo_object_name}}.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat parking et garage en {{geo_object_name}}",
            "text": "Vous avez décidé d'obtenir à l'achat un parking ou un garage afin de résoudre votre souci de stationnement en centre-ville, ou pour un investissement qui vous garantira un rendement régulier ? Les outils de recherche ERA vous permettront de finaliser votre projet de parking, en vous orientant dans la catégorie : vente parking."
          },
          {
            "title": "Comment avez-vous défini votre projet de parking en {{geo_object_name}} ?",
            "text": "Vous souhaitez acheter un box ou un garage particulier proche de votre résidence ou une place de parking pour réduire vos frais de parking en centre-ville. Vous aurez à coup sûr une place de garage, que ce soit en plein milieu d'une grande ville de {{geo_object_name}}, au deuxième sous-sol d’un parking sécurisé, parmi les emplacements de stationnement mis en vente sur notre site e-commerce. Votre recherche a pour nature d'acquérir une place de parking, un garage ou un box, pour le stationnement d’une ou plusieurs voitures, afin de constituer un patrimoine avec un petit rendement régulier ? Les annonces ne manquent pas non plus, selon vos moyens et vos exigences sur la nature du bien, place de parking, box, garage, en extérieur ou en sous-sol, ... L’une de nos agences a à coup sûr référencé la bonne idée qui vous assurera un bon rendement. N’hésitez pas à créer une alerte, les nouvelles annonces, en exclusivité, de parking, box ou garage, vous parviendront en temps réel. Dans cet objectif créez votre compte sur notre site e-commerce ERA France. Vous pouvez également faire appel aux services de nos agences qui vous accompagneront vers cette nouvelle expérience."
          },
          {
            "title": "Comment trouver un parking ou garage en {{geo_object_name}} ?",
            "text": "Parmi les formules ERA vous pourrez repérer une place de parking, un emplacement de stationnement, un box à vendre en {{geo_object_name}}, pour réduire vos frais de stationnement ou investir dans un bien. Si votre projet se trouve en centre-ville d'une grande ville de la région, il se trouve à coup sûr parmi les exclusivités de notre site e-commerce immobilier. Les photos et les descriptions de chaque emplacement de voitures, vous renseigneront pour être plus précis en vous donnant le lieu du stationnement, le type de parking, le nombre de voitures qu’il peut recevoir. Les annonces informent également sur la configuration de chaque place de parking ou garage à vendre, s’il se trouve en sous-sol, en surface, en extérieur, mais également sur le quartier où il est installé. A l’aide de notre simulateur financier vous pourrez rapidement connaitre le rendement de chaque emplacement."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou garage en {{geo_object_name}} ?",
            "content": "Les prix des parkings et garages en {{geo_object_name}} varient selon leur emplacement. Ils sont d'autant plus cher s'ils se situent proches d'un centre-ville."
          },
          {
            "title": "Combien y a-t-il de parkings et garages disponibles en {{geo_object_name}} ?",
            "content": "En budget, emplacement et surface très divers, nombre de parkings et garages sont proposés sur toute la région {{geo_object_name}}, on peut recenser {{annonces}} place de parking et garages."
          },
          {
            "title": "Comment trouver un parking ou un garage à acheter en {{geo_object_name}} ?",
            "content": "Consulter les annonces de vente de parking, box ou garage des vitrines des agences immobilières, des réseaux sociaux ou des journaux, vous offre la possibilité de repérer de nombreux résultats pour votre recherche de parking garage à acheter en France. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier, plus particulièrement celles dont la référence est notée en exclusivité."
          },
          {
            "title": "Quel est le délai moyen pour acheter un parking ou un garage en {{geo_object_name}} ?",
            "content": "Le délai entre l'accord verbal ou écrit de l'achat de votre parking ou garage, signature du compromis de vente, et la signature du contrat définitif de vente de votre emplacement de stationnement devant notaire est de deux à cinq mois."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en vente dans le {{geo_object_name}}",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Pour acheter un parking ou un garage dans le département {{geo_object_name}}, il est conseillé de faire appel à une agence immobilière spécialisée, elle vous guidera dans toutes les étapes de votre achat, dans les meilleurs délais et au meilleur prix. ERA Immobilier réunit toutes les conditions pour vous aider lors de cet achat.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat parking et garage {{geo_object_name}}",
            "text": "Vous avez décidé d'acquérir un parking ou un garage afin de trouver une solution pour votre stationnement en centre-ville, ou pour un investissement qui vous garantit un rendement régulier ? Le moteur de recherche du site ERA va vous permettre de finaliser votre projet de parking, en vous orientant dans la catégorie : vente parking."
          },
          {
            "title": "Vous voulez acheter un parking ou un garage dans votre département : {{geo_object_name}} ?",
            "text": "Vous souhaitez acheter un box ou un garage particulier proche de votre résidence ou une place de parking pour réduire vos frais de parking dans la capitale de votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}. Vous trouverez certainement votre place dans un garage, que cela soit en plein milieu d'une ville, au deuxième sous-sol d’un parking sécurisé, parmi les emplacements de stationnement mis en vente sur notre site e-commerce.\nVotre recherche a pour nature d’investir dans une place de parking, un garage ou un box, pour le stationnement d’une ou plusieurs voitures, afin de créer un patrimoine avec un petit rendement régulier ? Les annonces ne manquent pas non plus, selon votre capital disponible et vos attentes sur la nature du bien, place de parking, box, garage, en extérieur ou en sous-sol. L’un de nos cabinets a certainement référencé la bonne idée qui vous assurera un bon rendement.\nN’hésitez pas à concevoir une alerte, les toutes nouvelles annonces, en exclusivité, de parking, box ou garage, vous parviendront dès leur parution. Dans cet objectif créez votre compte sur notre site e-commerce ERA France. Vous pouvez également faire appel aux services de nos cabinets qui vous accompagneront vers cette nouvelle expérience."
          },
          {
            "title": "Vous allez trouver votre parking ou garage dans votre département : {{geo_object_name}}",
            "text": "Parmi les offres ERA vous pourrez rechercher une place de parking, un emplacement de stationnement, un box à vendre dans votre département : {{geo_object_name}}, pour réduire vos dépenses de stationnement ou investir dans un bien. Si votre projet se trouve dans le secteur de la préfecture, sous-préfecture ou d'une plus petite ville, il se trouve certainement parmi les exclusivités de notre site e-commerce immobilier. Les photos et les descriptions de chaque emplacement de voiture, vous renseigneront plus précisément en vous donnant le lieu du stationnement, les catégories de parking, le nombre de niveaux, la quantité de voitures qu’il peut recevoir. Les annonces informent également sur les surfaces de chaque place de parking ou garage à vendre, s’il se trouve en sous-sol, en surface, en extérieur, mais également sur le quartier où il se situe. A l’aide de notre simulateur financier vous pourrez rapidement connaitre le rendement de chaque emplacement."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou garage dans votre département : {{geo_object_name}} ?",
            "content": "Les prix des parkings et garages dans votre département ({{geo_object_name}}) varient selon leur emplacement. Ils sont d'autant plus cher s'ils se situent proches d'un centre-ville."
          },
          {
            "title": "Combien y a-t-il de parkings et garages disponibles dans votre département : {{geo_object_name}} ?",
            "content": "En budget, emplacement et surface très divers, nombre de parkings et garages sont proposés sur tout le département, plus particulièrement en centre {{geo_object_name}}, on peut recenser {{annonces}} place de parking et garages."
          },
          {
            "title": "Comment trouver un parking ou un garage à acheter dans votre département : {{geo_object_name}} ?",
            "content": "Consulter les annonces de vente de parking, box ou garage des vitrines des agences immobilières, des réseaux sociaux ou des journaux, vous offre la possibilité de repérer de nombreux résultats pour votre recherche de parking garage à acheter dans votre département : {{geo_object_name}}. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier, plus particulièrement celles dont la référence est notée en exclusivité."
          },
          {
            "title": "Quel est le délai moyen pour acheter un parking ou un garage dans votre département : {{geo_object_name}} ?",
            "content": "Le délai entre l'accord verbal ou écrit de l'achat de votre parking ou garage, signature du compromis de vente, et la signature du contrat définitif de vente de votre emplacement de stationnement devant notaire est de deux à cinq mois."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "La recherche d'un parking ou garage en vente dans votre ville, peut être appréhendée comme un parcours du combattant ; elle est souvent simplifiée avec l'aide d'une agence immobilière. C'est pourquoi, ERA Immobilier met son expertise à votre service à {{geo_object_name}}.",
        "BOTTOM_CONTENT": [
          {
            "title": "Achat parking et garage à {{geo_object_name}}",
            "text": "Vous avez décidé d’acheter un parking ou un garage afin de résoudre vos problèmes de stationnement en centre-ville de {{geo_object_name}}, ou pour un investissement qui vous assure un rendement fréquent ? Le moteur de recherche du programme ERA vous permettra de finaliser votre projet de parking, en vous orientant dans la catégorie : vente parking à {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
          },
          {
            "title": "Vous voulez définir les critères de votre projet d’achat à {{geo_object_name}} ?",
            "text": "Vous souhaitez acheter un box ou un garage particulier proche de votre résidence ou une place de parking pour réduire vos frais de stationnement à {{geo_object_name}}. Vous aurez sûrement votre place dans un garage à {{geo_object_name}} ({{code_postal}}), de superficie de {{superficie}} au nombre de {{nbr_habitant}} habitants appelés {{gentilet}}. Ou au second sous-sol d’un parking protégé, parmi les emplacements de stationnement mis en vente sur notre site.\nVotre recherche a pour nature d'acquérir dans une place de parking, un garage ou un box, pour le stationnement d’une ou plusieurs voitures, afin de créer un patrimoine avec un petit rendement fréquent ? Les annonces ne manquent pas non plus, selon vos moyens et vos attentes sur la nature du bien, place de parking, box, garage, en extérieur ou en sous-sol. L’un de nos groupements a sûrement référencé la bonne affaire qui vous assurera un bon rapport.\nN’hésitez pas à créer une alerte, les nouvelles annonces en exclusivité, de parking, box ou garage, vous parviendront en temps réel. Pour cela créez votre compte sur ERA France. Vous pouvez également faire appel aux services de nos affiliés qui vous accompagneront vers cette nouvelle expérience."
          },
          {
            "title": "Vous allez dénicher votre parking ou garage à {{geo_object_name}}",
            "text": "Parmi les annonces ERA vous pourrez chercher une place de parking, un emplacement de stationnement, un box à {{geo_object_name}}, pour réduire votre coût de stationnement ou investir dans un bien. Si votre projet est placé dans la banlieue, en périphérie, ou en centre-ville de {{geo_object_name}}, il se trouve sûrement parmi les exclusivités de ERA Immobilier. Les photos et les descriptions de chaque emplacement de voitures, vous renseigneront de manière plus précise en vous donnant le lieu du stationnement, la catégorie de parking, le nombre de niveaux, le nombre de voitures qu’il peut recevoir. Les annonces informent également sur la surface de chaque place de parking ou garage, s’il est placé en sous-sol, en surface, en extérieur, mais également sur le quartier où il est installé. A l’aide de notre simulateur financier vous pourrez rapidement connaitre le rendement de chaque emplacement."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou garage à {{geo_object_name}} ?",
            "content": "Les prix des parkings et garages à {{geo_object_name}} varient selon leur emplacement. Ils sont d'autant plus cher s'ils se situent proches du centre-ville."
          },
          {
            "title": "Combien y a-t-il de parkings et garages disponibles à {{geo_object_name}} ?",
            "content": "En budget, emplacement et surface très divers, nombre de parkings et garages sont proposés sur tout {{geo_object_name}}, on peut recenser {{annonces}} places de parking et garages. Pour mieux connaitre le nombre d'emplacements vous pouvez contacter la mairie de {{geo_object_name}}, dont le maire est {{maire}}."
          },
          {
            "title": "Comment trouver un parking ou un garage à acheter à {{geo_object_name}} ?",
            "content": "Consulter les annonces de vente de parking, box ou garage des vitrines des agences immobilières, des réseaux sociaux ou des journaux, vous offre la possibilité de repérer de nombreux résultats pour votre recherche de parking garage à acheter à {{geo_object_name}}. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier, plus particulièrement celles dont la référence est notée en exclusivité."
          },
          {
            "title": "Quel est le délai moyen pour acheter un parking ou un garage à {{geo_object_name}} ?",
            "content": "Le délai entre l'accord verbal ou écrit de l'achat de votre parking ou garage, signature du compromis de vente, et la signature du contrat définitif de vente de votre emplacement de stationnement devant notaire est de deux à cinq mois."
          }
        ],
        "ARRONDISSEMENT": {
          "TITLE": "{{annonces}} {{typo}} en vente à {{geo_object_name}} ({{code_postal}})",
          "SUBTITLE": "Achetez votre {{subTitleTypo}} à {{geo_object_name}}"
        }
      },
      "ARRONDISSEMENT": {
        "TITLE": "Achat immobilier à {{geo_object_name}} ({{code_postal}})",
        "SUBTITLE": "Achetez votre {{subTitleTypo}} immobilier à {{geo_object_name}}"
      }
    }
  },
  "LOCATION": {
    "COUNTRY": {
      "TITLE": "Location immobilière",
      "SUBTITLE": "Louez votre logement en France chez ERA Immobilier",
      "EDITO": "Pour louer un bien immobilier en France il existe une étape incontournable, faire appel à une agence immobilière professionnelle qui vous permettra de vendre vite et au bon prix. ERA Immobilier réunit les meilleures conditions pour vous permettre d'atteindre votre objectif.",
      "BOTTOM_CONTENT": [
        {
          "title": "Location immobilière France",
          "text": "Grâce à ses annonces, ERA Immobilier vous aide à trouver une location immobilière dans toute la France. En quelques étapes, faites le choix de vos critères pour votre futur appartement ou maison en location en France sur notre site internet."
        },
        {
          "title": "Vous aimeriez trouver une location en France ?",
          "text": "Pour votre recherche de logement en France, ERA met à votre disposition des outils de sélection, que ce soit pour trouver l’agence de notre réseau la plus proche de chez vous ou rechercher votre appartement à partir de vos critères. Ses 450 agences et ses 2100 conseillers, rien qu’en France, vous accompagnent quelle que soit votre zone de prédilection. Que votre recherche se situe autour d'une grande ville ou à la campagne, que vous désiriez un studio, un appartement 2 pièces en étage ou non, ou une maison, vous trouverez à coup sûr le logement défini dans votre projet."
        },
        {
          "title": "Vous allez faire le choix de votre logement en location",
          "text": "Vous êtes contraint de déménager à l’autre bout de la France et devez chercher un appartement en location. Vous ne voulez ou ne pouvez pas devenir acquéreur de votre logement immédiatement, notre réseau de franchises, avec ses nombreuses annonces référencées sur toute la France, va vous donner tous les conseils pour vous aider à trouver votre bien, selon votre situation et éventuellement votre famille. Appartement 1 pièce, 2 pièces, en étage ou non, maison avec plusieurs chambres, nos outils de rechercher vont contribuer à trouver le type de bien qui vous correspond. Après avoir déterminé les exigences de votre foyer : le nombre de pièces, de chambres, la surface, le confort recherché pour votre appartement ; n'hésitez pas à créer une alerte sur le site ERA France afin d'être informé des nouveaux produits mis à la location. ERA Immobilier va vous aider à devenir locataire en ciblant vos critères, et va vous permettre de gagner du temps en accédant rapidement aux annonces qui vous correspondent.Grâce à notre moteur de recherche, ou à un conseiller ERA, vous pourrez sélectionner un appartement qui correspond à votre demande, loyer, nombre de pièces, tout en réduisant vos frais."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'une location immobilière en France ?",
          "content": "Le prix d'une location immobilière en France est très variable. Il est fonction de sa situation géographique, il sera plus élevé en centre-ville. Le loyer tient compte du confort, de la surface, du nombre de pièces, de chambres, ... "
        },
        {
          "title": "Combien y a-t-il de location immobilière en France ?",
          "content": "On dénombre en France environ 37 millions de logements de tous types. Les résidences principales représentant environ 30 millions de l'immobilier, les propriétaires de maisons ou appartement sont environ 58%. Le nombre de biens en location est de {{annonces}}."
        },
        {
          "title": "Comment trouver un bien à louer dans en France ?",
          "content": "Pour louer un appartement ou une maison en location en France il existe de nombreux outils principalement en ligne. On peut consulter les offres de particuliers ou de professionnels sur les réseaux sociaux ou tout simplement trouver une annonce dans la vitrine de son agence de quartier."
        },
        {
          "title": "Quels sont les documents nécessaires pour une location en France ?",
          "content": "Les documents à fournir pour louer un bien, que ce soit un appartement 2 pièces ou une maison, sont : un justificatif d'identité, carte d'identité, passeport ou permis de conduire ; un justificatif de domicile, en principe les 3 dernières quittances de loyer ; les documents justifiant de son activité professionnelle, contrat de travail, Kbis, carte d'étudiant, ... ; les documents justifiant de ses ressources, le dernier avis d'imposition ainsi que ses 3 derniers bulletins de salaires ou équivalent, un dépôt de garantie."
        }
      ]
    },
    "REGION": {
      "TITLE": "Location immobilière {{geo_object_name}}",
      "SUBTITLE": "Louez votre logement en {{geo_object_name}} chez ERA Immobilier",
      "EDITO": "ERA Immobilier peut vous accompagner dans vos démarches pour une location immobilière en {{geo_object_name}}. Son site internet, tout comme ses agences immobilières présentes sur tout le territoire, vont vous permettre de choisir votre location.",
      "BOTTOM_CONTENT": [
        {
          "title": "Location immobilière en {{geo_object_name}}",
          "text": "Grâce à ses annonces, ERA Immobilier vous épaule pour trouver une location immobilière dans toute la région {{geo_object_name}}. En seulement quelques étapes, trouvez une maison en location en {{geo_object_name}} sur notre site internet."
        },
        {
          "title": "Vous souhaitez trouver une location en {{geo_object_name}} ?",
          "text": "Pour votre recherche de logement en {{geo_object_name}}, ERA met à votre disposition des outils de sélection, que ce soit pour trouver l’agence de notre réseau la plus proche de chez vous ou rechercher votre appartement à partir de vos critères. Ses multiples agences et ses nombreux conseillers, rien qu’en {{geo_object_name}} vous aideront, peu importe votre zone de désignation. Que votre recherche se trouve en périphérie de la plus grande ville de la région {{geo_object_name}} ou en son centre, que vous désiriez un studio, 2 pièces en étage ou non, ou une maison, vous aurez automatiquement le logement défini dans votre projet."
        },
        {
          "title": "Quel logement cherchez-vous en location en {{geo_object_name}} ?",
          "text": "Vous êtes contraint de déménager à l’autre bout de la région {{geo_object_name}} et devez chercher une location. Vous n'avez pas le temps, ou ne pouvez pas, devenir facilement acquéreur de votre maison, notre réseau de franchises, avec ses nombreuses annonces référencées sur toute la région {{geo_object_name}}, va vous donner tous les conseils pour vous épauler à trouver votre bien, selon votre situation et éventuellement votre famille. Appartement 1 pièce, 2 pièces, en étage ou non, maison avec plusieurs chambres, nos outils de rechercher vont contribuer à trouver le type de bien qui vous correspond. Après avoir déterminé les demandes de votre foyer : le nombre de pièces, de chambres, la surface, le confort recherché à la maison ; n’hésitez pas à concevoir une alerte sur le site ERA France, afin d’être informé des nouveaux produits mis à la location. ERA Immobilier va vous épauler pour devenir locataire en ciblant vos critères, et vous aidera à économiser du temps en accédant rapidement aux annonces qui vous correspondent. Grâce à notre outil de recherche, ou à un conseiller ERA, vous pourrez sélectionner ce qui se rapporte à votre demande, loyer, nombre de pièces, tout en réduisant vos frais."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'une location immobilière en {{geo_object_name}} ?",
          "content": "Le prix d'une location immobilière en {{geo_object_name}} est très variable. Il est fonction de sa situation géographique, il sera plus élevé en centre-ville. Le loyer tient compte du confort, de la surface, du nombre de pièces, de chambres, ... "
        },
        {
          "title": "Combien y a-t-il de location immobilière en {{geo_object_name}} ?",
          "content": "On dénombre en région {{geo_object_name}} des logements de tous types, le nombre de biens en location est de {{annonces}}. Les résidences principales en France représentent environ 30 millions de l'immobilier, les propriétaires de maisons ou appartement sont environ 58%."
        },
        {
          "title": "Comment trouver un bien à louer en {{geo_object_name}} ?",
          "content": "Pour obtenir un appartement ou une maison en location en {{geo_object_name}} il existe de nombreux outils principalement en ligne. On peut consulter les offres de particuliers ou de professionnels sur les réseaux sociaux ou tout simplement trouver une annonce dans la vitrine de son agence de quartier."
        },
        {
          "title": "Quels sont les documents nécessaires pour une location en {{geo_object_name}} ?",
          "content": "Les documents à fournir pour louer un bien, que ce soit un appartement 2 pièces ou une maison, sont : une justificatif d'identité, carte d'identité, passeport ou permis de conduire ; un justificatif de domicile, en principe les 3 dernières quittances de loyer ; les documents justifiant de son activité professionnelle, contrat de travail, Kbis, carte d'étudiant, ... ; les documents justifiant de ses ressources, le dernier avis d'imposition ainsi que ses 3 derniers bulletins de salaires ou équivalent, un dépôt de garantie."
        }
      ]
    },
    "DEPARTEMENT": {
      "TITLE": "Location immobilière {{geo_object_name}}",
      "SUBTITLE": "Louez votre logement dans le {{geo_object_name}} chez ERA Immobilier",
      "EDITO": "La recherche d'une location immobilière dans votre département, {{geo_object_name}}, peut être perçue comme un parcours difficile ; elle est toujours simplifiée avec l'aide d'une agence immobilière. Pour cela ERA Immobilier met son expertise à votre service.",
      "BOTTOM_CONTENT": [
        {
          "title": "Location immobilière {{geo_object_name}}",
          "text": "Grâce à ses annonces, ERA Immobilier vous aide à trouver une location immobilière dans votre département {{geo_object_name}} ({{code_departement}}), en région {{region}}. En seulement quelques étapes, trouvez une maison en location dans le département de votre choix sur notre site internet."
        },
        {
          "title": "Vous souhaitez obtenir rapidement une location dans votre département : {{geo_object_name}} ?",
          "text": "Pour votre recherche de logement dans votre département ({{geo_object_name}}), ERA met à votre disposition une gestion rapide d'après votre sélection, que ce soit pour trouver l’agence de notre réseau la plus proche de chez vous ou chercher votre appartement à partir de vos critères. Ses 450 groupements et ses 2100 conseillers, rien que sur le territoire français, vous aideront, peu importe votre zone de prédilection. Que votre recherche soit placée autour de la ville préfecture ou en centre de votre département, {{geo_object_name}}, que vous souhaitiez un studio, 2 pièces en étage ou non, ou une maison, vous obtiendrez automatiquement le logement défini dans votre projet."
        },
        {
          "title": "Besoin d'un peu d'aide pour trouver un logement en location dans votre département : {{geo_object_name}} ?",
          "text": "Vous êtes contraint de déménager à l’autre bout de la région et devez dénicher une location. Vous ne désirez ou ne pouvez pas devenir acheteur de votre maison directement, notre réseau de franchises, avec ses nombreuses annonces référencées sur tout votre département ({{geo_object_name}}), va vous donner les conseils pour vous aider à trouver votre bien, selon votre situation et éventuellement votre famille. Appartement 1 pièce, 2 pièces, en étage ou non, maison avec plusieurs chambres, nos outils de chercher vont contribuer à trouver la catégorie du bien qui correspond à vos attentes. Après avoir établi les choix de votre foyer : le nombre de pièces, de chambres, la surface, le confort recherché pour la maison ; pensez à créer une alerte sur le site ERA France, afin d’être informé des nouveaux produits mis à la location. ERA Immobilier va vous aider à devenir locataire en ciblant vos critères, et va vous permettre d'économiser du temps en accédant rapidement aux annonces qui vous correspondent. Grâce à notre outil de recherche, ou à un conseiller ERA, vous pourrez sélectionner les fiches produits correspondant à votre demande, loyer, nombre de pièces, tout en limitant vos frais."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'une location immobilière dans votre département : {{geo_object_name}} ?",
          "content": "Le prix d'une location immobilière du département : {{geo_object_name}} est très variable. Il est fonction de sa situation géographique, il sera plus élevé en centre-ville. Le loyer tient compte du confort, de la surface, du nombre de pièces, de chambres, ..."
        },
        {
          "title": "Combien y a-t-il de locations immobilières en {{geo_object_name}} ?",
          "content": "On dénombre dans votre département ({{geo_object_name}}) de nombreux types de logements. Les résidences principales représentant une grande part de l'immobilier, mais certains propriétaires de maisons ou appartement mettent leur bien en location. Le nombre de biens en location est de {{annonces}}."
        },
        {
          "title": "Comment trouver un bien à louer dans le département {{geo_object_name}} ?",
          "content": "Pour louer un appartement ou une maison en location dans le département suivant : {{geo_object_name}}, il existe de nombreux outils principalement en ligne. On peut consulter les offres de particuliers ou de professionnels sur les réseaux sociaux ou tout simplement trouver une annonce dans la vitrine de son agence ERA de quartier."
        },
        {
          "title": "Quels sont les documents nécessaires pour une location dans un département : {{geo_object_name}} ?",
          "content": "Les documents à fournir pour louer un bien, que ce soit un appartement 2 pièces ou une maison, sont : un justificatif d'identité, carte d'identité, passeport ou permis de conduire ; un justificatif de domicile, en principe les 3 dernières quittances de loyer ; les documents justifiant de son activité professionnelle, contrat de travail, Kbis, carte d'étudiant, ... ; les documents justifiant de ses ressources, le dernier avis d'imposition ainsi que ses 3 derniers bulletins de salaires ou équivalent, un dépôt de garantie."
        }
      ]
    },
    "VILLE": {
      "TITLE": "Location immobilière {{geo_object_name}} ({{code_postal}})",
      "SUBTITLE": "Louez votre logement à {{geo_object_name}} chez ERA Immobilier",
      "EDITO": "Pour trouver une location immobilière à {{geo_object_name}}, il est souhaitable de faire appel à une agence immobilière spécialisée, elle vous guidera dans toutes les étapes, de la visite du bien à la signature du bail. ERA Immobilier réunit toutes les conditions pour vous guider lors de cet recherche.",
      "BOTTOM_CONTENT": [
        {
          "title": "Location immobilière à {{geo_object_name}}",
          "text": "Grâce à ses annonces, ERA Immobilier vous aide à trouver une location immobilière dans votre ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}. En quelques étapes, trouvez une maison en location à {{geo_object_name}} sur notre site web."
        },
        {
          "title": "Vous êtes à la recherche d'une nouvelle location à {{geo_object_name}} ?",
          "text": "Pour votre recherche de logement à {{geo_object_name}}, ERA met à votre disposition des outils de sélection, que ce soit pour trouver l’agence de notre réseau la plus proche de chez vous ou trouver votre appartement à partir de vos critères. Ses 450 magasins et ses 2100 conseillers, rien que sur le territoire français vous aideront, peu importe votre ville de prédilection.\nQue votre recherche se trouve en périphérie ou en milieu de {{geo_object_name}}, que vous vouliez un studio, 2 pièces en étage ou non, ou une maison, notre sélection vous proposera systématiquement le logement défini dans votre projet."
        },
        {
          "title": "Repérez la location de vos rêves à {{geo_object_name}}",
          "text": "Vous êtes obligé de déménager dans la ville de {{geo_object_name}} et devez dénicher une location. Vous ne voulez ou ne pouvez pas devenir acquéreur de votre logement pour l'instant, notre réseau de franchises, avec ses multiples annonces référencées sur l'hexagone, va vous donner tous les conseils pour vous assister et découvrir votre bien, selon votre situation et les besoins votre famille. Appartement 1 pièce, 2 pièces, en étage ou non, maison avec plusieurs chambres, nos outils vont contribuer à trouver le type de bien qui vous correspond. Après avoir déterminé les attentes de votre foyer : le nombre de pièces, de chambres, le confort recherché pour votre famille ; pensez à créer une alerte sur le site ERA France afin d’être prévenu des nouveaux produits mis à la location. ERA Immobilier va vous épauler pour devenir locataire en ciblant vos critères, et vous permettra de gagner du temps en accédant rapidement aux annonces qui vous conviennent. Grâce à notre moteur de recherche, ou à un consultant ERA, vous pourrez sélectionner ce qui correspond à votre demande, loyer, nombre de pièces, tout en limitant vos frais."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est le prix d'une location immobilière à {{geo_object_name}} ?",
          "content": "Le prix d'une location immobilière à {{geo_object_name}} est très variable. Il est fonction de sa situation géographique, il sera plus élevé près de la mairie. Le loyer tient compte du confort, de la surface, du nombre de pièces, de chambres, ..."
        },
        {
          "title": "Combien y a-t-il de location immobilière à {{geo_object_name}} ?",
          "content": "On dénombre un nombre d’habitants de {{nbr_habitant}} dans la ville de {{geo_object_name}} ({{code_postal}}). A {{geo_object_name}} on dénombre des logements de tous types, des résidences principales ou de vacances, celles-ci sont louées par les propriétaires de maisons ou appartement. Le nombre de biens en location est de {{annonces}}."
        },
        {
          "title": "Comment trouver un bien à louer dans à {{geo_object_name}} ?",
          "content": "Pour louer un appartement ou une maison en location à {{geo_object_name}} il existe de nombreux outils principalement en ligne. On peut consulter les offres de particuliers ou de professionnels sur les réseaux sociaux ou tout simplement trouver une annonce dans la vitrine de son agence ERA France."
        },
        {
          "title": "Quels sont les documents nécessaires pour une location à {{geo_object_name}} ?",
          "content": "Les documents à fournir pour louer un bien à {{geo_object_name}}, que ce soit un appartement 2 pièces ou une maison, sont : un justificatif d'identité, carte d'identité, passeport ou permis de conduire ; un justificatif de domicile, en principe les 3 dernières quittances de loyer ; les documents justifiant de son activité professionnelle, contrat de travail, Kbis, carte d'étudiant, ... ; les documents justifiant de ses ressources, le dernier avis d'imposition ainsi que ses 3 derniers bulletins de salaires ou équivalent, un dépôt de garantie."
        }
      ]
    },
    "ARRONDISSEMENT": {
      "TITLE": "Location immobilière {{geo_object_name}} ({{code_postal}})",
      "SUBTITLE": "Louez votre logement à {{geo_object_name}} chez ERA Immobilier"
    },
    "APPARTEMENT": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Louez votre {{subTitleTypo}} en France",
        "EDITO": "La recherche d'un appartement à la location en France peut être pressentie comme un parcours du combattant ; elle est largement simplifiée avec l'aide d'une agence immobilière. Pour cela ERA Immobilier met son expertise à votre service.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location appartement France",
            "text": "Vous êtes à la recherche d'un appartement à la location en France, venez utiliser le moteur de recherche que ERA France met à votre disposition sur son site internet. Le grand nombre d'appartements référencés, grâce à son réseau de franchises, vous permettra de trouver votre logement en suivant quelques étapes."
          },
          {
            "title": "Définissez votre projet de location",
            "text": "Pour louer son appartement en France il faut suivre quelques étapes incontournables que le site ERA vous permet de bien cibler. Nous passons pratiquement tous à un moment de notre vie par la période location immobilière. Afin d'affiner la recherche d'un futur logement il fait tout d'abord définir son projet locatif. Les appartements en location sont en nombre sur le site ERA France, mais les résultats seront plus ou moins importants selon les critères renseignés. Les annonces de plusieurs biens pourront alors vous être proposées. Bien d'autres données vous permettront de faire votre choix définitif : immeuble avec ou sans ascenseur, logement au 1er ou dernier étage, date de disponibilité, cuisine intégrée, terrasse ou balcon, ..."
          },
          {
            "title": "Louer votre appartement en France",
            "text": "Le nombre d'appartements que ERA propose à la location va vous permettre de trouver le logement de vos rêves en France. Les outils de recherche de nos services, les conseils que vous trouverez en agence sont un plus pour faire le tri dans les annonces référencées.\nMais, afin d'optimiser votre recherche, penser à constituer rapidement votre dossier locataire.\nN'hésitez pas à créer une alerte afin de recevoir les nouvelles propositions de locations en temps réel."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location d'appartement en France ?",
            "content": "Les prix des locations d'appartements en France sont très variables suivant la localisation, que l'on loue dans une ville de taille moyenne ou à Paris, le prix peut passer du simple au double. Il dépend également du type d'appartement, de sa surface, du nombre de pièces, mais aussi des commodités."
          },
          {
            "title": "Combien y a-t-il de location d'appartement en France ?",
            "content": "Il existe environ 37 millions de logements de tous types en France. Les résidences principales représentant 82 % des logements, 55 % sont des logements individuels. Le nombre d'appartements en location est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à louer en France ?",
            "content": "Pour trouver un appartement à louer en France il est possible de consulter les petites annonces des journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, le plus simple qui vous fera gagner du temps est de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location d'appartement en France ?",
            "content": "Les documents qui vous seront nécessaires pour créer votre dossier de locataire, sont :\nun justificatif d'identité avec photographie : photocopie de carte d'identité ou de passeport par exemple \nun justificatif de domicile : 3 dernières quittances de loyer, dernier avis de taxe foncière, ...\nun ou plusieurs documents justifiant de votre situation professionnelle : contrat de travail, copie de la carte professionnelle pour les professions libérales, copie du certificat d'identification de l'Insee pour les travailleurs indépendants, ...\nun ou plusieurs documents justifiant de ses ressources : 3 derniers bulletins de salaires, les justificatifs de versement des indemnités de stage, ...\nun dépôt de garantie."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en location en {{geo_object_name}}",
        "SUBTITLE": "Louez votre{{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Toutes les annonces et les offres de locations d'appartements en {{geo_object_name}} sur le site ERA Immobilier sont consultables en quelques clics ; les informations nécessaires pour vous orienter dans votre choix sont également consultables dans nos agences.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location appartement en {{geo_object_name}}",
            "text": "Vous recherchez un appartement à la location en {{geo_object_name}}, venez utiliser les outils de recherche que ERA France met à votre disposition sur son site internet. Le grand nombre de logements référencés, grâce à son réseau de franchises, vous permettra de trouver votre logement en fonction de quelques phases."
          },
          {
            "title": "Commencez par définir votre projet de location en {{geo_object_name}}",
            "text": "Pour trouver son appartement en location en {{geo_object_name}}, il faut suivre quelques phases inévitables que le site ERA vous permet de bien cibler. Nous passons quasiment tous à un moment de notre vie par la période location immobilière. Afin d’affiner la recherche d’un futur logement il fait tout d’abord choisir son projet appartement. Les appartements en location sont en nombre sur le site ERA France, mais les résultats seront assez importants selon les critères renseignés. Les annonces de plusieurs biens pourront alors vous être proposées. Bien d’autres données vous permettront de finaliser votre choix définitif : édifice avec ou sans ascenseur, logement au premier ou dernier étage, date de planning, cuisine intégrée, terrasse ou balcon."
          },
          {
            "title": "Ciblez votre appartement en location en {{geo_object_name}}",
            "text": "Le nombre de logements que ERA propose à la location va vous permettre de trouver le logement de vos rêves en {{geo_object_name}}. Les outils de recherche de nos offres, les conseils que vous aurez en agence sont un plus pour faire le tri dans les annonces référencées. Mais pour améliorer votre recherche et constituer rapidement votre dossier de locataire, n’hésitez pas à créer une alerte afin de recevoir les nouvelles offres de locations en temps réel."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location d'appartement en {{geo_object_name}} ?",
            "content": "Les prix des locations d'appartements en {{geo_object_name}} sont très variables suivant la localisation, que l'on loue dans une ville de taille moyenne ou une grande ville, le prix peut passer du simple au double. Il dépend également du type d'appartement, de sa surface, du nombre de pièces, mais aussi des commodités."
          },
          {
            "title": "Combien y a-t-il de locations d'appartements en {{geo_object_name}} ?",
            "content": "Il existe un grand nombre de logements de tous types en {{geo_object_name}}. Pour toute la France les résidences principales représentant 82 % des logements, 55 % sont des logements individuels. Le nombre d'appartements en location en {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à louer en {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à louer en {{geo_object_name}} il est possible de consulter les petites annonces des journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, le plus simple qui vous fera gagner du temps est de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location d'appartement en {{geo_object_name}} ?",
            "content": "Les documents qui vous seront nécessaires pour créer votre dossier de locataire, sont :\nun justificatif d'identité avec photographie : photocopie de carte d'identité ou de passeport par exemple\nun justificatif de domicile : 3 dernières quittances de loyer, dernier avis de taxe foncière, ...\nun ou plusieurs documents justifiant de votre situation professionnelle : contrat de travail, copie de la carte professionnelle pour les professions libérales, copie du certificat d'identification de l'Insee pour les travailleurs indépendants, ...\nun ou plusieurs documents justifiant de ses ressources : 3 derniers bulletins de salaires, les justificatifs de versement des indemnités de stage, ...\nun dépôt de garantie."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location dans le {{geo_object_name}}",
        "SUBTITLE": "Louez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "ERA Immobilier propose des annonces de locations d'appartements sur toute la France, il permet également aux futurs locataires d'établir une étude des locations dans leur département ({{geo_object_name}}) ; quelques clics suffisent pour sélectionner celles qui paraissent pertinentes.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location appartement {{geo_object_name}}",
            "text": "Vous recherchez un appartement à la location dans le département : {{geo_object_name}}, venez utiliser le moteur de recherche que ERA France met à votre disposition sur son site web. Le grand nombre d'habitations référencées, grâce à son réseau de franchises, vous permettra de trouver votre logement en fonction de quelques critères."
          },
          {
            "title": "Avez-vous fait défini votre projet de location dans votre département ({{geo_object_name}}) ?",
            "text": "Pour trouver une location d'appartement dans votre département ({{geo_object_name}}) il faut suivre quelques phases incontournables que le site ERA vous permet de bien cibler. Nous passons quasiment tous à un moment de notre vie par la période location immobilière. Afin d’affiner la recherche d’un futur logement il fait tout d’abord déterminer son projet locatif. Les logements en location sont en nombre sur le site ERA France, mais les résultats seront plus ou moins importants selon les critères renseignés. Les annonces de plusieurs biens pourront alors vous être proposées. Bien d’autres données vous permettront de faire votre choix définitif : édifice avec ou sans ascenseur, logement au premier ou dernier étage, date de disponibilité, cuisine intégrée, grande terrasse ou balcon."
          },
          {
            "title": "Vous recherchez votre logement locatif dans votre département : {{geo_object_name}} ?",
            "text": "Le nombre d'habitations que ERA propose à la location vous permettra de trouver le logement de vos rêves dans votre département, votre {{geo_object_name}} ({{code_departement}}), en région {{region}}. Les outils de sélection de nos prestations, les conseils que vous obtiendrez en agence sont un plus pour faire le tri dans les annonces référencées. Mais afin d'améliorer votre recherche essayez de constituer rapidement votre dossier de futur locataire. N’hésitez pas à développer une alerte afin de recevoir les toutes nouvelles propositions de locations rapidement."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location d'appartement dans votre département : {{geo_object_name}} ?",
            "content": "Les prix des locations d'appartements dans votre appartement ({{geo_object_name}}) sont très variables suivant la localisation, que l'on loue dans une ville de taille moyenne ou un village, le prix peut passer du simple au double. Il dépend également du type d'appartement, de sa surface, du nombre de pièces, mais aussi des commodités. "
          },
          {
            "title": "Combien y a-t-il de location d'appartement dans votre département ({{geo_object_name}}) ?",
            "content": "Il existe des logements de tous types dans votre département : {{geo_object_name}}. Les résidences principales sont souvent les principaux logements, un peu plus de la moitié sont des logements individuels. Le nombre d'appartements en location est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à louer dans votre département {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à louer dans votre département {{geo_object_name}} il est possible de consulter les petites annonces des journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, le plus simple qui vous fera gagner du temps est de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location d'appartement dans votre département : {{geo_object_name}} ?",
            "content": "Les documents qui vous seront nécessaires pour créer votre dossier de locataire, sont :\nun justificatif d'identité avec photographie : photocopie de carte d'identité ou de passeport par exemple\nun justificatif de domicile : 3 dernières quittances de loyer, dernier avis de taxe foncière, ...\nun ou plusieurs documents justifiant de votre situation professionnelle : contrat de travail, copie de la carte professionnelle pour les professions libérales, copie du certificat d'identification de l'Insee pour les travailleurs indépendants, ...\nun ou plusieurs documents justifiant de ses ressources : 3 derniers bulletins de salaires, les justificatifs de versement des indemnités de stage, ...\nun dépôt de garantie."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "Découvrez les annonces d'appartements en location à {{geo_object_name}} dans votre agence ERA Immobilier ou sur notre site internet. Vous souhaitez trouver votre nouveau logement ? L'appartement à la location de vos rêves se trouve parmi nos exclusivités.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location appartement à {{geo_object_name}}",
            "text": "Vous recherchez un appartement à la location dans votre ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}} ? Venez utiliser l'outil de recherche que ERA Immobilier met à votre disposition sur le web. Le grand nombre d'habitations référencés, grâce à son réseau de franchises, vous aidera à trouver votre logement en quelques phases."
          },
          {
            "title": "Vous êtes prêt à définir votre projet de location à {{geo_object_name}}",
            "text": "Pour mettre en location sa maison à {{geo_object_name}}, il faut suivre quelques étapes incontournables que le site ERA vous permet de bien cibler. Nous passons pratiquement tous à un moment de notre vie par la période location immobilière. Afin d’affiner la recherche d’un futur logement il faut, tout d’abord, choisir son projet locatif. Les bien en location sont en nombre sur le site ERA France, mais les résultats seront assez importants selon les critères renseignés. Les annonces de plusieurs biens pourront alors vous être proposées. Bien d’autres données vous permettront de finaliser votre choix définitif : building avec ou sans ascenseur, logement au 1er ou dernier étage, date de disponibilité, cuisine intégrée, grande terrasse ou balcon."
          },
          {
            "title": "Vous allez être épaulé dans la recherche d'une location d'appartement à {{geo_object_name}}",
            "text": "Le nombre d'habitations que ERA propose à la location vous aidera à trouver le logement de vos rêves à {{geo_object_name}}. Les outils de recherche de nos offres, les conseils que vous trouverez en agence sont un plus pour faire le tri dans les annonces référencées. Mais afin d'améliorer votre recherche pensez à créer rapidement votre dossier locataire. N’hésitez pas à réaliser une alerte, afin de bénéficier de la réception de toutes nouvelles offres de locations en temps réel."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location d'appartement à {{geo_object_name}} ?",
            "content": "Les prix des locations d'appartements sont très variables dans la ville de {{geo_object_name}} ({{code_postal}}) au nombre de {{nbr_habitant}} habitants. Suivant la localisation, que l'on loue près des commerces de {{geo_object_name}} ou dans sa banlieue, le prix peut passer du simple au double. Il dépend également du type d'appartement, de sa surface, du nombre de pièces, mais aussi des commodités."
          },
          {
            "title": "Combien y a-t-il de location d'appartement à {{geo_object_name}} ?",
            "content": "La commune de {{geo_object_name}} comprend des résidences principales,logements individuels ou collectifs. Le nombre d'appartements en location est de {{annonces}}."
          },
          {
            "title": "Comment trouver un appartement à louer à {{geo_object_name}} ?",
            "content": "Pour trouver un appartement à louer à {{geo_object_name}} il est possible de consulter les petites annonces des journaux spécialisés, les annonces des réseaux sociaux, les annonces des vitrines des agences immobilières, le plus simple qui vous fera gagner du temps est de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location d'appartement à {{geo_object_name}} ?",
            "content": "Les documents qui vous seront nécessaires pour créer votre dossier de locataire à {{geo_object_name}}, sont :\nun justificatif d'identité avec photographie : photocopie de carte d'identité ou de passeport par exemple\nun justificatif de domicile : 3 dernières quittances de loyer, dernier avis de taxe foncière, ...\nun ou plusieurs documents justifiant de votre situation professionnelle : contrat de travail, copie de la carte professionnelle pour les professions libérales, copie du certificat d'identification de l'Insee pour les travailleurs indépendants, ...\nun ou plusieurs documents justifiant de ses ressources : 3 derniers bulletins de salaires, les justificatifs de versement des indemnités de stage, ...\nun dépôt de garantie."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    },
    "MAISON": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Louez votre {{subTitleTypo}} en France",
        "EDITO": "Habiter une maison est le souhait de nombreux français et la première étape est souvent la location. Trouver la maison conforme à ses critères à louer en France peut être un parcours difficile sans l'aide d'un expert. ERA Immobilier référence des annonces de maisons à la location partout en France.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location maison France",
            "text": "Un large choix de locations de maisons est proposé sur le site ERA Immobilier France, ce qui devrait vous permettre de trouver rapidement la villa de vos rêves. En plus de l'option de choix de maison, le moteur de recherche permet de trouver son appartement, garage, parking, terrain, ... à la location ou en vente."
          },
          {
            "title": "Venez définir les critères de votre projet de location de maison",
            "text": "Famille recomposée, couple pour une première recherche de logement, personnes en mutation professionnelle, les causes qui mènent vers une maison en location sont divers.\nCette recherche doit vous emporter vers la location de vos rêves, car ce n’est pas un simple voyage, votre nouveau logement peut l'être pour des années. C'est pourquoi ERA France a développé un moteur qui, avec un menu et une navigation simple, vous permet de faire les choix du type de location recherchée, de destinations avec une sélection de villes, du nombre de pièces et chambres, et même de la surface et bien d'autres critères. ERA France a pensé à tout, piscine, balcon, cave, ... peuvent être choisis selon votre budget."
          },
          {
            "title": "Vous allez trouver votre maison en location en France",
            "text": "Ce sont vos besoins qui vous permettront de vous déplacer dans le menu, celui-ci vous amènera vers une première sélection de locations de maisons en France. Les fiches détaillées de toutes les locations vous renseigneront sur les villas, photos à l'appui, mais aussi sur le quartier où elles se situent.\nVous avez également la possibilité de créer une alerte afin de recevoir les nouvelles offres de locations de maisons ; mais également de prendre contact avec l'agence de votre département qui les gère afin d'obtenir encore plus de conseils."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location de maison en France ?",
            "content": "Le prix de la location d'une maison en France est différent selon le département dans lequel elle se situe, il est plus élevé en centre-ville, en montagne et sur la côte d'azur. Il est fixé selon le type de maison, sa surface, son nombre de pièces, mais également ses prestations (piscine, séjour spacieux, ...). "
          },
          {
            "title": "Combien y a-t-il de location de maison en France ?",
            "content": "37 millions de logements sont répertoriés en France. Plus de 80% sont des résidences principales, les autres sont des maisons de vacances ou des appartements locations de vacances. 55 % des logements sont des maisons individuelles. Le nombre de maisons en location est de {{annonces}}."
          },
          {
            "title": "Comment trouver une maison à louer en France ?",
            "content": "En consultant les annonces des vitrines des agences immobilières, des réseaux sociaux ou des journaux spécialisés vous avez déjà de nombreux résultats pour votre recherche de location de maison en France. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de maison en France ?",
            "content": "Le dossier de location du futur locataire d'une maison en France doit comporter :\nune pièce d'identité avec photographie,\nun justificatif de domicile,\nun ou plusieurs justificatifs de situation professionnelle,\nun ou plusieurs justificatifs de ressources,\nun dépôt de garantie,\nes mêmes documents pour un cautionnaire s'il est nécessaire."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en location en {{geo_object_name}}",
        "SUBTITLE": "Louez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "Pour louer votre maison en {{geo_object_name}} il est conseillé de faire appel à une agence immobilière professionnelle, elle vous orientera vers votre location au meilleur prix. ERA Immobilier réunit toutes les conditions pour vous permettre d'atteindre cet objectif.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location maison en {{geo_object_name}}",
            "text": "Un grand choix de locations de maisons est proposé sur le site ERA Immobilier France, ce qui devrait permettre de trouver la villa de ses rêves. En plus de l’option de choix de maison, l'outil de recherche offre la possibilité de repérer les annonces d'appartements, garages, stationnements, terrains, etc. à la location ou à la vente."
          },
          {
            "title": "Bien définir votre projet de location de maison en {{geo_object_name}}",
            "text": "Famille recomposée, couple pour une première recherche de logement, personnes en mutation professionnelle, les causes qui mènent vers une maison en location sont divers. Cette recherche doit vous amener vers la location de vos rêves, car ce n’est pas un simple voyage, votre nouveau logement peut l’être pour des années. C’est pourquoi ERA France a développé un moteur qui, avec un menu et une navigation simple, vous permet de faire les choix du type de location recherchée, de destinations avec un éventail de villes, du nombre de pièces et chambres, mais aussi de la surface et bien d’autres critères. ERA France a pensé à tout, piscine, balcon, cave à vin, ... peuvent être privilégiés selon vos finances."
          },
          {
            "title": "Trouvez votre location en {{geo_object_name}}",
            "text": "Ce sont vos attentes qui vous donneront la possibilité de vous déplacer dans le menu, celui-ci vous amènera vers une première sélection de locations de maisons en France. Les fiches détaillées de toutes les locations vous renseigneront sur les villas, photos à l’appui, mais aussi sur le quartier où elles se localisent. Vous avez également la possibilité de développer une alerte afin de profiter des nouvelles offres de locations de maisons ; mais aussi de prendre contact avec l’agence de votre département qui les gère afin de recevoir encore plus de conseils. Découvrez les annonces : location de maisons en {{geo_object_name}}, mettre en location en {{geo_object_name}}, mais aussi locations en {{geo_object_name}}."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location de maison en {{geo_object_name}} ?",
            "content": "Le prix de la location d'une maison en {{geo_object_name}} est différent selon le département dans lequel elle se situe, il est plus élevé en centre-ville. Il est fixé selon le type de maison, sa surface, son nombre de pièces, mais également ses prestations (piscine, séjour spacieux, ...)."
          },
          {
            "title": "Combien y a-t-il de location de maison en {{geo_object_name}} ?",
            "content": "Un bon nombre de logements est répertorié en {{geo_object_name}}. Le nombre de maisons en location en {{geo_object_name}} est de {{annonces}}. La plupart sont des résidences principales, les autres sont des maisons de vacances ou des appartements locations de vacances."
          },
          {
            "title": "Comment trouver une maison à louer en {{geo_object_name}} ?",
            "content": "En consultant les annonces des vitrines des agences immobilières, des réseaux sociaux ou des journaux spécialisés vous avez déjà de nombreux résultats pour votre recherche de location de maison en {{geo_object_name}}. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de maison en {{geo_object_name}} ?",
            "content": "Le dossier de location du futur locataire d'une maison en {{geo_object_name}} doit comporter :\nune pièce d'identité avec photographie,\nun justificatif de domicile,\nun ou plusieurs justificatifs de situation professionnelle,\nun ou plusieurs justificatifs de ressources,\nun dépôt de garantie,\nes mêmes documents pour un cautionnaire s'il est nécessaire."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location dans le {{geo_object_name}}",
        "SUBTITLE": "Louez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "Toutes les annonces et les offres de locations de maisons, du département : {{geo_object_name}}, sur le site ERA Immobilier sont consultables en quelques secondes ; les informations nécessaires pour vous aider dans votre recherche sont également disponibles dans nos agences.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location maison {{geo_object_name}}",
            "text": "Un large choix de locations de maisons est proposé sur le site ERA Immobilier France, ce qui devrait vous permettre de trouver rapidement la villa de vos rêves, dans votre département, {{geo_object_name}} ({{code_departement}}), en région {{region}}. En plus de l’option de choix de maison, l'outil de recherche permet de trouver son appartement, garage, parking, terrain, à la location ou à la vente."
          },
          {
            "title": "Vous voulez opter pour une location de maison en centre {{geo_object_name}} ?",
            "text": "Famille recomposée, couple pour une première recherche de logement, personnes en mutation professionnelle, les causes qui mènent vers une maison en location sont multiples. Cette recherche doit vous amener vers la location de votre vie, car ce n’est pas un simple voyage, votre nouveau logement peut l’être pour des années. C’est pourquoi ERA France a développé un moteur qui, avec un menu et une navigation simple, vous permet de faire les choix du type de location recherchée, avec un éventail de villes, du nombre de pièces et chambres, mais également bien d’autres critères. ERA France a songé à tout, piscine, balcon, cave à vin, ... peuvent être choisis en fonction de vos moyens."
          },
          {
            "title": "Vous cherchez une maison à louer dans votre département : {{geo_object_name}} ?",
            "text": "Ce sont vos besoins qui vous permettront de vous déplacer dans le menu, celui-ci vous amènera vers une première sélection de locations de maisons. Les notices explicatives détaillées de toutes les locations vous renseigneront sur les villas, photos à l’appui, mais aussi sur le quartier où elles se nichent. Vous avez également la possibilité de développer une alerte afin de bénéficier les toutes nouvelles offres de locations de maisons ; mais également de prendre contact avec l’agence de votre département, {{geo_object_name}}, qui les gère, afin d’obtenir encore plus de conseils. Découvrez les annonces : location de maisons en {{geo_object_name}}, mettre en location en {{geo_object_name}}, mais aussi locations de vacances."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location de maison dans votre département : {{geo_object_name}} ?",
            "content": "Le prix de la location d'une maison en centre {{geo_object_name}} est différent selon la ville dans lequel elle se situe, il est plus élevé dans le chef-lieu. Il est fixé selon le type de maison, sa surface, son nombre de pièces, mais également ses prestations (piscine, séjour spacieux, ...). "
          },
          {
            "title": "Combien y a-t-il de location de maison dans votre département : {{geo_object_name}} ?",
            "content": "Un bon nombre de maisons sont répertoriées dans votre département : {{geo_object_name}}. Nombre d'entre elles sont des résidences principales, les autres sont des maisons ou des locations de vacances. Le nombre de maisons en location, dans ce département, est de {{annonces}}."
          },
          {
            "title": "Comment trouver une maison à louer dans votre département : {{geo_object_name}} ?",
            "content": "En consultant les annonces des vitrines des agences immobilières, des réseaux sociaux ou des journaux spécialisés vous avez déjà de nombreux résultats pour votre recherche de location de maison de votre département : {{geo_object_name}}. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de maison dans votre département : {{geo_object_name}} ?",
            "content": "Le dossier de location du futur locataire d'une maison à {{geo_object_name}} doit comporter :\nune pièce d'identité avec photographie,\nun justificatif de domicile,\nun ou plusieurs justificatifs de situation professionnelle,\nun ou plusieurs justificatifs de ressources,\nun dépôt de garantie,\nes mêmes documents pour un cautionnaire s'il est nécessaire."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "Venez faire votre sélection parmi nos nombreuses exclusivités pour la location de votre maison à {{geo_object_name}}. Que vous désiriez louer une maison en centre ville ou dans la périphérie de {{geo_object_name}}, créez une alerte pour être prévenu dès la publication des nouvelles annonces.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location de maison à {{geo_object_name}}",
            "text": "Un large choix de locations de villas est proposé à {{geo_object_name}}, sur le site ERA Immobilier France, ce qui devrait permettre de trouver rapidement la villa de ses rêves. En plus de l’option de choix de maison, l'outil de recherche permet de trouver son appartement, garage, parking, terrain, à la location ou à la vente dans la ville de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}."
          },
          {
            "title": "Vous désirez louer dans une maison à {{geo_object_name}} ?",
            "text": "Famille recomposée, couple pour une première recherche de logement, personnes en mutation professionnelle, les causes qui mènent vers une maison en location sont nombreuses. Cette recherche doit vous amener vers la location de vos rêves, car ce n’est pas un simple voyage, votre nouveau logement peut l’être pour des années. C’est pourquoi ERA France a développé un moteur qui, avec un menu et une navigation simple, vous permet de faire les choix du type de location recherchée, de destinations avec un éventail de rues dans la ville de {{geo_object_name}}, du nombre de pièces et chambres, et de bien d’autres critères. ERA France a pensé à tout, piscine, balcon, cave à vin, peuvent être privilégiés selon vos moyens."
          },
          {
            "title": "Faites votre choix dans parmi les maisons en location à {{geo_object_name}}",
            "text": "Ce sont vos besoins qui vous donneront la possibilité de vous déplacer dans le menu, celui-ci vous amènera vers une première sélection de locations de maisons à {{geo_object_name}}. Les notices explicatives détaillées de toutes les locations vous renseigneront sur les villas, photos à l’appui, mais aussi sur le quartier où elles se localisent. Vous avez également la possibilité de concevoir une alerte afin de recevoir les nouvelles offres de locations de maisons ; mais aussi de prendre contact avec l’agence de votre département qui les gère afin d'avoir de bons conseils.\nDécouvrez les annonces : location de maisons à {{geo_object_name}}, mettre en location à {{geo_object_name}} ({{code_postal}}) , mais aussi locations d'appartements dans votre département : {{département}}."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'une location de maison à {{geo_object_name}} ?",
            "content": "Le prix de la location d'une maison à {{geo_object_name}} est différent selon le département dans lequel elle se situe, il est plus élevé en centre-ville, vers les commerces, près de la mairie. \nLe prix d'une location de maison est fixé selon le type de maison, sa surface, son nombre de pièces, mais également ses prestations (piscine, séjour spacieux, ...). "
          },
          {
            "title": "Combien y a-t-il de location de maison à {{geo_object_name}} ?",
            "content": "La ville de {{geo_object_name}} a un nombre d’habitants de {{nbr_habitant}}.\nUn bon nombre des logements sont répertoriés à {{geo_object_name}}, plus de la moitié sont des résidences principales, les autres sont des maisons de vacances ou des appartements locations de vacances. Le nombre de maisons en location à {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver une maison à louer dans à {{geo_object_name}} ?",
            "content": "En consultant les annonces des vitrines des agences immobilières, des réseaux sociaux ou des journaux spécialisés vous avez déjà de nombreux résultats pour votre recherche de location de maison à {{geo_object_name}}. Mais le plus rapide est certainement de consulter les annonces du site ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de maison à {{geo_object_name}} ?",
            "content": "Le dossier de location du futur locataire d'une maison à {{geo_object_name}} doit comporter :\nune pièce d'identité avec photographie,\nun justificatif de domicile,\nun ou plusieurs justificatifs de situation professionnelle,\nun ou plusieurs justificatifs de ressources,\nun dépôt de garantie,\nes mêmes documents pour un cautionnaire s'il est nécessaire."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    },
    "PARKING-GARAGE": {
      "COUNTRY": {
        "TITLE": "{{annonces}} {{typo}} en France",
        "SUBTITLE": "Louez votre {{subTitleTypo}} en France",
        "EDITO": "Louer un parking ou garage en France n'est pas toujours aisé, se faire aider dans sa recherche par une agence immobilière peut être une solution efficace. Le site internet ERA Immobilier référence un nombre important d'annonces de places de parking en France.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location parking et garage France",
            "text": "Alors que les particuliers possédant un garage, dans leur résidence principale en région, l'utilisent souvent à autre chose qu'à garer leur automobile, ceux qui habitent en ville sont souvent à la recherche d'un parking garage."
          },
          {
            "title": "Vous cherchez un parking ou un garage en France à la location ?",
            "text": "Que l'on habite en centre-ville ou dans un secteur très urbanisé, il n'est pas aisé de garer sa voiture ou sa moto dans la rue. Le logement occupé n'est pas toujours équipé d'une place de parking ou de garages particuliers.\nSi vous souhaitez devenir locataire de votre emplacement de garage, vous aurez le choix entre :\nle moins cher : le parking, place de stationnement limitée par un marquage au sol, situé en sous-sol ou en plein air dans un espace fermé,\nle plus adaptable : le box, emplacement fermé et délimité qui est le plus souvent en souterrain,\nle plus sécurité : le garage, espace fermé et délimité par des murs et une porte, situé à l’extérieur ou dans un endroit totalement privé.\nUne fois défini le type d'emplacement, la zone de recherche et le budget que l'on veut consacrer à son stationnement, il faut se mettre en quête du parking ou box adapté."
          },
          {
            "title": "Parcourez les annonces de location de place de parking ou box",
            "text": "Votre recherche dans les annonces ERA est le plus simple et le plus rapide pour trouver son espace de stationnement, en général les critères de celui-ci sont bien définis.\nIl est précisé s’il s’agit d’un box, d’un parking ou d’un garage, ainsi que son prix, sa situation, sa surface en mètre carré, le montant du loyer et des charges, s’il est dans un espace clos, délimité ou en plein air, en sous-sol ou en surface, aussi plusieurs photos sont présentées.\nPour de plus amples renseignements, sur la place de parking proche de votre secteur, ne manquez pas de vous rapprocher de l'agence de votre département, n'oubliez pas de noter sa référence."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou d'un garage en France ?",
            "content": "Le prix moyen d'une location de place de parking ou d'un box en France est de {{prix_moyen}}. A l'année le budget est plus ou moins important selon son emplacement et les critères demandés."
          },
          {
            "title": "Combien y a-t-il de location de parking ou garage en France ?",
            "content": "Dissociation des baux logement/parking, locataire ne possédant pas de voiture, autant de raisons pour lesquelles il est possible de trouver une place de parking, un box ou un garage, que ce soit auprès d'un professionnel comme d'un particulier. Le nombre de parking ou garage en location en France est de {{annonces}}."
          },
          {
            "title": "Comment trouver un parking ou garage à louer en France ?",
            "content": "Trouver des places de parking ou de garage en location en France près de chez soi n'est pas toujours simple, particulièrement en centre-ville, comme à Paris. Pour trouver une annonce répondant à tous vos critères, le plus simple est d'utiliser le moteur de recherche ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de parking ou garage en France ?",
            "content": "La signature d'un bail pour louer une place de parking ou un garage n'est pas obligatoire mais vivement recommandée. Le propriétaire peut demander les documents qu'il juge utiles en particulier :\nune pièce d'identité de l'automobiliste\nla copie de l’attestation d’assurance auto des conducteurs\nla copie de la carte grise du véhicule\nun dépôt de garantie."
          }
        ]
      },
      "REGION": {
        "TITLE": "{{annonces}} {{typo}} en location en {{geo_object_name}}",
        "SUBTITLE": "Louez votre {{subTitleTypo}} en {{geo_object_name}}",
        "EDITO": "La recherche d'un parking ou garage à la location en {{geo_object_name}} peut être entrevue comme un parcours difficile ; elle est grandement simplifiée grâce à l'aide d'une agence immobilière. Pour cela ERA Immobilier met son expertise à votre service.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location parking et garage en {{geo_object_name}}",
            "text": "Alors que les particuliers, possédant un garage dans leur résidence principale en région {{geo_object_name}}, le destinent souvent à autre chose qu’à garer leur auto, ceux qui habitent en agglomération sont souvent à la recherche d’un parking garage."
          },
          {
            "title": "Vous voulez une aide pour chercher un parking ou un garage en {{geo_object_name}} ?",
            "text": "Que l’on habite en centre-ville ou dans un secteur très urbanisé, il n’est pas facile de garer son véhicule ou sa moto dans la rue. Le logement occupé n'est que rarement équipé d'office d’une place de parking ou de garages particuliers. Si vous souhaitez devenir locataire de votre emplacement de garage, vous aurez le choix entre :\nle moins couteux : le parking, place de stationnement marqué au sol, situé en sous-sol ou en plein air dans un environnement fermé,\nle plus adaptable : le box, emplacement fermé et délimité qui est le plus souvent en souterrain,\nle plus sécurité : le garage, espace fermé et délimité par des murs et une porte, situé à l’extérieur ou dans un lieu pleinement privé.\nDès que l'on a défini la catégorie d’emplacement que l'on souhaite, la zone de recherche et le budget que l’on veut consacrer à son stationnement, il faut se mettre en quête du parking ou box adapté."
          },
          {
            "title": "Epluchez les annonces de parking ou un garage en {{geo_object_name}}",
            "text": "Annonces de location de place de parking ou box, ERA est le plus facile et le plus rapide pour trouver son espace de stationnement, au global les critères de celui-ci sont bien définis. Il est précisé s’il s’agit d’un box, d’un parking ou d’un garage, ainsi que son prix, son statut, sa surface en mètre carré, le montant du loyer et des charges, s’il est dans un environnement clos, délimité ou en plein air, en sous-sol ou en surface, aussi plusieurs photos sont présentées. Pour de plus amples renseignements, sur la place de parking proche de votre secteur, ne manquez pas de vous rapprocher de l’agence de votre département, n’oubliez pas de noter la référence de l'annonce."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou d'un garage en {{geo_object_name}} ?",
            "content": "Le prix moyen d'une location de place de parking ou d'un box en {{geo_object_name}} est de {{prix_moyen}}"
          },
          {
            "title": "Combien y a-t-il de location de parking ou garage en {{geo_object_name}} ?",
            "content": "Dissociation des baux logement/parking, locataire ne possédant pas de voiture, autant de raisons pour lesquelles il est possible de trouver une place de parking, un box ou un garage, que ce soit auprès d'un professionnel comme d'un particulier. Le nombre de parking ou garage en location en {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un parking ou garage à louer en {{geo_object_name}} ?",
            "content": "Trouver des places de parking ou de garage en location en {{geo_object_name}} près de chez soi n'est pas toujours simple, particulièrement en agglomération. Pour trouver une annonce répondant à tous vos critères, le plus simple est d'utiliser le moteur de recherche ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de parking ou garage en {{geo_object_name}} ?",
            "content": "La signature d'un bail pour louer une place de parking ou un garage n'est pas obligatoire mais vivement recommandée. Le propriétaire peut demander les documents qu'il juge utiles en particulier :\nune pièce d'identité de l'automobiliste\nla copie de l’attestation d’assurance auto des conducteurs\nla copie de la carte grise du véhicule\nun dépôt de garantie."
          }
        ]
      },
      "DEPARTEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location dans le {{geo_object_name}}",
        "SUBTITLE": "Louez votre {{subTitleTypo}} dans le {{geo_object_name}}",
        "EDITO": "ERA Immobilier vous accompagne dans votre recherche pour une location de parking ou garage dans votre département ({{geo_object_name}}). Sur son site internet, tout comme dans ses agences immobilières, vous avez la possibilité de consulter à tout moment les annonces référencées.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location parking et garage {{geo_object_name}}",
            "text": "Alors que les particuliers possédant un garage, dans leur résidence principale dans les petites villes ou villages, l’utilisent souvent à autre chose qu’à garer leur automobile, ceux qui habitent en centre-ville sont souvent en quête d’un stationnement garage."
          },
          {
            "title": "Besoin d'aide pour trouver un stationnement ou un garage ({{geo_object_name}}) ?",
            "text": "Que l’on loge en centre-ville ou dans un secteur très urbanisé, il n’est pas aisé de garer son auto ou sa moto, surtout dans la rue. Le logement occupé n’est pas toujours doté d'une place de stationnement ou de garages particuliers. Si vous souhaitez devenir locataire de votre emplacement de garage, vous aurez le choix entre :\nle moins couteux : le stationnement, place de stationnement restreinte par un marquage au sol, situé en sous-sol ou en plein air dans un environnement fermé,\nle plus adaptable : le box, emplacement fermé et délimité qui est le plus souvent en souterrain,\nle plus sécurité : le garage, espace fermé et délimité par des murs et une porte, situé à l’extérieur ou dans un lieu entièrement privé.\nDès lors que l'on a défini le type d’emplacement, la zone de recherche et le budget que l’on veut consacrer à son stationnement, il faut se mettre à la recherche de l'emplacement adapté."
          },
          {
            "title": "Les annonces de location dans son département : {{geo_object_name}}",
            "text": "Votre recherche dans les annonces ERA est le plus facile et le plus rapide pour trouver son espace de stationnement, en général les critères de celui-ci sont bien définis. Il est précisé s’il s’agit d’un box, d’un stationnement ou d’un garage, ainsi que son prix, son statut, sa surface en mètre carré, le loyer et les charges, s’il est dans un environnement clos, délimité ou en plein air, en sous-sol ou en surface, aussi plusieurs photos sont présentées. Pour de plus amples renseignements, sur la place de stationnement proche de votre secteur, ne manquez pas de vous rapprocher de l’agence de votre département, n’oubliez pas de mentionner sa référence."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou d'un garage dans votre département : {{geo_object_name}} ?",
            "content": "Le prix moyen d'une location de place de parking ou d'un box dans votre département ({{geo_object_name}}) est de {{prix_moyen}}. A l'année le budget est plus ou moins important selon son emplacement et les critères demandés."
          },
          {
            "title": "Combien y a-t-il de location de parking ou garage dans votre département : {{geo_object_name}} ?",
            "content": "Dissociation des baux logement/parking, locataire ne possédant pas de voiture, autant de raisons pour lesquelles il est possible de trouver une place de parking, un box ou un garage, que ce soit auprès d'un professionnel comme d'un particulier. Le nombre de parking ou garage en location dans votre département ({{geo_object_name}}) est de {{annonces}}."
          },
          {
            "title": "Comment trouver un parking ou garage à louer dans votre département : {{geo_object_name}} ?",
            "content": "Trouver des places de parking ou de garage en location, dans votre département : {{geo_object_name}} ({{code_departement}}) en région {{geo_object_name}}, près de chez soi, n'est pas toujours simple, particulièrement en périphérie d'une ville. Pour trouver une annonce répondant à tous vos critères, le plus simple est d'utiliser le moteur de recherche ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de parking ou garage dans votre département ({{geo_object_name}}) ?",
            "content": "La signature d'un bail pour louer une place de parking ou un garage n'est pas obligatoire mais vivement recommandée. Le propriétaire peut demander les documents qu'il juge utiles en particulier :\nune pièce d'identité de l'automobiliste\nla copie de l’attestation d’assurance auto des conducteurs\nla copie de la carte grise du véhicule\nun dépôt de garantie."
          }
        ]
      },
      "VILLE": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}",
        "EDITO": "Trouvez une location de parking ou garage dans votre ville, à {{geo_object_name}}, à l'aide des annonces du site internet des franchises ERA Immobilier. Box, parkings en sous-sol, garages en centre-ville, ... , les offres exclusives sont nombreuses et variées.",
        "BOTTOM_CONTENT": [
          {
            "title": "Location parking et garage à {{geo_object_name}}",
            "text": "Alors que les particuliers possédant un garage dans leur résidence principale, dans la banlieue de {{geo_object_name}} ({{departement}} {{code_departement}}), en région {{region}}, l’utilisent souvent à autre chose qu’à garer leur auto, ceux qui habitent en centre-ville sont souvent à la recherche d’un stationnement garage."
          },
          {
            "title": "Vous êtes à la recherche d'un stationnement ou un garage à {{geo_object_name}} ?",
            "text": "Que l’on habite en centre-ville ou dans un quartier très urbanisé de {{geo_object_name}}, il n’est pas facile de garer sa voiture ou sa moto dans la rue. Le logement occupé n'est que rarement équipé d’une place de stationnement ou de garages particuliers. Si vous souhaitez devenir locataire de votre emplacement de garage, vous aurez le choix entre :\nle moins couteux : le stationnement, place de stationnement limité par un marquage au sol, situé en sous-sol ou en plein air dans un environnement fermé,\nle plus adaptable : le box, emplacement fermé et délimité qui est le plus souvent en souterrain,\nle plus sécurité : le garage, espace fermé et entouré de murs et fermé par une porte, situé dans un endroit privé.\nDès lors que l'on a défini la catégorie d’emplacement, sa zone de recherche et le budget que l’on veut consacrer à son stationnement, il faut se mettre à la quête du stationnement ou box adapté."
          },
          {
            "title": "Parcourez les annonces de location de place de stationnement ou box à {{geo_object_name}}",
            "text": "Votre recherche dans les annonces ERA est le plus rapide et le plus rapide pour trouver votre espace de stationnement, en général les critères de celui-ci sont bien mentionnés. Il est précisé s’il s’agit d’un box, d’un stationnement ou d’un garage, ainsi que son prix, sa situation, sa surface en mètre carré, le montant du loyer et des charges, s’il est dans un environnement clos, délimité ou en plein air, en sous-sol ou en surface, aussi plusieurs photos sont jointes. Pour de plus amples renseignements sur la place de stationnement proche de {{geo_object_name}} ({{code_postal}}), ne manquez pas de vous rapprocher de l’agence de votre département : {{geo_object_name}}, n’oubliez pas de recopier sa référence."
          }
        ],
        "FAQ": [
          {
            "title": "Quel est le prix d'un parking ou d'un garage à {{geo_object_name}} ?",
            "content": "Le prix moyen d'une location de place de parking ou d'un box à {{geo_object_name}} est de {{prix_moyen}}. A l'année le budget est plus ou moins important selon son emplacement et les critères demandés. "
          },
          {
            "title": "Combien y a-t-il de location de parking ou garage à {{geo_object_name}} ?",
            "content": "Dissociation des baux logement/parking, locataire ne possédant pas de voiture, autant de raisons pour lesquelles il est possible de trouver une place de parking, un box ou un garage, que ce soit auprès d'un professionnel comme d'un particulier. Le nombre de parking ou garage en location à {{geo_object_name}} est de {{annonces}}."
          },
          {
            "title": "Comment trouver un parking ou garage à louer à {{geo_object_name}} ?",
            "content": "Trouver des places de parking ou de garage en location à {{geo_object_name}} près de chez soi n'est pas toujours simple, particulièrement près de la mairie ou de la gare. Pour trouver une annonce répondant à tous vos critères, le plus simple est d'utiliser le moteur de recherche ERA Immobilier."
          },
          {
            "title": "Quels sont les documents nécessaires pour une location de parking ou garage à {{geo_object_name}} ?",
            "content": "La signature d'un bail pour louer une place de parking ou un garage à {{geo_object_name}} n'est pas obligatoire, mais vivement recommandée. Le propriétaire peut demander les documents qu'il juge utiles en particulier :\nune pièce d'identité de l'automobiliste\nla copie de l’attestation d’assurance auto des conducteurs\nla copie de la carte grise du véhicule\nun dépôt de garantie."
          }
        ]
      },
      "ARRONDISSEMENT": {
        "TITLE": "{{annonces}} {{typo}} en location à {{geo_object_name}} ({{code_postal}}) ",
        "SUBTITLE": "Louez votre {{subTitleTypo}} à {{geo_object_name}}"
      }
    }
  },
  "HOMEPAGE": {
    "TITLE": "Agence immobilière",
    "SUBTITLE": "Agences subtitle",
    "EDITO": "Edito 2 à 3 lignes, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque posuere magna, a consectetur mauris gravida non. Aenean vel fermentum metus, in consequat arcu. Nam malesuada augue non nisl tempor lobortis {{location}}",
    "BOTTOM_CONTENT": [
      {
        "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous",
        "text": "Que l'on souhaite louer, acheter ou vendre un bien immobilier, la première démarche est de faire le choix de son agence immobilière. Ce choix est primordial, car la confiance entre client et agent est une des clefs dans la réussite de votre projet. Choisir une agence proche de chez vous est la garantie d'échanges avec un partenaire qui a la connaissance des biens immobiliers de votre ville, ou quartier, et des prix qui y sont pratiqués ; elle possède déjà un réseau dont elle peut vous faire bénéficier.\nTrouver une agence ERA près de chez vous, parmi les 450 présentes rien qu'en France, vous permettra de profiter des conseils de nos collaborateurs qui ont une parfaite connaissance du marché immobilier local. Faire confiance à l'agence ERA de votre ville, c'est faire le choix de l'un des plus importants réseaux de franchises d'agences immobilières dans le monde. Implanté sur tout le territoire, ERA Immobilier propose un grand nombre d'exclusivités d'appartements ou maisons, que ce soit à la vente ou à la location, sélectionné par nos collaborateurs. Par ailleurs, ceux-ci sauront vous apporter toutes les informations et le suivi nécessaires jusqu'à la signature de votre contrat, à la vente comme à l'achat. <a class='bottomContentLink' href='https://www.erafrance.com/agences'>Vous pouvez trouver votre agence ERA Immobilier en un clic.</a>"
      },
      {
        "title": "Faites estimer votre bien immobilier",
        "text": "La première étape d'un projet de vente immobilière est l'estimation de votre bien. Un prix de vente estimé au mieux déclenchera les premiers contacts d'acheteurs potentiels.\nLe particulier, qui est tenté d'estimer le prix de son bien lui-même, risque d'utiliser un peu vite la méthode qui consiste à additionner le prix d'achat de son logement, les frais d'acquisition, le prix des travaux de rénovation ou d'amélioration, ... auxquels il rajoute une valeur affective.\nIl est toujours possible de faire apprécier la valeur d'un logement à l'aide d'un outil d'estimation. Il vous permet de connaître une valeur indicative de votre bien. Le simulateur est un logiciel qui consiste à associer les données du marché immobilier et les éléments que le vendeur indique ; l'estimation en ligne est souvent approximative.\nPour un calcul fiable et précis il est conseillé de se rapprocher d'une agence immobilière. Vous aurez la possibilité de demander une estimation gratuite.\nLes agents ERA, forts de 30 ans d'expérience dans le domaine de la location et de la vente, savent vous informer sur la réalité du marché, éventuellement les travaux à engager, ils vous font gagner du temps. Ils tiennent compte du département et de la ville où se situe votre bien, du prix du mètre carré des appartements ou maisons similaires au votre. L'état, le nombre de pièces, les équipements, l'accessibilité de votre bien, ainsi que les différentes commodités, mais aussi les défauts, ne seront pas oubliés par nos experts. Mais c'est grâce à la méthode exclusive ERA Immobilier et à ses 4 techniques d'estimation que vous êtes sûr d'obtenir une évaluation fiable."
      },
      {
        "title": "Trouvez votre maison ou appartement idéal",
        "text": "Trouver son bien immobilier idéal n'est pas toujours une mince affaire, manque de temps, manque d'expertise. Il existe de nombreuses aides en ville et même en ligne, mais que valent-elles ? Pouvons-nous y répondre en toute confiance ?\nVotre projet d'achat vous semble bien défini, vous voulez en finir avec la location, vous souhaitez trouver votre appartement, des 'outils' de recherche vous sont nécessaires pour aller jusqu'à la signature de l'acte de vente.\nLa première méthode qui s'offre à vous consiste à éplucher les annonces immobilières spécialisées sur internet ou dans les journaux. Sans oublier les réseaux sociaux, moyen moderne pour accompagner votre recherche. Les annonces sont plus ou moins détaillées, avec photo et/ou vidéos. C'est la manière la plus simple de procéder, qui ne fait pas toujours appel à un intermédiaire, elle vous semble donc économique.\nLes annonces immobilières contiennent la plupart du temps un grand nombre d'informations, mais il faut savoir qu'elles sont souvent floues, qu'un vocabulaire spécifique est utilisé. Faire appel à une agence est une solution pour vous aider à déceler les sous-entendus tout en vous donnant tous les conseils jusqu'à votre achat. Nos conseillers immobilier ERA Immobilier assistent les acquéreurs dans leur projet, tout comme les vendeurs qui leur ont donné mandat de vente. Depuis l'établissement de votre budget, notre négociateur vous aidera à définir un secteur, le type de bien que vous pouvez acquérir, mais aussi la superficie, le nombre de pièces, ... Faire appel aux services de nos agences immobilières multiplie vos chances de trouver votre maison au prix réel du marché, nos agents immobiliers ont l'habitude de la négociation, ils vous font gagner du temps et de l'argent."
      },
      {
        "title": "Vendez votre logement avec ERA Immobilier",
        "text": "Faire appel à une agence immobilière ERA Immobilier, pour la vente de votre appartement ou votre maison, c'est bénéficier de services de professionnels. L'agence va déterminer le juste prix, qu'il s'agisse d'un appartement 2 pièces ou d'un logement plus grand, et ce, grâce à la Multi-Expertise ERA qui associe 4 techniques d'estimation. D'autre part, nos agents savent vous donner les conseils de home staging et de valorisation de votre bien immobilier.\nPar le biais de notre site internet, vous pouvez trouver l'agence <a class='bottomContentLink' href='https://www.erafrance.com/agences'>ERA à proximité de chez vous parmi nos 450 franchisés en France</a>. Une large diffusion de votre annonce de vente, comprenant galerie de photos, fiche technique, bilan énergétique, ... pourra s'effectuer dans nos pages d'offres, plus particulièrement dans notre sélection d'exclusivités, et dans nos vitrines.\nVote expert ERA assure activement la gestion des clients potentiels. Il réunit toutes les conditions, pour les faire passer de contact à prospect, en étudiant leur dossier et leur solvabilité et en organisant les visites, et ainsi les compter parmi vos éventuels acquéreurs.\nVotre agence ERA vous propose deux types de mandats : le mandat simple, vous pouvez confier votre bien à plusieurs agences immobilières ; le mandat exclusif, une seule agence peut proposer votre logement et la vente, celui-ci vous garantit une vente plus rapide.\nAvec une très bonne connaissance du marché, ERA Immobilier vous permet de vendre au meilleur taux et dans les meilleurs délais.\nDe plus faire appel aux services d'un conseiller immobilier assure un meilleur taux de réussite. En effet, selon de multiples études 70% des propriétaires ont réussi à vendre leur bien par ce biais contre 28% pour les propriétaires ayant tenté de le faire directement."
      }
    ],
    "FAQ": [
      {
        "title": "Comment choisir son agence immobilière ?",
        "content": "Pour bien choisir son agence immobilière il parait essentiel de respecter quelques critères.\nTout d'abord le choix d'une agence spécialiste de votre secteur est primordial, elle aura une parfaite connaissance du marché de votre ville.\nChoisir une agence qui connait parfaitement les biens du même type que le vôtre s'impose également, afin qu'elle identifie rapidement les acheteurs potentiels.\nVérifiez l'existence réelle de l'agence et assurez-vous que votre interlocuteur possède une carte professionnelle délivrée par la CCI (chambre de commerce et d'industrie) de sa région.\nComparez les agences. Depuis combien de temps existent-elles ? Quel est le volume de leurs mandats ? Quels sont leurs tarifs ? Quelle est sa E-réputation ? Publient-t-elles des témoignages et avis client sur le Web ?\nEnfin n'oubliez pas que, comme dans toute relation, la confiance est essentielle."
      },
      {
        "title": "Pourquoi choisir ERA Immobilier ?",
        "content": "Choisir une agence ERA c'est choisir un important réseau de franchises.\nLa communication se fait inter-agences pour garantir une très bonne visibilité d'un bien à la vente. Elle se fait également par tous les moyens actuellement mis à notre disposition, que ce soit sur internet, sur les réseaux ou dans les journaux.\nL'outil d'expertise ERA Immobilier vous assure que votre bien est estimé au meilleur prix. Nos agents vous conseillent pour le valoriser au mieux et vous guident dans la constitution de votre dossier juridique et technique."
      },
      {
        "title": "Quels sont les délais de vente d'un bien immobilier ?",
        "content": "Vendre un bien immobilier prend plus ou moins de temps, en fonction du marché immobilier et la tension entre l'offre et la demande, de son prix, de sa situation, de son état.\nLes étapes importantes pour une vente immobilière sont : l'estimation du prix le plus juste, la recherche d'un acquéreur, la signature du compromis de vente, la signature de l'acte de vente devant le notaire.\nLe délai moyen est de 3 mois."
      },
      {
        "title": "Comment estimer la valeur de mon bien ?",
        "content": "Étape importante pour une vente immobilière, l'estimation du prix de son bien doit être en corrélation avec les tarifs du secteur.\nVous avez la possibilité d'effectuer cette estimation par le biais de sites internet, il est conseillé de ne pas vous en tenir à la première évaluation.\nL'expertise d'un agent immobilier est toutefois recommandée il possède une parfaite connaissance de votre secteur est recommandée et des biens semblables au votre."
      },
      {
        "title": "Est-ce le moment d'acheter un bien immobilier en 2022 ?",
        "content": "Les prix de l'immobilier se montrent à présent à un niveau plus élevé que les prévisions ne le laissaient apparaître, les spécialistes nous laissent entrevoir une baisse dans les mois à venir, et ce, au fur et à mesure de l'utilisation de l'excès de l'épargne des ménages.\nCependant les taux des crédits immobiliers eux, sont particulièrement bas, c'est donc bien le moment d'acheter un bien immobilier. L'immobilier reste un investissement fiable."
      },
      {
        "title": "Faut-il acheter dans le neuf ou dans l’ancien ?",
        "content": "Neuf ou ancien chacun présente ses avantages et ses inconvénients. Habitable immédiatement, personnalisable, pratique, frais notariés réduits, ... coût d'acquisition plus élevé pour le neuf.\nCharme, disponible plus rapidement, prix de départ moins élevé, ... travaux et coût de revient parfois supérieur pour l'ancien.\nLes économies d'énergie peuvent faire la différence ..."
      }
    ]
  },
  "AGENCES": {
    "COUNTRY": {
      "TITLE": "Agence immobilière",
      "SUBTITLE": "Trouvez votre agence immobilière en France dans le réseaux ERA Immobilier",
      "EDITO": "Futur acquéreur, vendeur, locataire ou propriétaire, vous avez un projet d'achat immobilier ou souhaitez vendre votre bien ou le louer ? Afin d'avoir le meilleur accompagnement dans vos démarches, il est souhaitable de vous faire épauler par une agence immobilière.•\nERA, présent sur le territoire national et international, est là pour vous représenter ou vous guider dans votre recherche.",
      "BOTTOM_CONTENT": [
        {
          "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous",
          "text": "Pour mieux définir votre projet immobilier et vos critères de sélection, il est préférable de sélectionner l'agence proche de chez vous ou de votre bien.\nERA Immobilier, présent dans 33 pays, offre un réseau de près de 450 agences implantées sur toute la France métropolitaine ; vous trouverez donc facilement l'une d'elles dans votre ville ou votre département.\nVous pensez que votre projet immobilier, que ce soit à l'achat, la vente, ou la location, mérite un bon accompagnement ? Vous pourrez facilement entrer en relation avec l'une de nos agences immobilières à proximité de chez vous, en renseignant le nom de votre commune, son code postal ou tout simplement votre département dans le moteur de recherche de notre site internet."
        },
        {
          "title": "Faites estimer votre bien immobilier par une agence ERA Immobilier",
          "text": "La première étape, pour vendre votre propriété, est l'estimation de celui-ci. Etape souvent difficile pour ceux qui voudront l'effectuer sans aide. En effet, tout le monde ne possède pas tous les éléments, calculs et données nécessaires pour l'estimation qui assurera une vente rapide. De plus lorsqu'il s'agit de notre bien nous ne portons pas le même regard sur celui-ci. Pour estimer précisément votre maison ou appartement la solution la plus sûre est donc de faire appel à un professionnel.\nUn notaire ou un expert immobilier sont tout à fait à même d'estimer votre bien. Mais parce qu'une bonne estimation vous permettra de le vendre plus rapidement et de limiter les négociations par les futurs acquéreurs, la Multi-Expertise ERA Immobilier, qui associe 4 outils d'estimation, vous garantit une bonne appréciation en vous donnant une fourchette de prix de vente adaptée.\nAvec ERA Immobilier vous aurez le choix d'effectuer votre évaluation directement sur notre site. En 2 minutes notre formulaire vous permet d'avoir un prix de vente pertinent de votre bien. Pour une expertise plus précise faites appel à l'agent immobilier ERA de votre ville. Il connait bien les prix du marché dans votre localité, il considèrera toutes les conditions à prendre en compte pour une vente rapide ; de plus, ce service est gratuit et sans engagement."
        },
        {
          "title": "Trouvez votre maison ou appartement idéal avec le réseau ERA Immobilier",
          "text": "Une agence immobilière met en relation vendeur et acquéreur potentiel. Grâce à ses connaissances commerciales, juridiques et techniques, ainsi que son sens de la psychologie, l'agent immobilier sait se placer entre acheteur et vendeur.\nParce que c'est le premier réseau d'agences immobilières, ERA Immobilier possède un large choix de maisons et appartements mis à la vente, parmi lesquels un futur acheteur ayant un projet d'achat trouvera le logement de ses rêves.\nA la recherche d'un logement pour votre famille. ERA Immobilier vous aidera à affiner votre projet, tout en vous amenant à répondre à quelques questions :\nQuel est votre budget ?\nRecherchez-vous une maison ou un appartement ?\nDans du neuf ou de l'ancien ?\nVotre projet doit-il se situer à la ville ou à la campagne ?\nUne fois vos critères bien définis nos agents immobiliers vous accompagneront lors de la visite de biens conformes à vos choix. Ces conseillers, experts, seront présents depuis la prospection de propriétés à vendre jusqu'à la signature de l'acte de vente devant notaire."
        },
        {
          "title": "Vendez votre logement avec ERA Immobilier",
          "text": "Afin de vous mettre en relation avec d'éventuels acheteurs nos agents immobiliers, experts en home staging, seront vous donner conseils pour la mise en valeur de votre bien.\nAvec près de 450 agences en France, ERA Immobilier est un réseau qui vous permet de toucher un grand nombre d'acquéreurs potentiels ; que ce soit par le biais de nos annonces en vitrines d'agences ou sur notre site internet.\nVotre appartement ou votre maison est à vendre ? Pensez à la première étape qu'il faut à tout prix effectuer : l'estimation de votre bien.\nPour cela, deux possibilités s'offrent à vous :\nFaire cette estimation en ligne en moins de 2 minutes grâce à notre outil d'estimation en ligne, votre fourchette de prix de vente vous sera définie ;\nEt pour une évaluation plus précise, n'hésitez pas à faire appel à l'une de nos agences, gratuitement et sans engagement elle saura vous apporter son expertise."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est l'intérêt de passer par une agence immobilière ?",
          "content": "Les agents immobiliers ont l'habitude de gérer la relation vendeur/acquéreur, son rôle est neutre entre les deux parties et il a intérêt à ce que la transaction aboutisse.\nLes affiliés ERA Immobilier ont l'habitude de conduire des négociations entre vendeur et acheteur intéressé par un bien.\nHormis leur rôle de prospect, les spécialistes de nos agences immobilières savent organiser les visites, vérifier la solvabilité et la motivation des potentiels acquéreurs. Pour rappel ERA France propose un accompagnement administratif et juridique pour constituer un dossier auprès d'un notaire, le but étant que votre achat aboutisse sans aucun problème. D'après l'étude de l'université Paris Dauphine, 46% est le pourcentage de ventes de particulier qui n'aboutissent pas pour des raisons juridiques."
        },
        {
          "title": "Comment faire pour trouver un logement rapidement ?",
          "content": "Trouver son logement idéal n'est pas toujours facile si l'on n'est pas épaulé par un professionnel de l'immobilier.\nLa recherche interminable des petites annonces correspondant à ses choix, le décryptable des avis de mise à la vente parfois déroutants, la file d'attente interminable des acheteurs potentiels à chaque visite d'appartement, ...\nLa solution pour ceux qui manquent de temps et d'expertise est l'agence immobilière. Elle agit comme un intermédiaire entre un vendeur et un acquéreur. L'agent pourra effectuer des visites pour votre compte, il vous informe régulièrement du progrès de sa mission, il vous assiste jusqu'à la signature de l'acte de vente chez le notaire."
        },
        {
          "title": "Comment choisir votre agence immobilière ?",
          "content": "Pour choisir l'agence immobilière qui sera efficace pour la vente ou l'achat de votre logement, il est préférable de respecter quelques conseils :\nPréférez l'agence de votre commune ou secteur géographique,\nChoisissez l'agence immobilière spécialiste du type de votre bien ou du bien recherché\nComparez les agences immobilières susceptibles de gérer votre dossier\nPensez à vérifier l'existence de l'agence retenue\nPréférez un agent expert du type de votre bien ou du bien recherché\nEt n'oubliez pas, comme dans toute relation, la confiance est primordiale !"
        },
        {
          "title": "Qu'est-ce qu'une bonne agence immobilière ?",
          "content": "Vous avez le projet de vendre votre bien ou d'acheter votre logement, faire appel à une agence immobilière sera nécessaire si vous manquez de temps pour vous en occuper vous-même. Mais il va être préférable de choisir la 'bonne' agence, pour avoir de meilleurs services et l'estimation la plus juste du bien.\nEn premiers lieux pensez au bouche-à-oreille pour vous renseigner sur la notoriété des agences de votre secteur. Les agences ERA Immobilier possèdent une importante notoriété et grande expertise ; de plus leur site internet regroupe un nombre important de biens à acheter et vous propose des outils fiables pour l'estimation de ceux que vous souhaitez vendre.\nLe professionnel qui va prendre en charge votre dossier doit être d'une bonne écoute. Nos franchisés ERA sont formés pour être à l'écoute de vos besoins.\nUne bonne agence sait proposer un grand nombre de services. ERA met à votre disposition des outils et méthodes de travail pour vous aider à vendre au meilleur prix, comme pour vous seconder dans la démarche d'acquisition de votre logement.\nLa bonne agence immobilière est tenue de communiquer ses tarifs. ERA affiche le montant de ses honoraires.\nPlus de 90% des recherches immobilières s'effectuent sur internet, il est donc primordial que votre agence immobilière y soit présente. ERA possède un site regroupant toutes les annonces de ses affiliés."
        }
      ]
    },
    "REGION": {
      "TITLE": "Agence immobilière {{geo_object_name}}",
      "SUBTITLE": "Trouvez votre agence immobilière en {{geo_object_name}} sur ERA Immobilier",
      "EDITO": "Futur acquéreur, vendeur, locataire ou propriétaire, vous avez un projet d'achat immobilier ou souhaitez vendre votre bien ou le louer ? Afin d'avoir le meilleur accompagnement dans vos démarches, il est souhaitable de vous faire épauler par une agence immobilière.•\nERA, présent sur le territoire national et international, est là pour vous représenter ou vous guider dans votre recherche.",      "BOTTOM_CONTENT": [
        {
          "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous en {{geo_object_name}}",
          "text": "Pour mieux définir votre projet immobilier et vos critères de sélection, il est préférable de sélectionner l'agence proche de chez vous ou de votre bien.\nERA Immobilier, présent dans 33 pays, offre un réseau de près de 450 agences implantées sur toute la France métropolitaine ; vous trouverez donc facilement l'une d'elles dans votre ville ou votre département.\nVous pensez que votre projet immobilier, que ce soit à l'achat, la vente, ou la location, mérite un bon accompagnement ? Vous pourrez facilement entrer en relation avec l'une de nos agences immobilières à proximité de chez vous, en renseignant le nom de votre commune, son code postal ou tout simplement votre département dans le moteur de recherche de notre site internet."
        },
        {
          "title": "Faites estimer votre bien immobilier par une agence ERA Immobilier en {{geo_object_name}}",
          "text": "La première étape, pour vendre votre propriété, est l'estimation de celui-ci. Etape souvent difficile pour ceux qui voudront l'effectuer sans aide. En effet, tout le monde ne possède pas tous les éléments, calculs et données nécessaires pour l'estimation qui assurera une vente rapide. De plus lorsqu'il s'agit de notre bien nous ne portons pas le même regard sur celui-ci. Pour estimer précisément votre maison ou appartement la solution la plus sûre est donc de faire appel à un professionnel.\nUn notaire ou un expert immobilier sont tout à fait à même d'estimer votre bien. Mais parce qu'une bonne estimation vous permettra de le vendre plus rapidement et de limiter les négociations par les futurs acquéreurs, la Multi-Expertise ERA Immobilier, qui associe 4 outils d'estimation, vous garantit une bonne appréciation en vous donnant une fourchette de prix de vente adaptée.\nAvec ERA Immobilier vous aurez le choix d'effectuer votre évaluation directement sur notre site. En 2 minutes notre formulaire vous permet d'avoir un prix de vente pertinent de votre bien. Pour une expertise plus précise faites appel à l'agent immobilier ERA de votre ville. Il connait bien les prix du marché dans votre localité, il considèrera toutes les conditions à prendre en compte pour une vente rapide ; de plus, ce service est gratuit et sans engagement."
        },
        {
          "title": "Trouvez votre maison ou appartement idéal avec le réseau ERA Immobilier en {{geo_object_name}}",
          "text": "Une agence immobilière met en relation vendeur et acquéreur potentiel. Grâce à ses connaissances commerciales, juridiques et techniques, ainsi que son sens de la psychologie, l'agent immobilier sait se placer entre acheteur et vendeur.\nParce que c'est le premier réseau d'agences immobilières, ERA Immobilier possède un large choix de maisons et appartements mis à la vente, parmi lesquels un futur acheteur ayant un projet d'achat trouvera le logement de ses rêves.\nA la recherche d'un logement pour votre famille. ERA Immobilier vous aidera à affiner votre projet, tout en vous amenant à répondre à quelques questions :\nQuel est votre budget ?\nRecherchez-vous une maison ou un appartement ?\nDans du neuf ou de l'ancien ?\nVotre projet doit-il se situer à la ville ou à la campagne ?\nUne fois vos critères bien définis nos agents immobiliers vous accompagneront lors de la visite de biens conformes à vos choix. Ces conseillers, experts, seront présents depuis la prospection de propriétés à vendre jusqu'à la signature de l'acte de vente devant notaire."
        },
        {
          "title": "Vendez votre logement avec ERA Immobilier {{geo_object_name}} ",
          "text": "Afin de vous mettre en relation avec d'éventuels acheteurs nos agents immobiliers, experts en home staging, seront vous donner conseils pour la mise en valeur de votre bien.\nAvec près de 450 agences en France, ERA Immobilier est un réseau qui vous permet de toucher un grand nombre d'acquéreurs potentiels ; que ce soit par le biais de nos annonces en vitrines d'agences ou sur notre site internet.\nVotre appartement ou votre maison est à vendre ? Pensez à la première étape qu'il faut à tout prix effectuer : l'estimation de votre bien.\nPour cela, deux possibilités s'offrent à vous :\nFaire cette estimation en ligne en moins de 2 minutes grâce à notre outil d'estimation en ligne, votre fourchette de prix de vente vous sera définie ;\nEt pour une évaluation plus précise, n'hésitez pas à faire appel à l'une de nos agences, gratuitement et sans engagement elle saura vous apporter son expertise."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est l'intérêt de passer par une agence immobilière en {{geo_object_name}} ?",
          "content": "Les agents immobiliers ont l'habitude de gérer la relation vendeur/acquéreur, son rôle est neutre entre les deux parties et il a intérêt à ce que la transaction aboutisse.\nLes affiliés ERA Immobilier ont l'habitude de conduire des négociations entre vendeur et acheteur intéressé par un bien.\nHormis leur rôle de prospect, les spécialistes de nos agences immobilières savent organiser les visites, vérifier la solvabilité et la motivation des potentiels acquéreurs. Pour rappel ERA France propose un accompagnement administratif et juridique pour constituer un dossier auprès d'un notaire, le but étant que votre achat aboutisse sans aucun problème. D'après l'étude de l'université Paris Dauphine, 46% est le pourcentage de ventes de particulier qui n'aboutissent pas pour des raisons juridiques."
        },
        {
          "title": "Comment faire pour trouver un logement rapidement en {{geo_object_name}} ?",
          "content": "Trouver son logement idéal n'est pas toujours facile si l'on n'est pas épaulé par un professionnel de l'immobilier.\nLa recherche interminable des petites annonces correspondant à ses choix, le décryptable des avis de mise à la vente parfois déroutants, la file d'attente interminable des acheteurs potentiels à chaque visite d'appartement, ...\nLa solution pour ceux qui manquent de temps et d'expertise est l'agence immobilière. Elle agit comme un intermédiaire entre un vendeur et un acquéreur. L'agent pourra effectuer des visites pour votre compte, il vous informe régulièrement du progrès de sa mission, il vous assiste jusqu'à la signature de l'acte de vente chez le notaire."
        },
        {
          "title": "Comment choisir votre agence immobilière en {{geo_object_name}} ?",
          "content": "Pour choisir l'agence immobilière qui sera efficace pour la vente ou l'achat de votre logement, il est préférable de respecter quelques conseils :\nPréférez l'agence de votre commune ou secteur géographique,\nChoisissez l'agence immobilière spécialiste du type de votre bien ou du bien recherché\nComparez les agences immobilières susceptibles de gérer votre dossier\nPensez à vérifier l'existence de l'agence retenue\nPréférez un agent expert du type de votre bien ou du bien recherché\nEt n'oubliez pas, comme dans toute relation, la confiance est primordiale !"
        },
        {
          "title": "Qu'est-ce qu'une bonne agence immobilière en {{geo_object_name}} ?",
          "content": "Vous avez le projet de vendre votre bien ou d'acheter votre logement, faire appel à une agence immobilière sera nécessaire si vous manquez de temps pour vous en occuper vous-même. Mais il va être préférable de choisir la 'bonne' agence, pour avoir de meilleurs services et l'estimation la plus juste du bien.\nEn premiers lieux pensez au bouche-à-oreille pour vous renseigner sur la notoriété des agences de votre secteur. Les agences ERA Immobilier possèdent une importante notoriété et grande expertise ; de plus leur site internet regroupe un nombre important de biens à acheter et vous propose des outils fiables pour l'estimation de ceux que vous souhaitez vendre.\nLe professionnel qui va prendre en charge votre dossier doit être d'une bonne écoute. Nos franchisés ERA sont formés pour être à l'écoute de vos besoins.\nUne bonne agence sait proposer un grand nombre de services. ERA met à votre disposition des outils et méthodes de travail pour vous aider à vendre au meilleur prix, comme pour vous seconder dans la démarche d'acquisition de votre logement.\nLa bonne agence immobilière est tenue de communiquer ses tarifs. ERA affiche le montant de ses honoraires.\nPlus de 90% des recherches immobilières s'effectuent sur internet, il est donc primordial que votre agence immobilière y soit présente. ERA possède un site regroupant toutes les annonces de ses affiliés."
        }
      ]
    },
    "DEPARTEMENT": {
      "TITLE": "Agence immobilière dans le {{geo_object_name}}",
      "SUBTITLE": "Trouvez votre agence immobilière dans le {{geo_object_name}} sur ERA Immobilier",
      "EDITO": "Futur acquéreur, vendeur, locataire ou propriétaire, vous avez un projet d'achat immobilier ou souhaitez vendre votre bien ou le louer ? Afin d'avoir le meilleur accompagnement dans vos démarches, il est souhaitable de vous faire épauler par une agence immobilière.•\nERA, présent sur le territoire national et international, est là pour vous représenter ou vous guider dans votre recherche.",      "BOTTOM_CONTENT": [
        {
          "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous en {{geo_object_name}}",
          "text": "Pour mieux définir votre projet immobilier et vos critères de sélection, il est préférable de sélectionner l'agence proche de chez vous ou de votre bien.\nERA Immobilier, présent dans 33 pays, offre un réseau de près de 450 agences implantées sur toute la France métropolitaine ; vous trouverez donc facilement l'une d'elles dans votre ville ou votre département.\nVous pensez que votre projet immobilier, que ce soit à l'achat, la vente, ou la location, mérite un bon accompagnement ? Vous pourrez facilement entrer en relation avec l'une de nos agences immobilières à proximité de chez vous, en renseignant le nom de votre commune, son code postal ou tout simplement votre département dans le moteur de recherche de notre site internet."
        },
        {
          "title": "Faites estimer votre bien immobilier par une agence ERA Immobilier en {{geo_object_name}}",
          "text": "La première étape, pour vendre votre propriété, est l'estimation de celui-ci. Etape souvent difficile pour ceux qui voudront l'effectuer sans aide. En effet, tout le monde ne possède pas tous les éléments, calculs et données nécessaires pour l'estimation qui assurera une vente rapide. De plus lorsqu'il s'agit de notre bien nous ne portons pas le même regard sur celui-ci. Pour estimer précisément votre maison ou appartement la solution la plus sûre est donc de faire appel à un professionnel.\nUn notaire ou un expert immobilier sont tout à fait à même d'estimer votre bien. Mais parce qu'une bonne estimation vous permettra de le vendre plus rapidement et de limiter les négociations par les futurs acquéreurs, la Multi-Expertise ERA Immobilier, qui associe 4 outils d'estimation, vous garantit une bonne appréciation en vous donnant une fourchette de prix de vente adaptée.\nAvec ERA Immobilier vous aurez le choix d'effectuer votre évaluation directement sur notre site. En 2 minutes notre formulaire vous permet d'avoir un prix de vente pertinent de votre bien. Pour une expertise plus précise faites appel à l'agent immobilier ERA de votre ville. Il connait bien les prix du marché dans votre localité, il considèrera toutes les conditions à prendre en compte pour une vente rapide ; de plus, ce service est gratuit et sans engagement."
        },
        {
          "title": "Trouvez votre maison ou appartement idéal avec le réseau ERA Immobilier en {{geo_object_name}}",
          "text": "Une agence immobilière met en relation vendeur et acquéreur potentiel. Grâce à ses connaissances commerciales, juridiques et techniques, ainsi que son sens de la psychologie, l'agent immobilier sait se placer entre acheteur et vendeur.\nParce que c'est le premier réseau d'agences immobilières, ERA Immobilier possède un large choix de maisons et appartements mis à la vente, parmi lesquels un futur acheteur ayant un projet d'achat trouvera le logement de ses rêves.\nA la recherche d'un logement pour votre famille. ERA Immobilier vous aidera à affiner votre projet, tout en vous amenant à répondre à quelques questions :\nQuel est votre budget ?\nRecherchez-vous une maison ou un appartement ?\nDans du neuf ou de l'ancien ?\nVotre projet doit-il se situer à la ville ou à la campagne ?\nUne fois vos critères bien définis nos agents immobiliers vous accompagneront lors de la visite de biens conformes à vos choix. Ces conseillers, experts, seront présents depuis la prospection de propriétés à vendre jusqu'à la signature de l'acte de vente devant notaire."
        },
        {
          "title": "Vendez votre logement avec ERA Immobilier en {{geo_object_name}}",
          "text": "Afin de vous mettre en relation avec d'éventuels acheteurs nos agents immobiliers, experts en home staging, seront vous donner conseils pour la mise en valeur de votre bien.\nAvec près de 450 agences en France, ERA Immobilier est un réseau qui vous permet de toucher un grand nombre d'acquéreurs potentiels ; que ce soit par le biais de nos annonces en vitrines d'agences ou sur notre site internet.\nVotre appartement ou votre maison est à vendre ? Pensez à la première étape qu'il faut à tout prix effectuer : l'estimation de votre bien.\nPour cela, deux possibilités s'offrent à vous :\nFaire cette estimation en ligne en moins de 2 minutes grâce à notre outil d'estimation en ligne, votre fourchette de prix de vente vous sera définie ;\nEt pour une évaluation plus précise, n'hésitez pas à faire appel à l'une de nos agences, gratuitement et sans engagement elle saura vous apporter son expertise."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est l'intérêt de passer par une agence immobilière en {{geo_object_name}} ?",
          "content": "Les agents immobiliers ont l'habitude de gérer la relation vendeur/acquéreur, son rôle est neutre entre les deux parties et il a intérêt à ce que la transaction aboutisse.\nLes affiliés ERA Immobilier ont l'habitude de conduire des négociations entre vendeur et acheteur intéressé par un bien.\nHormis leur rôle de prospect, les spécialistes de nos agences immobilières savent organiser les visites, vérifier la solvabilité et la motivation des potentiels acquéreurs. Pour rappel ERA France propose un accompagnement administratif et juridique pour constituer un dossier auprès d'un notaire, le but étant que votre achat aboutisse sans aucun problème. D'après l'étude de l'université Paris Dauphine, 46% est le pourcentage de ventes de particulier qui n'aboutissent pas pour des raisons juridiques."
        },
        {
          "title": "Comment faire pour trouver un logement rapidement en {{geo_object_name}} ?",
          "content": "Trouver son logement idéal n'est pas toujours facile si l'on n'est pas épaulé par un professionnel de l'immobilier.\nLa recherche interminable des petites annonces correspondant à ses choix, le décryptable des avis de mise à la vente parfois déroutants, la file d'attente interminable des acheteurs potentiels à chaque visite d'appartement, ...\nLa solution pour ceux qui manquent de temps et d'expertise est l'agence immobilière. Elle agit comme un intermédiaire entre un vendeur et un acquéreur. L'agent pourra effectuer des visites pour votre compte, il vous informe régulièrement du progrès de sa mission, il vous assiste jusqu'à la signature de l'acte de vente chez le notaire."
        },
        {
          "title": "Comment choisir votre agence immobilière en {{geo_object_name}} ?",
          "content": "Pour choisir l'agence immobilière qui sera efficace pour la vente ou l'achat de votre logement, il est préférable de respecter quelques conseils :\nPréférez l'agence de votre commune ou secteur géographique,\nChoisissez l'agence immobilière spécialiste du type de votre bien ou du bien recherché\nComparez les agences immobilières susceptibles de gérer votre dossier\nPensez à vérifier l'existence de l'agence retenue\nPréférez un agent expert du type de votre bien ou du bien recherché\nEt n'oubliez pas, comme dans toute relation, la confiance est primordiale !"
        },
        {
          "title": "Qu'est-ce qu'une bonne agence immobilièreen {{geo_object_name}} ?",
          "content": "Vous avez le projet de vendre votre bien ou d'acheter votre logement, faire appel à une agence immobilière sera nécessaire si vous manquez de temps pour vous en occuper vous-même. Mais il va être préférable de choisir la 'bonne' agence, pour avoir de meilleurs services et l'estimation la plus juste du bien.\nEn premiers lieux pensez au bouche-à-oreille pour vous renseigner sur la notoriété des agences de votre secteur. Les agences ERA Immobilier possèdent une importante notoriété et grande expertise ; de plus leur site internet regroupe un nombre important de biens à acheter et vous propose des outils fiables pour l'estimation de ceux que vous souhaitez vendre.\nLe professionnel qui va prendre en charge votre dossier doit être d'une bonne écoute. Nos franchisés ERA sont formés pour être à l'écoute de vos besoins.\nUne bonne agence sait proposer un grand nombre de services. ERA met à votre disposition des outils et méthodes de travail pour vous aider à vendre au meilleur prix, comme pour vous seconder dans la démarche d'acquisition de votre logement.\nLa bonne agence immobilière est tenue de communiquer ses tarifs. ERA affiche le montant de ses honoraires.\nPlus de 90% des recherches immobilières s'effectuent sur internet, il est donc primordial que votre agence immobilière y soit présente. ERA possède un site regroupant toutes les annonces de ses affiliés."
        }
      ]
    },
    "VILLE": {
      "TITLE": "Agence immobilière {{geo_object_name}} {{code_postal}}",
      "SUBTITLE": "Trouvez votre agence immobilière à {{geo_object_name}} sur ERA Immobilier",
      "EDITO": "Futur acquéreur, vendeur, locataire ou propriétaire, vous avez un projet d'achat immobilier ou souhaitez vendre votre bien ou le louer ? Afin d'avoir le meilleur accompagnement dans vos démarches, il est souhaitable de vous faire épauler par une agence immobilière.•\nERA, présent sur le territoire national et international, est là pour vous représenter ou vous guider dans votre recherche.",
      "BOTTOM_CONTENT": [
        {
          "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous à {{geo_object_name}} ",
          "text": "Pour mieux définir votre projet immobilier et vos critères de sélection, il est préférable de sélectionner l'agence proche de chez vous ou de votre bien.\nERA Immobilier, présent dans 33 pays, offre un réseau de près de 450 agences implantées sur toute la France métropolitaine ; vous trouverez donc facilement l'une d'elles dans votre ville ou votre département.\nVous pensez que votre projet immobilier, que ce soit à l'achat, la vente, ou la location, mérite un bon accompagnement ? Vous pourrez facilement entrer en relation avec l'une de nos agences immobilières à proximité de chez vous, en renseignant le nom de votre commune, son code postal ou tout simplement votre département dans le moteur de recherche de notre site internet."
        },
        {
          "title": "Faites estimer votre bien immobilier par une agence ERA Immobilier à {{geo_object_name}}",
          "text": "La première étape, pour vendre votre propriété, est l'estimation de celui-ci. Etape souvent difficile pour ceux qui voudront l'effectuer sans aide. En effet, tout le monde ne possède pas tous les éléments, calculs et données nécessaires pour l'estimation qui assurera une vente rapide. De plus lorsqu'il s'agit de notre bien nous ne portons pas le même regard sur celui-ci. Pour estimer précisément votre maison ou appartement la solution la plus sûre est donc de faire appel à un professionnel.\nUn notaire ou un expert immobilier sont tout à fait à même d'estimer votre bien. Mais parce qu'une bonne estimation vous permettra de le vendre plus rapidement et de limiter les négociations par les futurs acquéreurs, la Multi-Expertise ERA Immobilier, qui associe 4 outils d'estimation, vous garantit une bonne appréciation en vous donnant une fourchette de prix de vente adaptée.\nAvec ERA Immobilier vous aurez le choix d'effectuer votre évaluation directement sur notre site. En 2 minutes notre formulaire vous permet d'avoir un prix de vente pertinent de votre bien. Pour une expertise plus précise faites appel à l'agent immobilier ERA de votre ville. Il connait bien les prix du marché dans votre localité, il considèrera toutes les conditions à prendre en compte pour une vente rapide ; de plus, ce service est gratuit et sans engagement."
        },
        {
          "title": "Trouvez votre maison ou appartement idéal avec le réseau ERA Immobilier à {{geo_object_name}}",
          "text": "Une agence immobilière met en relation vendeur et acquéreur potentiel. Grâce à ses connaissances commerciales, juridiques et techniques, ainsi que son sens de la psychologie, l'agent immobilier sait se placer entre acheteur et vendeur.\nParce que c'est le premier réseau d'agences immobilières, ERA Immobilier possède un large choix de maisons et appartements mis à la vente, parmi lesquels un futur acheteur ayant un projet d'achat trouvera le logement de ses rêves.\nA la recherche d'un logement pour votre famille. ERA Immobilier vous aidera à affiner votre projet, tout en vous amenant à répondre à quelques questions :\nQuel est votre budget ?\nRecherchez-vous une maison ou un appartement ?\nDans du neuf ou de l'ancien ?\nVotre projet doit-il se situer à la ville ou à la campagne ?\nUne fois vos critères bien définis nos agents immobiliers vous accompagneront lors de la visite de biens conformes à vos choix. Ces conseillers, experts, seront présents depuis la prospection de propriétés à vendre jusqu'à la signature de l'acte de vente devant notaire."
        },
        {
          "title": "Vendez votre logement avec ERA Immobilier à {{geo_object_name}}",
          "text": "Afin de vous mettre en relation avec d'éventuels acheteurs nos agents immobiliers, experts en home staging, seront vous donner conseils pour la mise en valeur de votre bien.\nAvec près de 450 agences en France, ERA Immobilier est un réseau qui vous permet de toucher un grand nombre d'acquéreurs potentiels ; que ce soit par le biais de nos annonces en vitrines d'agences ou sur notre site internet.\nVotre appartement ou votre maison est à vendre ? Pensez à la première étape qu'il faut à tout prix effectuer : l'estimation de votre bien.\nPour cela, deux possibilités s'offrent à vous :\nFaire cette estimation en ligne en moins de 2 minutes grâce à notre outil d'estimation en ligne, votre fourchette de prix de vente vous sera définie ;\nEt pour une évaluation plus précise, n'hésitez pas à faire appel à l'une de nos agences, gratuitement et sans engagement elle saura vous apporter son expertise."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est l'intérêt de passer par une agence immobilière à {{geo_object_name}} ?",
          "content": "Les agents immobiliers ont l'habitude de gérer la relation vendeur/acquéreur, son rôle est neutre entre les deux parties et il a intérêt à ce que la transaction aboutisse.\nLes affiliés ERA Immobilier ont l'habitude de conduire des négociations entre vendeur et acheteur intéressé par un bien.\nHormis leur rôle de prospect, les spécialistes de nos agences immobilières savent organiser les visites, vérifier la solvabilité et la motivation des potentiels acquéreurs. Pour rappel ERA France propose un accompagnement administratif et juridique pour constituer un dossier auprès d'un notaire, le but étant que votre achat aboutisse sans aucun problème. D'après l'étude de l'université Paris Dauphine, 46% est le pourcentage de ventes de particulier qui n'aboutissent pas pour des raisons juridiques."
        },
        {
          "title": "Comment faire pour trouver un logement rapidement à {{geo_object_name}} ?",
          "content": "Trouver son logement idéal n'est pas toujours facile si l'on n'est pas épaulé par un professionnel de l'immobilier.\nLa recherche interminable des petites annonces correspondant à ses choix, le décryptable des avis de mise à la vente parfois déroutants, la file d'attente interminable des acheteurs potentiels à chaque visite d'appartement, ...\nLa solution pour ceux qui manquent de temps et d'expertise est l'agence immobilière. Elle agit comme un intermédiaire entre un vendeur et un acquéreur. L'agent pourra effectuer des visites pour votre compte, il vous informe régulièrement du progrès de sa mission, il vous assiste jusqu'à la signature de l'acte de vente chez le notaire."
        },
        {
          "title": "Comment choisir votre agence immobilière à {{geo_object_name}} ?",
          "content": "Pour choisir l'agence immobilière qui sera efficace pour la vente ou l'achat de votre logement, il est préférable de respecter quelques conseils :\nPréférez l'agence de votre commune ou secteur géographique,\nChoisissez l'agence immobilière spécialiste du type de votre bien ou du bien recherché\nComparez les agences immobilières susceptibles de gérer votre dossier\nPensez à vérifier l'existence de l'agence retenue\nPréférez un agent expert du type de votre bien ou du bien recherché\nEt n'oubliez pas, comme dans toute relation, la confiance est primordiale !"
        },
        {
          "title": "Qu'est-ce qu'une bonne agence immobilière à {{geo_object_name}} ?",
          "content": "Vous avez le projet de vendre votre bien ou d'acheter votre logement, faire appel à une agence immobilière sera nécessaire si vous manquez de temps pour vous en occuper vous-même. Mais il va être préférable de choisir la 'bonne' agence, pour avoir de meilleurs services et l'estimation la plus juste du bien.\nEn premiers lieux pensez au bouche-à-oreille pour vous renseigner sur la notoriété des agences de votre secteur. Les agences ERA Immobilier possèdent une importante notoriété et grande expertise ; de plus leur site internet regroupe un nombre important de biens à acheter et vous propose des outils fiables pour l'estimation de ceux que vous souhaitez vendre.\nLe professionnel qui va prendre en charge votre dossier doit être d'une bonne écoute. Nos franchisés ERA sont formés pour être à l'écoute de vos besoins.\nUne bonne agence sait proposer un grand nombre de services. ERA met à votre disposition des outils et méthodes de travail pour vous aider à vendre au meilleur prix, comme pour vous seconder dans la démarche d'acquisition de votre logement.\nLa bonne agence immobilière est tenue de communiquer ses tarifs. ERA affiche le montant de ses honoraires.\nPlus de 90% des recherches immobilières s'effectuent sur internet, il est donc primordial que votre agence immobilière y soit présente. ERA possède un site regroupant toutes les annonces de ses affiliés."
        }
      ]
    },
    "ARRONDISSEMENT": {
      "TITLE": "Agence immobilière {{geo_object_name}}",
      "SUBTITLE": "Trouvez votre agence immobilière {{geo_object_name}} sur ERA Immobilier",
      "EDITO": "Futur acquéreur, vendeur, locataire ou propriétaire, vous avez un projet d'achat immobilier ou souhaitez vendre votre bien ou le louer ? Afin d'avoir le meilleur accompagnement dans vos démarches, il est souhaitable de vous faire épauler par une agence immobilière.•\nERA, présent sur le territoire national et international, est là pour vous représenter ou vous guider dans votre recherche.",
      "BOTTOM_CONTENT": [
        {
          "title": "Trouvez votre agence immobilière ERA Immobilier proche de chez vous à {{geo_object_name}} ",
          "text": "Pour mieux définir votre projet immobilier et vos critères de sélection, il est préférable de sélectionner l'agence proche de chez vous ou de votre bien.\nERA Immobilier, présent dans 33 pays, offre un réseau de près de 450 agences implantées sur toute la France métropolitaine ; vous trouverez donc facilement l'une d'elles dans votre ville ou votre département.\nVous pensez que votre projet immobilier, que ce soit à l'achat, la vente, ou la location, mérite un bon accompagnement ? Vous pourrez facilement entrer en relation avec l'une de nos agences immobilières à proximité de chez vous, en renseignant le nom de votre commune, son code postal ou tout simplement votre département dans le moteur de recherche de notre site internet."
        },
        {
          "title": "Faites estimer votre bien immobilier par une agence ERA Immobilier à {{geo_object_name}}",
          "text": "La première étape, pour vendre votre propriété, est l'estimation de celui-ci. Etape souvent difficile pour ceux qui voudront l'effectuer sans aide. En effet, tout le monde ne possède pas tous les éléments, calculs et données nécessaires pour l'estimation qui assurera une vente rapide. De plus lorsqu'il s'agit de notre bien nous ne portons pas le même regard sur celui-ci. Pour estimer précisément votre maison ou appartement la solution la plus sûre est donc de faire appel à un professionnel.\nUn notaire ou un expert immobilier sont tout à fait à même d'estimer votre bien. Mais parce qu'une bonne estimation vous permettra de le vendre plus rapidement et de limiter les négociations par les futurs acquéreurs, la Multi-Expertise ERA Immobilier, qui associe 4 outils d'estimation, vous garantit une bonne appréciation en vous donnant une fourchette de prix de vente adaptée.\nAvec ERA Immobilier vous aurez le choix d'effectuer votre évaluation directement sur notre site. En 2 minutes notre formulaire vous permet d'avoir un prix de vente pertinent de votre bien. Pour une expertise plus précise faites appel à l'agent immobilier ERA de votre ville. Il connait bien les prix du marché dans votre localité, il considèrera toutes les conditions à prendre en compte pour une vente rapide ; de plus, ce service est gratuit et sans engagement."
        },
        {
          "title": "Trouvez votre maison ou appartement idéal avec le réseau ERA Immobilier à {{geo_object_name}}",
          "text": "Une agence immobilière met en relation vendeur et acquéreur potentiel. Grâce à ses connaissances commerciales, juridiques et techniques, ainsi que son sens de la psychologie, l'agent immobilier sait se placer entre acheteur et vendeur.\nParce que c'est le premier réseau d'agences immobilières, ERA Immobilier possède un large choix de maisons et appartements mis à la vente, parmi lesquels un futur acheteur ayant un projet d'achat trouvera le logement de ses rêves.\nA la recherche d'un logement pour votre famille. ERA Immobilier vous aidera à affiner votre projet, tout en vous amenant à répondre à quelques questions :\nQuel est votre budget ?\nRecherchez-vous une maison ou un appartement ?\nDans du neuf ou de l'ancien ?\nVotre projet doit-il se situer à la ville ou à la campagne ?\nUne fois vos critères bien définis nos agents immobiliers vous accompagneront lors de la visite de biens conformes à vos choix. Ces conseillers, experts, seront présents depuis la prospection de propriétés à vendre jusqu'à la signature de l'acte de vente devant notaire."
        },
        {
          "title": "Vendez votre logement avec ERA Immobilier à {{geo_object_name}}",
          "text": "Afin de vous mettre en relation avec d'éventuels acheteurs nos agents immobiliers, experts en home staging, seront vous donner conseils pour la mise en valeur de votre bien.\nAvec près de 450 agences en France, ERA Immobilier est un réseau qui vous permet de toucher un grand nombre d'acquéreurs potentiels ; que ce soit par le biais de nos annonces en vitrines d'agences ou sur notre site internet.\nVotre appartement ou votre maison est à vendre ? Pensez à la première étape qu'il faut à tout prix effectuer : l'estimation de votre bien.\nPour cela, deux possibilités s'offrent à vous :\nFaire cette estimation en ligne en moins de 2 minutes grâce à notre outil d'estimation en ligne, votre fourchette de prix de vente vous sera définie ;\nEt pour une évaluation plus précise, n'hésitez pas à faire appel à l'une de nos agences, gratuitement et sans engagement elle saura vous apporter son expertise."
        }
      ],
      "FAQ": [
        {
          "title": "Quel est l'intérêt de passer par une agence immobilière à {{geo_object_name}} ?",
          "content": "Les agents immobiliers ont l'habitude de gérer la relation vendeur/acquéreur, son rôle est neutre entre les deux parties et il a intérêt à ce que la transaction aboutisse.\nLes affiliés ERA Immobilier ont l'habitude de conduire des négociations entre vendeur et acheteur intéressé par un bien.\nHormis leur rôle de prospect, les spécialistes de nos agences immobilières savent organiser les visites, vérifier la solvabilité et la motivation des potentiels acquéreurs. Pour rappel ERA France propose un accompagnement administratif et juridique pour constituer un dossier auprès d'un notaire, le but étant que votre achat aboutisse sans aucun problème. D'après l'étude de l'université Paris Dauphine, 46% est le pourcentage de ventes de particulier qui n'aboutissent pas pour des raisons juridiques."
        },
        {
          "title": "Comment faire pour trouver un logement rapidement à {{geo_object_name}} ?",
          "content": "Trouver son logement idéal n'est pas toujours facile si l'on n'est pas épaulé par un professionnel de l'immobilier.\nLa recherche interminable des petites annonces correspondant à ses choix, le décryptable des avis de mise à la vente parfois déroutants, la file d'attente interminable des acheteurs potentiels à chaque visite d'appartement, ...\nLa solution pour ceux qui manquent de temps et d'expertise est l'agence immobilière. Elle agit comme un intermédiaire entre un vendeur et un acquéreur. L'agent pourra effectuer des visites pour votre compte, il vous informe régulièrement du progrès de sa mission, il vous assiste jusqu'à la signature de l'acte de vente chez le notaire."
        },
        {
          "title": "Comment choisir votre agence immobilière à {{geo_object_name}} ?",
          "content": "Pour choisir l'agence immobilière qui sera efficace pour la vente ou l'achat de votre logement, il est préférable de respecter quelques conseils :\nPréférez l'agence de votre commune ou secteur géographique,\nChoisissez l'agence immobilière spécialiste du type de votre bien ou du bien recherché\nComparez les agences immobilières susceptibles de gérer votre dossier\nPensez à vérifier l'existence de l'agence retenue\nPréférez un agent expert du type de votre bien ou du bien recherché\nEt n'oubliez pas, comme dans toute relation, la confiance est primordiale !"
        },
        {
          "title": "Qu'est-ce qu'une bonne agence immobilière à {{geo_object_name}} ?",
          "content": "Vous avez le projet de vendre votre bien ou d'acheter votre logement, faire appel à une agence immobilière sera nécessaire si vous manquez de temps pour vous en occuper vous-même. Mais il va être préférable de choisir la 'bonne' agence, pour avoir de meilleurs services et l'estimation la plus juste du bien.\nEn premiers lieux pensez au bouche-à-oreille pour vous renseigner sur la notoriété des agences de votre secteur. Les agences ERA Immobilier possèdent une importante notoriété et grande expertise ; de plus leur site internet regroupe un nombre important de biens à acheter et vous propose des outils fiables pour l'estimation de ceux que vous souhaitez vendre.\nLe professionnel qui va prendre en charge votre dossier doit être d'une bonne écoute. Nos franchisés ERA sont formés pour être à l'écoute de vos besoins.\nUne bonne agence sait proposer un grand nombre de services. ERA met à votre disposition des outils et méthodes de travail pour vous aider à vendre au meilleur prix, comme pour vous seconder dans la démarche d'acquisition de votre logement.\nLa bonne agence immobilière est tenue de communiquer ses tarifs. ERA affiche le montant de ses honoraires.\nPlus de 90% des recherches immobilières s'effectuent sur internet, il est donc primordial que votre agence immobilière y soit présente. ERA possède un site regroupant toutes les annonces de ses affiliés."
        }
      ]
    }
  }
}
