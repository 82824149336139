import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { combineLatest, Subscription } from 'rxjs';

import { Actualite, Source, ActualiteTag, Agence, AgenceGroupe } from '@/models';
import { RouteObserverService } from '@/services/route-observer.service';
import { BlogImmobilierService } from '@/services/blog.service';
import { MetadataService } from '@/services/metadata.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';
import { ToolsService } from '@/services/tools.service';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BlogComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private source: Source = 'SOURCE.ERA';
  private eraAgenceIds: number[] = [];
  private url_front: string;
  private isGroupe = false;
  private fragment?: string;

  public loading = false;
  public actualites: Actualite[] = [];
  public itemsPerPage = 15;
  public currentPage = 1;
  public totalItems = 0;
  public actualitesTags: ActualiteTag[] = [];
  public actualitesTagsSelected: number[] = [];
  public agence?: Agence | AgenceGroupe;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private routeObserver: RouteObserverService,
    private blogService: BlogImmobilierService,
    private agencesService: AgencesService,
    private toolsService: ToolsService,
    private route: ActivatedRoute,
    private meta: MetadataService,
    private cd: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    configService: ConfigService
  ) {
    this.url_front = configService.config.url_front;
  }

  ngOnInit(): void {
    this.updateSeo();
    this.actualitesTags = this.route.snapshot.data['actualitesTags'] ?? [];

    this.subscriptions.add(
      combineLatest([
        this.agencesService.agence$,
        this.routeObserver.currentRoute$
      ]).subscribe(([agence, route]) => {
        if (agence) {
          this.agence = agence.data;
          this.source = 'SOURCE.AGENCE';
          this.isGroupe = agence.type === 'groupe';
          this.eraAgenceIds = this.isGroupe ? (this.agence as AgenceGroupe).agences.map((a) => a.era_id) : [];
        }
        this.fragment = route.fragment;
        this.getData(route.queryParams);
      })
    );

    const pathWithoutHash = this.location.path(false);
    this.location.replaceState(pathWithoutHash);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goBack(): void {
    this.location.back();
  }

  toggleTag(id: number): void {
    const indexTag = this.actualitesTagsSelected.indexOf(id);

    if (indexTag < 0) {
      this.actualitesTagsSelected.push(id);
    } else {
      this.actualitesTagsSelected.splice(indexTag, 1);
    }

    const tags = this.actualitesTagsSelected.toString();
    const params: any = { page: '1' };

    if (tags) {
      params.tags = tags;
    }

    const { route, queryParams } = this.toolsService.updateSearchParams(this.document, params);
    this.router.navigate([route], { queryParams });
  }

  getArticleDetails({ article, urlToShare }: { article: Actualite, urlToShare: string }): void {
    const { id, titre, accroche, image } = article;

    this.meta.setMetadata({
      title: titre,
      description: accroche,
      ogTitle: titre,
      ogDescription: accroche,
      ogUrl: `${this.url_front}${MainRoutes.Blog}/article/${id}`,
      ogImage: image,
      url: urlToShare,
      singleArticle: true
    });

    window.open(urlToShare, '_blank');
  }

  private getData(params: Record<string, string>): void {
    const tags = params['tags'] ? params['tags'].split(',').map((t) => +t) : [];
    const page = params['page'] ? +params['page'] : 1;

    if (tags.length) {
      this.actualitesTagsSelected = tags;
    } else if (this.fragment === 'gestion locative') {
      this.actualitesTagsSelected = [4];
    } else if (this.fragment === 'gestion de syndic') {
      this.actualitesTagsSelected = [5];
    }

    this.getActualites(page, this.actualitesTagsSelected.toString());
  }

  private getActualites(page = 1, tags = ''): void {
    let ids: string;

    if (this.eraAgenceIds.length > 0) {
      ids = this.eraAgenceIds.toString();
    }	else if (this.agence) {
      ids = this.agence.era_id.toString();
    }	else {
      ids = '';
    }

    this.currentPage = page;
    this.loading = true;

    this.subscriptions.add(
      this.blogService.getActualites(
        this.itemsPerPage, this.currentPage, 'desc', 'created_at', this.source, tags, ids
      ).subscribe(({ data, meta: { total } }) => {

        this.actualites = data;
        this.totalItems = total || 0;
        this.loading = false;

        this.cd.markForCheck();
      })
    );
  }

  private updateSeo(): void {
    const { data, url } = this.routeObserver.currentRoute;
    const { title, description } = data;

    // SEO
    this.meta.setMetadata({
      title: title,
      description: description,
      ogTitle: title,
      ogDescription: description,
      ogUrl: `${this.url_front}${url}`
    });
  }
}
