import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, filter, Observable, of, take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { Environment } from '@/models';
import { APP_CONFIGURATION } from '@/utilities/app-configuration.token';
import dataConfig from '../../assets/config/config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config$ = new BehaviorSubject<Environment>(dataConfig);
  private _isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIGURATION) @Optional() private url: string
  ) {}

  loadConfig(): Observable<Environment> {
    this._isBrowser = isPlatformBrowser(this.platformId);
    return of(dataConfig);
  }

  get config$(): Observable<Environment> {
    return this._config$.asObservable().pipe(
      filter((config) => !!config),
      take(1)
    );
  }

  get config(): Environment {
    return this._config$.value!;
  }

  get isBrowser(): boolean {
    return this._isBrowser;
  }

  get isServer(): boolean {
    return !this._isBrowser;
  }
}
