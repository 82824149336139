@use "styles/constants";

app-contact-form .contact-form {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  color: var(--color-grey-super-dark);

  &.agenceheader {
    @media (max-width: constants.$bp-tablet) {
      margin-top: 30px;
    }
  }

  .header {
    padding: 0 20px;

    .icon-close {
      margin: 0;
    }
  }

  ::-webkit-scrollbar {
    height: 5px;
  }

  .agences {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    padding-bottom: 1rem;
    margin-bottom: 4rem;

    app-agence-mini-card {
      display: inline-block;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;

    &-contact {
      max-width: 1206px;
      display: flex;
      justify-content: center;
      margin: 0 80px 80px 80px;
      overflow: hidden;
      gap: 2rem;

      @media (max-width: constants.$bp-tablet) {
        padding: 20px;
        margin: 20px;
        flex-wrap: wrap;
      }

      &-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .negociator {
          background-color: var(--color-blue-light);
          border-radius: constants.$radius;
          padding: 15px 15px 0 15px;

          @media (max-width: constants.$bp-tablet) {
            display: none;
          }

          .image-container {
            width: 60px;

            img {
              border-radius: 50%;
            }
          }

          .display-text-18px {
            font-weight: 600;
          }

          @media (max-width: constants.$bp-tablet) {
            margin-top: 40px;
          }
        }

        .negociator-buttons {
          gap: 15px;

          button.btn {
            width: 50%;

            @media (max-width: constants.$bp-phone) {
              width: 100%;
            }

            &.btn-arrow-white {
              margin-right: 10px;

              @media (max-width: constants.$bp-phone) {
                margin-right: 0;
              }
            }

            &.btn-arrow-red-dark {
              @media (max-width: constants.$bp-phone) {
                margin-bottom: 30px;
              }
            }
          }
        }

        .contactContent {
          background: var(--color-blue-light);
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: constants.$radius;
          width: 100%;
          padding: 15px;
          font-weight: 600;
          line-height: 19px;

          .contact {
            display: flex;
            position: relative;
            justify-content: space-around;
            align-items: center;
            border-radius: constants.$radius;
            height: 48px;
            width: 100%;
            cursor: pointer;
            color: var(--color-blue-dark);
          }

          .btn-phone-blue-border {
            width: 100%;
            min-width: initial;
          }
        }

        &.agence {
          padding: 30px;
          box-shadow: 0px 0px 20px 0px var(--box-shadow);
          border-radius: constants.$radius;
          width: 467px;
          height: fit-content;

          @media (max-width: constants.$bp-tablet) {
            margin-right: 0;
            margin-bottom: 45px;
            width: 100%;
          }
        }

        .btn {
          @media (max-width: constants.$bp-phone) {
            width: 100%;
          }

          &.btn-arrow-white {
            border: 1px solid var(--color-grey-su-super-dark);

            @media (max-width: constants.$bp-phone) {
              margin-bottom: 20px;
            }
          }
        }

        &-form {
          max-width: 689px;
          overflow: hidden;

          .input-geoloc {
            bottom: initial;
          }

          &-fullname {
            @media (max-width: constants.$bp-tablet) {
              display: block;
              width: 100%;
            }

            .name {
              flex: 1;

              @media (max-width: constants.$bp-tablet) {
                margin-right: 0;
                margin-bottom: 40px;
              }
            }
          }

          .phone {
            width: 300px;
          }

          .input-label label.select-origine_contact {
            left: -21px;
          }

          .input-label label.select-job-type {
            left: -4px;
          }

          &-error {
            position: absolute;
            bottom: 0;
            width: 100%;

            p {
              position: absolute;
              font-size: 12px;
              line-height: 24px;
              color: var(--color-red-medium);
              background-color: transparent;
              left: 0%;
            }
          }

          .message {
            position: relative;
            border: 2px solid var(--color-blue-extra-light);
            padding: 16px 20px;
            width: 100%;
            border-radius: constants.$radius;

            &:focus {
              outline: none;
              border: 2px solid var(--color-blue-extra-light);
            }
          }

          &-nearestAgency {
            display: flex;
            position: relative;
            overflow: hidden;
            margin-bottom: 40px;

            app-carousel {
              display: grid;

              .gallery {
                overflow-x: auto;
                padding: 1rem;
                display: flex;
              }
            }

            &-container {
              width: 388px;
              height: 360px;
              left: 0px;
              top: 0px;
              border-radius: constants.$radius;
              padding: 40px;
              box-shadow: 0px 0px 20px 0px var(--box-shadow);
              background: var(--white);
              margin-right: 30px;
              cursor: pointer;
              position: relative;
              margin-bottom: 30px;
              display: flex;
              flex-direction: column;

              .display-text-18px {
                font-weight: 900;
              }

              .button {
                .btn-phone-blue-border {
                  padding: 0;
                }

                &.selected {
                  visibility: hidden;
                }
              }

              &.active {
                color: var(--white);
                background: var(--color-blue-dark);

                .button {
                  .btn-phone-blue-border {
                    color: var(--white);
                    border-color: var(--white);
                    padding: 0;
                  }
                }
              }

              &-selected {
                height: 48px;
                width: 308px;
                left: 40px;
                top: 233px;
                border-radius: constants.$radius;
                padding: 0px, 37px, 0px, 37px;
                background: var(--color-blue-dark);
                color: var(--white);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .title {
                font-weight: 600;
                line-height: 21.5px;
                padding-bottom: 10px;
              }

              .address-container {
                padding-bottom: 10px;

                .display-text-16px {
                  line-height: 32px;
                }
              }
            }
          }

          .input-toggle {
            @media (max-width: constants.$bp-tablet) {
              flex-direction: column;
              align-items: flex-start;
            }

            p {
              @media (max-width: constants.$bp-tablet) {
                margin-bottom: 30px;
              }
            }

            &-items {
              p {
                @media (max-width: constants.$bp-tablet) {
                  margin: 0;
                }
              }
            }
          }

          .wrapper-button {
            @media (max-width: constants.$bp-tablet) {
              position: fixed;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              z-index: 200;
              background-color: var(--white);
              padding: 20px;
            }

            &-submit {
              @media (max-width: constants.$bp-tablet) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .block-contact-agency-presentation-address {
    padding-bottom: 10px;
  }

  .block-contact-agency-asterisque {
    @media (max-width: constants.$bp-tablet) {
      padding-bottom: 150px;
    }
  }
}
