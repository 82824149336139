import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, OnInit, input, output, signal } from '@angular/core';
import { Subscription } from 'rxjs';

import { HeaderService } from '@/services/header.service';
import { StorageKey, StorageService } from '@/services/storage.service';
import { RouteObserverService } from '@/services/route-observer.service';
import { AuthService } from '@/services/auth.service';
import { Agence, AgenceGroupe, Annonce, AnnonceLight, ConnectedUser } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-annonce-card',
  templateUrl: './annonce-card.component.html',
  styleUrls: ['./annonce-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AnnonceCardComponent implements OnChanges, OnDestroy, OnInit {
  readonly annonce = input.required<Annonce | AnnonceLight>();
  readonly listAnnonces = input(false);
  readonly groupe = input<AgenceGroupe>();
  readonly agence = input<Agence>();
  readonly profile = input(false);

  readonly hidePropertyButtonClickEmt = output<number>();
  readonly showTab = output<number>();

  private subscriptions = new Subscription();
  private imagesList: string[] = [];

  public MainRoutes = MainRoutes;

  public sold = false;
  public emptyHeart = true;
  public activeImageIndex = 0;
  public alreadySeen = false;
  public annonceLink!: string;
  public imagesCount = 0;
  public imageUrl = signal('');
  public typeBien?: string;
  public connectedUser: ConnectedUser;

  constructor(
    private routeObserver: RouteObserverService,
    private storageService: StorageService,
    private headerService: HeaderService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {
    this.sold = !!this.routeObserver.currentRoute.url?.endsWith('/vendu');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.connectedUser$.subscribe((user) => {
        this.connectedUser = user;
        this.cd.markForCheck();
      })
    );
  }

  ngOnChanges(): void {
    const annonce = this.annonce();

    if (!this.profile()) {
      const annoncesListSeen: number[] = this.storageService.getObject(StorageKey.AlreadySeen) ?? [];
      this.alreadySeen = annoncesListSeen.indexOf(annonce.id) > -1;
    }

    const groupe = this.groupe();
    const agence = this.agence();
    if (groupe) {
      this.annonceLink = `${MainRoutes.Groupes}/${groupe.slug}${MainRoutes.Annonce}/${annonce.id}`;
    } else if (agence) {
      this.annonceLink = `/${agence.slug}${MainRoutes.Annonce}/${annonce.id}`;
    } else {
      this.annonceLink = `${MainRoutes.Annonce}/${annonce.id}`;
    }

    if (annonce) {
      if (this.listAnnonces()) {
        this.imagesList = annonce.photo;
      } else {
        this.imagesList = annonce.images?.little ?? annonce.photo;
      }

      this.imagesCount = this.imagesList.length;
      this.imageUrl.set(this.imagesList[this.activeImageIndex]);

      this.typeBien = annonce.type_bien.toLowerCase();
    }

    this.emptyHeart = !(annonce as Annonce)['is_favorite'];
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  removeFromfavoris(ev: Event): void {
    ev.stopPropagation();

    if (this.connectedUser) {
      this.authService.removeFavorisFromUserAccount(this.annonce().id).subscribe(() => {
        this.emptyHeart = !this.emptyHeart;
        this.cd.markForCheck();
      });
    } else {
      this.headerService.showNeedConnectModal();
    }
  }

  addToFavoris(ev: Event): void {
    ev.stopPropagation();

    if (this.connectedUser) {
      this.authService.addFavorisToUserAccount(this.annonce().id).subscribe(() => {
        this.emptyHeart = !this.emptyHeart;
        this.cd.markForCheck();
      });
    } else {
      this.headerService.showNeedConnectModal();
    }
  }

  hideProperty(): void {
    this.hidePropertyButtonClickEmt.emit(this.annonce().id);
  }

  showTabAnnonce(): void {
    this.showTab.emit(this.annonce().id);
  }

  previousImage(): void {
    this.activeImageIndex = this.activeImageIndex > 0 ? this.activeImageIndex - 1 : this.imagesList.length - 1;
    this.imageUrl.set(this.imagesList[this.activeImageIndex]);
  }

  nextImage(): void {
    this.activeImageIndex = this.activeImageIndex < this.imagesList.length - 1 ? this.activeImageIndex + 1 : 0;
    this.imageUrl.set(this.imagesList[this.activeImageIndex]);
  }

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/erabw.png';
  }
}
