import { Coordinates } from './geoloc.model';

export type ListDisplayMode = 'list' | 'map';

export const DEFAULT_DISPLAY_MODE: ListDisplayMode = 'list';

export type FilterTypeVendeur = 'particulier' | 'professionnel';
export type FilterTypeAnnonce = 'vente' | 'location' | 'estimer';
export type FilterTypeAction = 'acheter' | 'louer';

export type SavedSearchType = 'vente' | 'location';
export type SearchMode = 'agences' | 'annonces';

export type SearchFilters = {
  type_annonce?: FilterTypeAnnonce;
  type?: FilterTypeAction;
  exclusif?: boolean;
  coup_coeur?: boolean;
  geoVillesIds?: number[];
  geoDepartementsIds?: number[];
  geoRegionsIds?: number[];
  polygon?: string;
  distance?: string;
  location?: string;
  typeBien?: string[];
  prix_from?: string;
  prix_to?: string;
  surface_from?: string;
  surface_to?: string;
  terrain_from?: string;
  terrain_to?: string;
  nb_pieces?: string;
  nb_chambres?: string;
  shouldTriggerLoad?: boolean;
  criteres?: string[];
  agence_id?: number;
  era_groupe_id?: number;
  fields?: string;
  searchLocations?: SearchLocation[];
  page?: number;
  per_page?: number;
  display?: ListDisplayMode;
};

export type SearchLocationType = 'region' | 'departement' | 'ville' | 'adresse';

export type SearchLocation = {
  // id technique (region, departement ou ville)
  id: number | string;
  // 'region', 'departement', 'ville' ou 'adresse'
  type: SearchLocationType;
  // nom (région ou département ou ville) ou adresse complète
  nom: string;
  // code (région / département) ou code postal (ville / adresse)
  code: string;
  // bounding box (région / département) [lng1, lat1, lng2, lat2]
  bbox?: [number, number, number, number];
  // slug (région, département ou ville)
  slug?: string;
  // geoloc (ville / adresse)
  geoloc?: Coordinates<number>;
  // adresse dans le cadre d'un autocompmlete adresse
  adresse?: string;
  // ville dans le cadre d'un autocompmlete adresse
  ville?: string;
};

export type SearchGeoAutoCompletion = {
  departements?: SearchLocation[];
  villes?: SearchLocation[];
  adresses?: SearchLocation[];
};
