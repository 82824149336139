app-carousel {
  display: inline-table;
  position: relative;
  width: 100%;

  .gallery {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.lightbox {
      width: 90vw;
      height: 80vh;
    }
  }

  .dots {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background-color: var(--color-grey-medium);
      transition: all ease-in-out 300ms;

      &.active {
        background-color: var(--white);
      }
    }
  }

  app-button-circle {
    position: absolute;
    top: 50%;
    z-index: 99;

    @media (max-width: 900px) {
      background-color: transparent !important;
    }

    &:first-of-type {
      left: -45px;

      @media (max-width: 900px) {
        left: 20px;
      }
    }

    &:last-of-type {
      right: -45px;

      @media (max-width: 900px) {
        right: 20px;
      }
    }
  }
}
