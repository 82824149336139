import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges, forwardRef, input, output } from '@angular/core';

import { Agence, AgenceGroupe } from '@/models';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-agence-mini-card',
  templateUrl: './agence-mini-card.component.html',
  styleUrl: './agence-mini-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgenceMiniCardComponent),
      multi: true
    }
  ],
  standalone: false
})
export class AgenceMiniCardComponent implements OnInit, OnChanges, ControlValueAccessor
{
  readonly selectedAgenceId = input.required<number | undefined>();
  readonly agence = input.required<Agence>();

  readonly cardClicked = output<number>();

  private agenceId!: number;
  public selected = false;
  public agence_id = new FormControl<number>(
    0,
    [Validators.required, Validators.min(1)]
  );
  private onChange: any = () => {};
  private onTouched: any = () => {};

  ngOnInit(): void {
    const agence = this.agence();
    this.agenceId = (agence as AgenceGroupe).era_agence_principale_id || agence.era_id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if another agency has been selected
    if (changes['selectedAgenceId'] && this.selectedAgenceId()) {
      this.selected = this.agenceId === this.selectedAgenceId();
    }
  }

  handleAgenceSelect() {
    this.selected = true;
    this.cardClicked.emit(this.agenceId);
    this.onChange(this.agenceId);
    this.onTouched(this.agenceId);
  }

  writeValue(value: number): void {
    this.agence_id.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
