import {
  afterNextRender, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  OnDestroy, OnInit, input, viewChildren
} from '@angular/core';

import { debounceTime, distinctUntilChanged, filter, Subscription, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { StorageKey, StorageService } from '@/services/storage.service';
import { GeographyService } from '@/services/geography.service';
import { FilterService } from '@/services/filter.service';
import { GeolocService } from '@/services/geoloc.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes, SEARCH_DEBOUNCE, SEARCH_MIN_LENGTH } from '@/constants';

import {
  Agence, AgenceGroupe, ConnectedUser, Recherche, SearchFilters, SearchGeoAutoCompletion, SearchLocation, SearchMode
} from '@/models';

import { SearchBarSuggestionComponent } from './search-bar-suggestion/search-bar-suggestion.component';

export type SearchType = 'estimer' | 'location' | 'vente';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchBarComponent implements OnInit, OnDestroy {
  readonly suggestions = viewChildren(SearchBarSuggestionComponent);

  readonly agenceGroup = input<AgenceGroupe>();
  readonly agence = input<Agence>();

  private subscriptions = new Subscription();
  private currentId?: number;
  private geoCoder?: string;

  public showDropdown = false;
  public searchType: SearchType = 'estimer';
  public searchByDrawRoute?: string;
  public autocompleteCities: SearchLocation[] = [];
  public autocompleteDepartments: SearchLocation[] = [];
  public autocompleteAddresses: SearchLocation[] = [];
  public connectedUser?: ConnectedUser;
  public savedSearchTypeSell: Recherche[] = [];
  public savedSearchTypeRent: Recherche[] = [];
  public searchInput = new FormControl('', { nonNullable: true });

  constructor(
    private geolocService: GeolocService,
    private filterService: FilterService,
    private storageService: StorageService,
    private geographyService: GeographyService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    afterNextRender(() => {
      this.changeSearchType((window.innerWidth < 820) ? 'vente' : 'estimer');
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.connectedUser$.subscribe((user) => {
        this.connectedUser = user;
        this.setSavedSearches();
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.searchInput.valueChanges.pipe(
        filter((value) => this.canAutoComplete(value)),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((value) => (
          this.geographyService.getAutocompletion(value, this.searchType === 'estimer' ? 'address' : 'where')
        ))
      ).subscribe((suggestions) => {
        this.processReceivedLocations(suggestions);
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Process submit button click
   */
  onSubmitButton(): void {
    const params: Record<string, any> = {};
    const agenceGroup = this.agenceGroup();
    const agence = this.agence();

    if (agence) {
      params['queryParams'] = { agence_id: agence.id };
    } else if (agenceGroup) {
      params['queryParams'] = { groupe_id: agenceGroup.id };
    }

    if (this.searchType === 'estimer') {
      if (this.geoCoder) {
        this.geolocService.updateGeoCoder(this.geoCoder);
        this.close();
      }
      this.router.navigate([MainRoutes.Estimer], params);
    } else if (this.searchType === 'vente') {
      this.router.navigate([MainRoutes.Acheter], params);
    } else if (this.searchType === 'location') {
      this.router.navigate([MainRoutes.Louer], params);
    }
  }

  /**
   * Close the suggestions panel
   */
  close(): void {
    if (this.showDropdown) {
      this.autocompleteDepartments = [];
      this.autocompleteAddresses = [];
      this.autocompleteCities = [];
      this.showDropdown = false;
    }
  }

  /**
   * Change active tab
   * @param type The tab type
   */
  changeSearchType(type: SearchType): void {
    this.searchByDrawRoute = (type === 'vente') ? MainRoutes.Acheter : MainRoutes.Louer;
    this.searchType = type;
    this.resetInput();
    this.close();
  }

  /**
   * Handle keyboad keys when suggestions dropdown is opened.
   * @param event The keyboad event
   */
  handleKeydown(event: any): void {
    const count = this.suggestions().length;

    if (count > 0) {
      if (event.code === 'Enter') {
        const el = this.suggestions().at(this.currentId || 0);
        if (el) {
          this.selectLocation(el.location());
        }
        return;
      }

      let position = this.currentId || 0;

      if (event.code === 'ArrowDown') {
        if (++position >= count) {
          position = 0;
        }
      } else if (event.code === 'ArrowUp') {
        if (--position < 0) {
          position = count - 1;
        }
      }

      this.selectSuggestion(position);
    }
  }

  lastSearchTextFromParams(type: SearchType): string[] {
    const lastSearch = this.getLastSearch(type);
    const lastSearchText: string[] = [];

    if (lastSearch) {
      let surfaceText = '';

      const locations = lastSearch.searchLocations?.map((location) => location.nom).join(',') ?? '';
      if (locations) {
        lastSearchText.push(locations);
      }

      if (lastSearch.typeBien?.length) {
        lastSearchText.push(lastSearch.typeBien.join(','));
      }

      if (lastSearch.nb_pieces) {
        lastSearchText.push(lastSearch.nb_pieces + ' pièces');
      }

      if (lastSearch.surface_from && lastSearch.surface_to) {
        surfaceText = `${lastSearch.surface_from} - ${lastSearch.surface_to}`;
      } else if (lastSearch.surface_from) {
        surfaceText = lastSearch.surface_from;
      } else if (lastSearch.surface_to) {
        surfaceText = lastSearch.surface_to;
      }

      if (surfaceText) {
        lastSearchText.push(surfaceText + ' m2');
      }
    }

    return lastSearchText;
  }

  goToLastSearch(type: SearchType): void {
    // Update params and set last_search, redirect
    const lastSearch = this.getLastSearch(type);
    this.filterService.updateFilters('annonces', lastSearch);
    this.filterService.buildRouteFromFilters({ mode: 'annonces' });
  }

  searchSavedSearches(searchTerm: Recherche): void {
    this.filterService.resetFilters();

    this.filterService.updateFilters('annonces', this.filterService.rechercheToFilters(searchTerm));
    this.filterService.buildRouteFromFilters({ mode: 'annonces' });
  }

  /**
   * Geolocalize user and route to annonces pages
   */
  handleAskForGeoloc(): void {
    this.subscriptions.add(
      this.geolocService.getSearchLocationFromPosition().subscribe((searchLocation) => {
        if (searchLocation) {
          this.geolocService.updateGeolocVille(searchLocation);
          this.filterService.updateFilters('annonces', { searchLocations: [searchLocation], type_annonce: this.searchType });
          this.filterService.updateFilters('agences', { searchLocations: [searchLocation] });
        }

        const agenceGroup = this.agenceGroup();
        const agence = this.agence();

        if (agenceGroup) {
          this.filterService.updateFilters('annonces', { era_groupe_id: agenceGroup.era_agence_principale_id });
        } else if (agence) {
          this.filterService.updateFilters('annonces', { agence_id: agence.id });
        }

        this.filterService.buildRouteFromFilters({ mode: 'annonces' });
      })
    );
  }

  /**
   * Select a suggested location item
   * @param locationItem The selected item
   */
  selectLocation(locationItem: SearchLocation): void {
    const mode: SearchMode = (this.searchType === 'estimer') ? 'agences' : 'annonces';

    this.filterService.updateFilters(mode, {
      searchLocations: [locationItem],
      type_annonce: this.searchType
    });

    const agenceGroup = this.agenceGroup();
    const agence = this.agence();

    if (agenceGroup) {
      this.filterService.updateFilters(mode, { era_groupe_id: agenceGroup.era_agence_principale_id });
    } else if (agence) {
      this.filterService.updateFilters(mode, { agence_id: agence.id });
    }

    this.close();
    this.resetInput();

    this.filterService.buildRouteFromFilters({ mode });
  }

  /**
   * Select a suggested address item. Save the selected address into the vendreService so that
   * EstimationComponent could use it and skip the address selection step.
   * @param location
   */
  selectAddress(location: SearchLocation): void {
    const { lat, lng } = location.geoloc!;

    this.geolocService.updateNearestAgency(`${lng}%2C${lat}`, lng, lat);
    this.geolocService.saveAddress(location);

    this.geoCoder = location.nom;
    this.autocompleteAddresses = [];
    this.showDropdown = false;

    this.onSubmitButton();
  }

  private getLastSearch(type: SearchType): SearchFilters {
    const filters: any = this.storageService.getObject<SearchFilters>(StorageKey.LastSearch);
    return filters ? filters[type] : undefined;
  }

  /**
   * Test if autocompletion can be requested
   * @param query The current input content
   * @returns true when enough characters has been entered, else false
   */
  private canAutoComplete = (query = ''): boolean => {
    if (query.length < SEARCH_MIN_LENGTH) {
      this.close();
      return false;
    }
    return true;
  };

  /**
   * Process the received suggestions
   * @param data
   */
  private processReceivedLocations(suggestions: SearchGeoAutoCompletion): void {
    const { villes, departements, adresses } = suggestions;

    this.autocompleteDepartments = departements ?? [];
    this.autocompleteAddresses = adresses ?? [];
    this.autocompleteCities = villes ?? [];

    this.showDropdown = true;

    // Let redraw list before selecting first element
    setTimeout(() => this.selectSuggestion(0), 0);
  }

  /**
   * Build saved search lists (one for sells and another for rents)
   */
  private setSavedSearches(): void {
    if (this.connectedUser) {
      const getCity = (recherche: Recherche) => (
        recherche.searchLocations?.length ? recherche.searchLocations.map((item) => item.nom).join(', ') : 'France'
      );

      this.savedSearchTypeSell = this.connectedUser.recherches.filter(
        (recherche) => recherche.type_annonce === 'vente'
      ).map(
        (recherche) => ({ ...recherche, city: getCity(recherche) })
      );

      this.savedSearchTypeRent = this.connectedUser.recherches.filter(
        (recherche) => recherche.type_annonce === 'location'
      ).map(
        (recherche) => ({ ...recherche, city: getCity(recherche) })
      );

    } else {
      this.savedSearchTypeSell = [];
      this.savedSearchTypeRent = [];
    }
  }

  /**
   * Reset input field
   */
  private resetInput(): void {
    this.searchInput.setValue('');
    this.geoCoder = '';
  }

  /**
   * Select the suggestion at the given index
   * @param index The suggestion position
   */
  private selectSuggestion(index: number): void {
    if (this.currentId) {
      const el = this.suggestions().at(this.currentId);
      if (el) {
        el.select(false);
      }
    }

    const el = this.suggestions().at(index);
    if (el) {
      el.select(true);
    }

    this.currentId = index;
  }
}
