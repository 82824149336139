@use "styles/forms";

app-form-input-telephone {
  position: relative;
  display: block;
  margin-bottom: 2rem;

  .phone-field {
    @include forms.form-field();

    label {
      left: 110px;
      top: 14px;

      &:not(.transformed) {
        transform: scale(0.7) translate(-220px, -52px);
      }
    }
  }
}
