import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MainHeaderState, HeaderType, HeaderModalType, Annonce } from '@/models';
import { UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _headerState = new BehaviorSubject<MainHeaderState>({
    disableAlertButton: false,
    currentAnnonce: undefined,
    redirectUrl: undefined,
    headerType: 'main',
    showSearch: false,
    lowIndex: false,
    modal: 'none'
  });

  get headerState$(): Observable<MainHeaderState> {
    return this._headerState.asObservable();
  }

  get headerState(): MainHeaderState {
    return this._headerState.value;
  }

  setHeaderType(type: HeaderType): void {
    if (this._headerState.value.headerType !== type) {
      this._headerState.next({
        ...this._headerState.value,
        headerType: type
      });
    }
  }

  setLowIndex(low: boolean): void {
    if (this._headerState.value.lowIndex !== low) {
      this._headerState.next({
        ...this._headerState.value,
        lowIndex: low
      });
    }
  }

  showSearchBar(show: boolean): void {
    if (this._headerState.value.showSearch !== show) {
      this._headerState.next({
        ...this._headerState.value,
        showSearch: show
      });
    }
  }

  showNeedConnectModal(show = true): void {
    const modal: HeaderModalType = show ? 'connect' : 'none';

    if (this._headerState.value.modal !== modal) {
      this._headerState.next({
        ...this._headerState.value,
        modal
      });
    }
  }

  showLoginModal(show = true, redirectUrl?: UrlTree): void {
    const modal: HeaderModalType = show ? 'login' : 'none';

    if (this._headerState.value.modal !== modal) {
      this._headerState.next({
        ...this._headerState.value,
        redirectUrl,
        modal
      });
    }
  }

  disableAlertButton(disable: boolean): void {
    if (this._headerState.value.disableAlertButton !== disable) {
      this._headerState.next({
        ...this._headerState.value,
        disableAlertButton: disable
      });
    }
  }

  setCurrentItemsCount(count: number): void {
    this._headerState.next({
      ...this._headerState.value,
      currentItemsCount: count
    });
  }

  // TODO-EL: A enlever, visiblement ne sert nulle part
  setActiveAnnonce(annonce?: Annonce): void {
    this._headerState.next({
      ...this._headerState.value,
      currentAnnonce: annonce
    });
  }
}
