app-tabs {
  .tabs {
    display: flex;

    > div {
      box-shadow: 0px 0px 20px 0px #ececec;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: var(--white);
      text-align: center;
      padding: 20px 30px;
      cursor: pointer;

      &.active {
        background-color: var(--color-blue-extra-light);
        box-shadow: none;
      }
    }
  }

  .tabs-content {
    padding: 30px;
  }
}
