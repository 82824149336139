import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, forkJoin, from, map, Observable, switchMap } from 'rxjs';

import { Annonce, Coordinates, SearchLocation, GeolocSavedAdresse } from '@/models';
import { ANNONCE_STATE } from '@/constants';
import { GeographyService } from './geography.service';
import { AnnoncesService } from './annonces.service';

export type GeoLoc = {
  coord?: string;
  lng?: number;
  lat?: number;
};

@Injectable({
  providedIn: 'root'
})
export class GeolocService {
  private _geolocVille$ = new BehaviorSubject<SearchLocation | undefined>(undefined);
  private _nearestAgency = new BehaviorSubject<GeoLoc>({ coord: '' });

  private _showAutocompleteAddressOnClick = false;
  private _savedAddress?: GeolocSavedAdresse;
  private _geoCoder = '';
  private _eraId?: number;

  constructor(
    private geographyService: GeographyService,
    private annoncesService: AnnoncesService
  ) {}

  get currentGeolocVille$() {
    return this._geolocVille$.asObservable();
  }

  get showAutocompleteAddressOnClick(): boolean {
    return this._showAutocompleteAddressOnClick;
  }

  get geoCoder(): string {
    return this._geoCoder;
  }

  get eraId(): number | undefined {
    return this._eraId;
  }

  get nearestAgency$(): Observable<GeoLoc> {
    return this._nearestAgency.asObservable();
  }

  get nearestAgency(): GeoLoc {
    return this._nearestAgency.value;
  }

  updateNearestAgency(coord: string, lng?: number, lat?: number): void {
    this._nearestAgency.next({ coord, lng, lat });
  }

  updateGeoCoder(value: string): void {
    this._geoCoder = value;
  }

  searchAddressAutocomplete(on = true): void {
    this._showAutocompleteAddressOnClick = on;
  }

  updateGeolocVille(data?: SearchLocation): void {
    this._geolocVille$.next(data);
  }

  getSavedAddress(): GeolocSavedAdresse | undefined {
    return this._savedAddress;
  }

  saveAddress(adresse?: SearchLocation): void {
    if (adresse) {
      const { nom, code, geoloc } = adresse;
      const { lat, lng } = geoloc!;

      this._savedAddress = {
        nom, code, lat, lng, coord: `${lng}%2C${lat}`
      };
    } else {
      this._savedAddress = undefined;
    }
  }

  getSplittedSavedAddress(): { address: string; city_name: string, city_zip: string } & GeoLoc | undefined {
    if (this._savedAddress) {
      try {
        const { nom, code, lat, lng, coord } = this._savedAddress;
        const codeIdx = nom!.indexOf(code!) ?? 0;

        return {
          address: codeIdx > 0 ? nom!.slice(0, codeIdx - 1) : nom!,
          city_name: nom?.slice(codeIdx + code!.length + 1) ?? '',
          city_zip: code!,
          coord,
          lat,
          lng
        };
      }
      catch (error) {
        console.error('Error building address', this._savedAddress, error);
      }
    }

    return undefined;
  }

  getPosition(): Observable<Coordinates> {
    if (!navigator.geolocation) {
      throw new Error('Geolocation not supported');
    }

    return from(new Promise<Coordinates>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude }),
        (err) => reject(err)
      );
    }));
  }

  getAnnoncesAndSearchLocationFromPosition(): Observable<{ annonces: Annonce[], searchLocation: SearchLocation | undefined }> {
    return this.getPosition().pipe(
      switchMap(({ lat, lng }) => {
        const coords = `${lat}%2C${lng}`;

        return forkJoin({
          annonces: this.annoncesService.getAnnoncesGeoloc('Vente', coords, 5, ANNONCE_STATE).pipe(
            filter(({ data }) => !!data.length),
            map(({ data }) => data)
          ),
          searchLocation: this.geographyService.geoVilleSearch(lat, lng).pipe(
            map(({ data }) => this.geographyService.toLocation(data[0]))
          )
        });
      })
    );
  }

  getSearchLocationFromPosition(): Observable<SearchLocation | undefined> {
    return this.getPosition().pipe(
      switchMap(({ lat, lng }) => this.geographyService.geoVilleSearch(lat, lng)),
      map(({ data }) => this.geographyService.toLocation(data[0]))
    );
  }
}
