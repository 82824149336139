@mixin error-border {
  border: 2px solid var(--color-red-medium);
}

@mixin form-field {
  width: 100%;

  &-label {
    position: relative;
    border: 2px solid var(--color-blue-extra-light);
    padding: 16px 20px;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    background-color: var(--white);

    &.error {
      @include error-border();
    }
  }

  label {
    position: absolute;
    left: 13px;
    top: 11px;
    background: transparent;
    transition: 0.3s all ease-in-out;
    color: var(--color-grey-medium);
    width: 350px;

    &:not(.transformed) {
      transform: scale(0.7) translate(-95px, -50px);
    }

    &.not-transformed input[type="number"]:focus-within ~ label {
      transform: none;
    }

    span {
      color: var(--color-red-medium);

      &.not-color {
        color: var(--color-grey-medium);
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
    }
  }
}