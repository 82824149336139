<div class="contact-form" [ngClass]="type === 'agence' || type === 'negociateur' ? 'agenceheader' : ''">
  <div class="breadcrumb desktop">
    <div class="breadcrumb-content">
      <div class="breadcrumb-back" (click)="backButton()">
        <div class="breadcrumb-back-arrow">
          <span class="icon-arrow-left"></span>
        </div>
        <p class="display-text-16px">{{ 'previous' | transloco }}</p>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <div class="wrapper-contact">
      <div class="wrapper-contact-container" [ngClass]="{ 'agence': type === 'agence' || type === 'negociateur' }">
        @if (negociator) {
          <app-team-mini-card [negociateur]="negociator" />
          <app-presentation-agency-card type="negociateur" [agence]="negociator.agence" />

          <div class="negociator-buttons flex justify-between mt-30px">
            <app-button
              [link]="['/' + agence?.slug]"
              aspect="dark-border"
              text="see agency"
            />
            <app-button
              [link]="[MainRoutes.Effectifs + '/' + (negociator.website_slug ?? negociator.id)]"
              text="see property details"
              aspect="red"
            />
          </div>
        } @else if (agence) {
          <app-presentation-agency-card type="agence" [agence]="agence" />
        }
      </div>

      <div class="wrapper-contact-container-form">
        <form class="form-contact" [formGroup]="applicationForm" (submit)="sendApplicationForm()">
          @if (type === 'agence') {
            <h2 class="display-h2 mb-40px">{{ 'contact_us' | transloco }}</h2>
            <h3 class="display-text-24px font-medium mb-20px">
              {{ 'interested_in' | transloco }}
            </h3>
          } @else {
            <h1 class="display-h2 mb-40px">{{ 'contact_us' | transloco }}</h1>
            <h2 class="display-text-24px font-medium mb-20px">
              {{ 'interested_in' | transloco }}
            </h2>
          }

          <app-tags-container [tags]="tags" [template]="tag" />
          <ng-template #tag let-tag let-active="active" let-inactive="inactive">
            <tag (click)="toggleTags(tag.contact_type)" [ngClass]="[contact_type === tag.contact_type ? active : inactive]">
              {{ tag.contact_type | transloco }}
            </tag>
          </ng-template>

          <div class="form-inputs mt-56px">
            <app-form-input
              id="firstname"
              [required]="true"
              label="firstname"
              icon="icon-user"
              formControlName="firstname"
              [validationErrors]="!!(firstname.touched || firstname.errors)"
            />

            <app-form-input
              id="lastname"
              label="lastname"
              icon="icon-user"
              formControlName="lastname"
              [validationErrors]="!!(lastname.touched && lastname.errors)"
            />

            <app-form-input
              id="email"
              label="email"
              icon="icon-email"
              formControlName="email"
              [validationErrors]="!!(email.touched && email.errors)"
            />

            <app-form-input-telephone
              id="telephone"
              formControlName="phone"
              label="portable"
              [validationErrors]="!!(phone.touched && phone.errors)"
            />

            @if (contact_type === CONTACT_TYPE.APPLICATION) {
              <app-form-select
                formControlName="job_type_id"
                label="job_type"
                [items]="postes"
                itemKey="era_id"
                itemLabel="titre"
                id="job"
              />
            }

            <app-form-input
              id="message"
              type="textarea"
              label="message"
              formControlName="message"
            />

            <app-form-geoloc
              [mode]="geoLocInputMode"
              [showCities]="geoLocInputMode !== 'department'"
              formControlName="address"
              (geolocChanged)="handleGeolocChanged($event)"
            />

            @if (!negociator) {
              <app-form-switch
                formControlName="call_back"
                [label]="(type === 'agence') ? 'application_form_call_back' : 'call_back'"
              />
            }

            @if (contact_type === CONTACT_TYPE.OTHER && type === 'era') {
              <app-agence-mini-card
                [agence]="DEFAULT_AGENCE"
                [selectedAgenceId]="agenceId.value"
                formControlName="agence_id"
              />
            }

            @if (nearestAgencies?.length && !pathUrl.startsWith(MainRoutes.Effectifs) && !pathUrl.startsWith(MainRoutes.AgenceImmobiliere)) {
              <div class="agences">
                @for (agence of nearestAgencies | slice: 0:3; track agence.id; let i = $index) {
                  <app-agence-mini-card
                    [agence]="agence"
                    [selectedAgenceId]="agenceId.value"
                    formControlName="agence_id"
                  />
                }
              </div>
            }

            <app-form-select
              formControlName="origine_contact"
              label="origine_contact"
              [items]="CONTACT_ORIGINS"
              itemKey="key"
              itemLabel="label"
              [translate]="true"
              id="origine"
            />

            <app-form-checkbox formControlName="contact_agreement" label="authorization" />
            <app-form-checkbox formControlName="partner_agreement" label="authorization_data" />

            <app-button-loading
              [disabled]="!applicationForm.valid"
              [loading]="isLoading"
              text="application_form_send"
              type='submit'
              size='large'
            />

            <div class="block-contact-agency-asterisque">
              <p>{{ 'data' | transloco }} {{ 'data_link' | transloco }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
